import styled from 'styled-components';

const imageScale = 0.8;

const NotFoundStyled = styled.div`
  min-height: 100vh;
  min-height: -webkit-fill-available;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${({ error }) => (error ? 'center' : 'space-around')};
  padding: 15px;

  .image-logo-big {
    width: calc(832px * ${imageScale});
    max-width: 100%;
  }

  .subtitle {
    font-size: 24px;
    color: ${({ theme }) => theme.colors.primary.main};
  }
  .logo-holder {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .loading-holder {
    display: flex;
    align-items: center;
    .loading-text {
      font-size: 24px;
      padding-bottom: 12px;
      color: ${({ theme }) => theme.colors.secondary.darker};
    }
  }
  .auth-error-card {
    width: 500px;
    max-width: 100%;
    padding: 40px;
    border-radius: 8px;
    background-color: ${({ theme }) => theme.colors.red};
    margin-bottom: 100px;

    .error-text {
      font-size: 18px;
      line-height: 24px;
      color: white;
    }
  }

  @media (max-width: ${({ theme }) => theme.bp.xs}px) {
    .auth-error-card {
      padding: 15px;
    }
  }
`;

export { NotFoundStyled };
