import styled from 'styled-components';

const ComponentStyled = styled.div`
  .logo-holder {
    background: ${({ theme }) => theme.colors.primary.main};
    margin-bottom: 30px;
    text-align: center;

    .logo {
      max-width: 200px;
    }
  }

  .login-container {
    padding: 15px;
    margin: 0 auto;
    max-width: 400px;
  }
`;

export default ComponentStyled;
