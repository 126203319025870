import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import bp from '../../../config/breakpoints';
import { SelectApplication } from '../../atoms';
import { Statistics, MenuResponsive } from '../../molecules';
import { Status, Chat, Messages } from '../../organisms';
import { AuthService } from '../../../services';
import SocketProvider from '../../../sockets/provider';
import Paths from '../../../config/routes';
import { useSocketContext } from '../../../sockets/context';
import HomeStyled from './styled';

const MENU_STEPS = {
  menu: 0,
  chatList: 1,
  stats: 2,
  chat: 3,
};

const Home = () => {
  const [openSidebar, setOpenSidebar] = useState(true);
  const [menuStep, setMenuStep] = useState(MENU_STEPS.chatList);
  const isMediumScreen = useMediaQuery({ query: `(max-width: ${bp.md}px)` });
  const { applicationName, applicationImage } = AuthService.getApplicationInfo();
  const isChatMenu = menuStep === MENU_STEPS.chat;
  const isMainMenu = menuStep === MENU_STEPS.menu;
  const history = useHistory();
  const location = useLocation();
  const { setUnSelectedChat } = useSocketContext();

  useEffect(() => {
    history.listen((event, actions) => {
      if (event.pathname === Paths.AGENT && location.pathname === Paths.CONVERSATION && actions === 'POP') {
        setMenuStep(MENU_STEPS.chatList);
        setOpenSidebar(true);
        setUnSelectedChat();
      } else if (event.pathname === Paths.AGENT && location.pathname === Paths.STATS && actions === 'POP') {
        setMenuStep(MENU_STEPS.menu);
      }
    });
  }, [history, location, setUnSelectedChat]);

  const toggleSidebar = (step) => {
    setMenuStep(step);
    setOpenSidebar((prevState) => !prevState);
  };

  const handleMenuClick = () => {
    if (isChatMenu) {
      history.push(Paths.AGENT);
      toggleSidebar(MENU_STEPS.chatList);
      setUnSelectedChat();
    } else if (menuStep === MENU_STEPS.stats) {
      history.push(Paths.AGENT);
      setMenuStep(MENU_STEPS.menu);
    } else {
      setMenuStep(MENU_STEPS.menu);
    }
  };

  return (
    <HomeStyled>
      <div className="status-bar">
        <Status isChatMenu={isChatMenu} isMainMenu={isMainMenu} onClickMenu={handleMenuClick} />
      </div>
      <div className="chat-container">
        <div className={`sidebar ${openSidebar && 'open'}`}>
          {menuStep === MENU_STEPS.menu && isMediumScreen && (
            <div className="menu-wrapper">
              <MenuResponsive
                onClickChats={() => setMenuStep(MENU_STEPS.chatList)}
                onClickStatistics={() => {
                  setMenuStep(MENU_STEPS.stats);
                  history.push(Paths.STATS);
                }}
              />
              <div className="select-application-wrapper">
                <SelectApplication projectName={applicationName} projectImage={applicationImage} />
              </div>
            </div>
          )}
          {(menuStep === MENU_STEPS.chatList || !isMediumScreen) && (
            <Chat
              onChatClick={() => {
                toggleSidebar(MENU_STEPS.chat);
                if (isMediumScreen) {
                  history.push(Paths.CONVERSATION);
                }
              }}
            />
          )}
          {menuStep === MENU_STEPS.stats && isMediumScreen && <Statistics showTitle />}
        </div>
        <div className="messages-list">
          <Messages />
        </div>
      </div>
    </HomeStyled>
  );
};

const SocketHome = () => (
  <SocketProvider>
    <Home />
  </SocketProvider>
);

export default SocketHome;
