const MODAL_TYPE = {
  TRANSFER_CONV: 'transfer_conv',
  CLOSE_CONV: 'close_conv',
  ATTACH: {
    IMAGE: 'image',
    VIDEO: 'video',
    DOCUMENT: 'document',
    AUDIO: 'audio',
  },
  PREVIEW: {
    IMAGE: 'preview-image',
    VIDEO: 'preview-video',
  },
};

Object.freeze(MODAL_TYPE);

export default MODAL_TYPE;
