import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import classNames from 'classnames';
import ModalStyled from './styled';
import { withI18n } from '../../../hocs';

const customStyles = {
  container: (provided) => ({
    ...provided,
    width: '100%',
    marginTop: 20,
    marginBottom: 20,
  }),
  input: (provided) => ({
    ...provided,
    paddingTop: 10,
    paddingBottom: 10,
  }),
  singleValue: (provided) => ({
    ...provided,
    overflow: 'visible',
  }),
};

/* const formDePrueba = {
  "id": 2,
  "title": "TITULO_NUEVO 2",
  "conversationSessionId": 23,
  "questions": [
    {
      "id": 14,
      "title": "Pregunta tipo multiple",
      "type": "multiple",
      "options": [
        {
          "id": 4,
          "title": "Respuesta 2"
        },
        {
          "id": 5,
          "title": "Respuesta 3"
        },
        {
          "id": 6,
          "title": "Respuesta 4"
        }
      ]
    },
    {
      "id": 6,
      "title": "Pregunta tipo input",
      "type": "input",
      "options": []
    },
    {
      "id": 16,
      "title": "Pregunta tipo option",
      "type": "option",
      "options": [
        {
          "id": 7,
          "title": "Opcion A"
        },
        {
          "id": 8,
          "title": "Opcion B"
        },
        {
          "id": 9,
          "title": "Opcion C"
        }
      ]
    }
  ]
}; */


const AgentRow = ({ isFocused, isSelected, isDisabled, label }) => (
  <div
    className={classNames({
      'select-custom-option': true,
      selected: isSelected,
      disabled: isDisabled,
      focused: isFocused,
    })}
  >
    <span>{label}</span>
  </div>
);

AgentRow.propTypes = {
  isDisabled: PropTypes.bool,
  label: PropTypes.string,
  isFocused: PropTypes.bool,
  isSelected: PropTypes.bool,
};

const CustomOption = ({ innerProps, isFocused, isSelected, isDisabled, data }) => {
  const label = data.title;
  return !isDisabled ? (
    <div {...innerProps}>
      <AgentRow isFocused={isFocused} isSelected={isSelected} isDisabled={isDisabled} label={label} />
    </div>
  ) : null;
};

CustomOption.propTypes = {
  innerProps: PropTypes.object,
  isDisabled: PropTypes.bool,
  isFocused: PropTypes.bool,
  isSelected: PropTypes.bool,
  data: PropTypes.string,
};

const ModalCloseForm = ({ show, i18n, onModalCancel, action, formFromState }) => {
  const [formToFill, setFormToFill] = useState(formFromState);
  const [formResponse, setFormResponse] = useState(null);
  useEffect(() => {
    setFormToFill(formFromState);
  }, [formFromState]);
  useEffect(() => {
    if (formToFill?.questions && formToFill?.id) {
      const formToSetResponse = {
        agentSessionId: formToFill?.agentSessionId,
        questions: [
        ],
      };
      for (let i = 0; i < formToFill.questions.length; i += 1) {
        formToSetResponse.questions.push({ id: formToFill.questions[i]?.id });
      }
      setFormResponse(formToSetResponse);
    }
  }, [formToFill]);
  
  const handleModalSubmit = () => {
    const copyFormResponse = {...formResponse };
    const questsToModify = copyFormResponse.questions.map((el) => {
      const copyEl = { ...el } 
      if (el?.answer?.length > 0) {
        for(let i = 0; i < el.answer.length; i += 1) {
          if (el?.answer[i].label) {
            copyEl.answer[i] = el.answer[i].label
          }
        }
      }
      return copyEl
    });
    copyFormResponse.questions = questsToModify;
    return formResponse;
  };
  const renderTextInput = (label, value, setValue) => {
    return (
      <>
        <p className="modal-text">{label}</p>
        <input
          type="text"
          required
          value={value || ''}
          onChange={(e) => setValue(e.target.value)}
          className='text-area-modal input-modal'
        />
      </>
    );
  }
  
  
  const renderSelectInput = (label, options, value, setValue) => {
    return (
      <>
        <p className="modal-text">{label}</p>
          <div className="modal-select">
            <Select
              options={options}
              styles={customStyles}
              placeholder={label}
              noOptionsMessage={() => i18n.supervisor.modalTransfer.selectNoOptionsMessage}
              // components={{ Option: CustomOption }}
              onChange={(v) => setValue(v)}
              value={value}
            />
          </div>
      </>
    );
  } 
  const renderMultiSelectInput = (label, options, value, setValue) => {
    return (
      <>
        <p className="modal-text">{label}</p>
          <div className="modal-select">
            <Select
              options={options}
              styles={customStyles}
              placeholder={label}
              noOptionsMessage={() => i18n.supervisor.modalTransfer.selectNoOptionsMessage}
              /* components={{ Option: CustomOption }} */
              onChange={(v) => setValue(v)}
              isMulti
              value={value}
            />
          </div>
      </>
    );
  }
  const changeFormValue = (val, type, i) => {
    const formResponseCopy = { ...formResponse };
    
    if (type === 'input') {
      formResponseCopy.questions[i].answer = [ val ];  
    }
    if (type === 'option') {
      formResponseCopy.questions[i].answer = [ val ];
    }
    if (type === 'multiple') {
      formResponseCopy.questions[i].answer = val;  
    }
    setFormResponse(formResponseCopy);
  };
  const mapOptions = (options) => {
    if (options) {
      const optionsCopy = options.map(el => (
        {label: el.title, value: el.title}
        ));
      return optionsCopy;
    }
    return options;
  }
  const renderFormFields = (formObject) => {
    if (formObject?.questions?.length) {
      const questionsList = (
        <div>
          {formObject.questions.map((el, i) => {
            let jsxElement = <div key={i} />;
            if (el?.type === 'input') {
              jsxElement = renderTextInput(
                el?.title,
                formResponse?.questions[i]?.answer && formResponse?.questions[i]?.answer[0],
                (val) => changeFormValue(val, el?.type, i)
              );
            }
            if (el?.type === 'option') {
              jsxElement = renderSelectInput(
                el?.title,
                mapOptions(el?.options),
                /* parseOptionSelectToComponent(formResponse?.questions[i]?.answer && formResponse?.questions[i]?.answer[0], i), */
                formResponse?.questions[i]?.answer && formResponse?.questions[i]?.answer[0],
                (val) => changeFormValue(val, el?.type, i)
              );
            }
            if (el?.type === 'multiple') {
              jsxElement = renderMultiSelectInput(
                el?.title,
                mapOptions(el?.options),
                formResponse?.questions[i]?.answer,
                (val) => changeFormValue(val, el?.type, i)
              );
            }
            return (
              <div key={i}>
                {jsxElement}
              </div>
            );
          })}
        </div>
      );
      return questionsList;
    }
    return (<div/>);
  }; 
  return (
    <ModalStyled show={show}>
      <div className="modal-content">
        <h2 className="modal-title">{formToFill?.title || ''}</h2>
        <div className="modal-body">
          {renderFormFields(formToFill)}
          <hr />
          <div className="modal-buttons-row">
            <CancelButton onClick={onModalCancel} label={i18n.buttons.cancel} />
            <SubmitButton
              /* disabled={isFormDisabled()} */
              onClick={() => action(handleModalSubmit())}
              label={i18n.modalCloseForm.saveAndCloseConv}
            />
          </div>
        </div>
      </div>
    </ModalStyled>
  );
};

ModalCloseForm.propTypes = {
  show: PropTypes.bool,
  i18n: PropTypes.object,
  formFromState: PropTypes.object,
  onModalCancel: PropTypes.func,
  action: PropTypes.func,
};

const CancelButton = ({ onClick, label }) => (
  <button onClick={onClick} className="modal-button-cancel ts ts-normal" type="button">
    {label}
  </button>
);

CancelButton.propTypes = {
  onClick: PropTypes.func,
  label: PropTypes.string,
};

const SubmitButton = ({ onClick, label, disabled }) => (
  <button disabled={disabled} onClick={onClick} className="modal-button-finish ts ts-normal" type="button">
    {label}
  </button>
);

SubmitButton.propTypes = {
  onClick: PropTypes.func,
  label: PropTypes.string,
  disabled: PropTypes.bool,
};

export default withI18n(ModalCloseForm);
