import styled from 'styled-components';

const ModalPreviewStyled = styled.div`
  display: ${(props) => (props.show ? 'flex' : 'none')};
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 100px 15px 15px 15px;
  .bigger-icon {
    width: 30px !important;
    height: 30px !important;
  }  
  .modal-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .modal-buttons-row {
        display: flex;
        flex-wrap: wrap;
        margin: 10px;
        z-index: 10;
        cursor: pointer;

        .modal-buttons-rotate{
          display: flex;
          width: 100%;
          justify-content: center;
          align-items: flex-end;
        }
        .wrapper-download {
          display: flex;
          width: 100%;
          justify-content: flex-end;
        }
        .modal-button-save {
          border: solid;
          border-width: 2px;
          border-radius: 4px;
          padding: 8px 16px;
          min-width: 100px;
          font-size: 14px;
          font-weight: 500;
          height: 32px;
          margin-left: 15px;
          background-color: #fdfdfd;
          text-transform: uppercase;
          color: ${(props) => props.theme.colors.grey[100]};
          box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
          display: flex;
          justify-self: flex-end;

          &:hover {
            color: ${(props) => props.theme.colors.secondary.main};
            border-color: ${(props) => props.theme.colors.secondary.main};
            cursor: pointer;
          }

          &:active {
            background-color: ${(props) => props.theme.colors.grey[300]};
            color: ${(props) => props.theme.colors.primary.main};
          }
        }
      }

  .modal-content {
    background-color: ${(props) => props.theme.colors.white};
    margin: auto;
    border-radius: 4px;
    position: relative;
    box-sizing: border-box;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
      .close-icon-position {
        padding-top: 5px;
        padding-right: 5px;
        display: flex;
        justify-content: flex-end;
        width: 100%;
        z-index: 10;
        cursor: pointer;
      }
      .close-icon {
        width: 20px;
        height: 20px;
      }

    .modal-body {
      display: flex;

      .modal-img {
        padding: 0;
        margin: 0 !important;
        transform-origin: center center;
      }

      .modal-video {
        max-width: 100%;
        min-height: 720px;
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.bp.md}px) {
    padding: 15px;
    .modal-content {
      width: 100%;
      min-height: 100%;

      .modal-body {
        .modal-video {
          min-height: inherit;
        }
      }
    }
  }
`;

export { ModalPreviewStyled };
