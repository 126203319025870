import * as api from '../api';
import { STORAGE_ITEM } from "../models";

class BanUserService {
  ban = (users) => {
    const applicationId = parseInt(localStorage.getItem(STORAGE_ITEM.APP_ID), 10);
    return api.banUsers(applicationId, users);
  };

  getList = () => {
    const applicationId = parseInt(localStorage.getItem(STORAGE_ITEM.APP_ID), 10);
    return api.getBannedUsers(applicationId)
      .then(res => res.data.data);
  };

  unBan = (users) => {
    const applicationId = parseInt(localStorage.getItem(STORAGE_ITEM.APP_ID), 10);
    return api.unbanUsers(applicationId, users)
      .then(res => res.data);
  };
};

const instance = new BanUserService();

export default instance;
