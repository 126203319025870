import styled from 'styled-components';

export default styled.div`
  width: 100%;
  height: 100%;

  .chat-list__settings__menu {
    right: 0;
    z-index: 1;
    top: 100%;

    .actions-wrapper {
      .logout {
        align-items: center;
        margin-bottom: 20px;
        display: inline-flex;
        cursor: pointer;

        .logout-icon {
          margin-right: 5px;
        }
      }
    }
  }

  .supervisor-header {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 15px;
    -webkit-box-shadow: 0px 2px 6px 0px rgba(166, 168, 168, 1);
    -moz-box-shadow: 0px 2px 6px 0px rgba(166, 168, 168, 1);
    box-shadow: 0px 2px 6px 0px rgba(166, 168, 168, 1);

    .menu-icon {
      height: 30px;
      width: auto;

      path {
        fill: ${(props) => props.theme.colors.primary.main};
      }
    }

    .supervisor-header--logo {
      height: 58px;
    }

    .supervisor-header--text {
      color: ${(props) => props.theme.colors.primary.main};
      font-size: 18px;
      font-weight: 400;
      margin-left: 30px;
      flex: 1;
    }
  }

  .supervisor-main {
    width: 100%;
    height: calc(100% - 58px);
    padding: 40px 50px;

    .tabs-container {
      height: 100%;
    }
  }

  .tab-list {
    border-bottom: 1px solid #ccc;
    padding-left: 0;
  }

  .tab-list-item {
    display: inline-block;
    list-style: none;
    margin-bottom: -1px;
    padding: 0.5rem 0.75rem;
    color: gray;
    font-size: 1.2em;
    &:hover {
      cursor: pointer;
    }
  }

  .tab-list-active {
    background-color: white;
    border: solid ${(props) => props.theme.colors.primary.main};
    color: ${(props) => props.theme.colors.primary.main};
    border-width: 0 0 4px 0;
  }

  @media (max-width: ${({ theme }) => theme.bp.lg}px) {
    .supervisor-header {
      padding-left: 0;
    }

    .supervisor-main {
      padding: 30px 15px;
    }
  }

  @media (max-width: ${({ theme }) => theme.bp.xs}px) {
    .supervisor-header {
      .supervisor-header--text {
        font-size: 15px;
        margin-left: 15px;
      }
    }
  }
`;
