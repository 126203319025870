import React from 'react';
import PropTypes from 'prop-types';
import { ButtonContainer } from './styled';
import { ArrowBackIcon } from '../svg-icons';
import i18n from '../../../assets/i18n';

const BackButton = ({ marginTop, marginBottom, onClick }) => (
  <ButtonContainer marginTop={marginTop} marginBottom={marginBottom}>
    <button onClick={onClick} className="footer--button ts" type="button">
      <ArrowBackIcon className="icon ts" />
      {i18n.supervisor.goBack}
    </button>
  </ButtonContainer>
);

BackButton.defaultProps = {
  onClick: () => {},
  marginTop: 15,
  marginBottom: 0,
};

BackButton.propTypes = {
  onClick: PropTypes.func,
  marginTop: PropTypes.number,
  marginBottom: PropTypes.number,
};

export default BackButton;
