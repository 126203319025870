import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import OverlayStyled from './styled';

const Overlay = forwardRef(({ show, children, className }, ref) => (
  <OverlayStyled ref={ref} show={show} className={className}>
    {children}
  </OverlayStyled>
));

Overlay.propTypes = {
  className: PropTypes.string,
  show: PropTypes.bool,
  children: PropTypes.node,
};

export default Overlay;
