import React from 'react';
import PropTypes from 'prop-types';
import { ModalStyled } from './styled';
import { AlertIcon, CancelButton, SubmitButton } from '../../atoms';

const ModalConfirm = ({ show, onModalCancel, onModalDoAction, title, body, actionLabel, cancelLabel }) => {
  return (
    <ModalStyled show={show}>
      <div className="modal-content">
        <h2 className="modal-title">{title}</h2>
        <div className="modal-body">
          <AlertIcon className="modal-icon" />
          {body instanceof String ? <p className="modal-text">{body}</p> : body}
          <hr />
          <div className="modal-buttons-row">
            <CancelButton onClick={onModalCancel} label={cancelLabel} />
            <SubmitButton onClick={onModalDoAction} label={actionLabel} />
          </div>
        </div>
      </div>
    </ModalStyled>
  );
};

ModalConfirm.propTypes = {
  show: PropTypes.bool,
  title: PropTypes.string,
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  actionLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  onModalDoAction: PropTypes.func,
  onModalCancel: PropTypes.func,
};

export default ModalConfirm;
