import styled from 'styled-components';

export default styled.div`
  display: ${(props) => (props.show ? 'block' : 'none')};
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  padding: 100px 15px 15px 15px;

  .modal-content {
    background-color: ${(props) => props.theme.colors.white};
    margin: auto;
    border-radius: 8px;
    width: 600px;
    max-width: 100%;
    display: flex;
    flex-flow: column;

    .modal-title {
      color: ${({ theme }) => theme.colors.primary.main};
      padding: 24px 24px 20px;
      font-size: 21px;
    }
    hr {
      width: 100%;
      border: 0.5px solid #cecece;
      margin-bottom: 10px;
      margin-top: 16px;
    }

    .modal-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 24px 20px;
      flex: 2;
    }

    .modal-text {
      font-size: 14px;
      color: ${(props) => props.theme.colors.grey[100]};
      text-align: left;
    }

    .modal-select {
      width: 100%;
    }

    .select-custom-option {
      display: flex;
      flex: 1;
      justify-content: space-between;
      align-items: center;
      padding: 10px 30px;

      img {
        width: 46px;
        height: 46px;
        border-radius: 50%;
      }
    }

    .selected {
      background: ${(props) => props.theme.colors.grey[300]};
    }

    .focused {
      background: ${(props) => props.theme.colors.grey[400]};
    }

    .modal-selected {
      margin: 20px 0 30px 0;
      border: 1px solid #ccc;
      border-radius: 4px;
      padding: 16px 12px;
    }
    .body-p-modal {
      margin-top: 20px;
      margin-bottom: 40px;
    }
    .text-area-modal {
      padding: 10px;
      width: 100%;
      border-radius: 4px;
      border-color: hsl(0, 0%, 80%);
      font-size: 16px;
      margin-top: 20px;
      margin-bottom: 20px;
      &:hover {
        border-color: rgb(169, 169, 169);
      }
    }
    .input-modal {
      border-style: solid;
      border-width: 1px;
    }
    .modal-buttons-row {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      align-items: flex-end;
      flex: 2;

      .modal-button-cancel {
        border: solid;
        border-width: 2px;
        border-radius: 4px;
        padding: 0 16px;
        margin-left: 5px;
        min-width: 100px;
        font-size: 14px;
        font-weight: 500;
        height: 32px;
        background-color: #fdfdfd;
        text-transform: uppercase;
        color: ${(props) => props.theme.colors.grey[100]};
        box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);

        &:hover {
          color: ${(props) => props.theme.colors.primary.main};
          border-color: ${(props) => props.theme.colors.primary.main};
          cursor: pointer;
        }

        &:active {
          background-color: ${(props) => props.theme.colors.grey[300]};
          color: ${(props) => props.theme.colors.primary.main};
        }
      }
      .modal-button-finish {
        margin-left: 5px;
        padding: 8px 12px 8px 12px;
        border-radius: 4px;
        outline: none;
        border-width: 0;
        background-color: ${({ theme }) => theme.colors.primary.main};
        color: ${({ theme }) => theme.colors.secondary.main};
        text-transform: uppercase;
        font-weight: 500;
        font-size: 14px;
        box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);

        &:hover {
          color: white;
          opacity: 0.75;
          cursor: pointer;
        }
        &:active {
          background-color: ${(props) => props.theme.colors.secondary.light};
          border-color: transparent;
        }
        &:disabled {
          border-color: ${(props) => props.theme.colors.grey[200]};
          color: ${(props) => props.theme.colors.grey[300]};
          cursor: not-allowed;
        }
      }
    }
    .invalid-input {
      border-color: red;
    }
  }

  @media (max-width: ${({ theme }) => theme.bp.md}px) {
    padding: 15px;
    .modal-content {
      width: 100%;
      min-height: 100%;
    }
  }
`;
