import React from 'react';
import PropTypes from 'prop-types';
import { SpinnerStyled } from './styled';

const Spinner = ({ size = 50 }) => (
  <SpinnerStyled size={size}>
    <div />
    <div />
    <div />
    <div />
  </SpinnerStyled>
);

Spinner.propTypes = {
  size: PropTypes.number,
};

export default Spinner;
