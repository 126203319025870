import React from 'react';
import PropTypes from 'prop-types';
import ButtonStyled from './styled';

const CancelButton = ({ onClick, label }) => (
  <ButtonStyled onClick={onClick} className="ts ts-normal" type="button">
    {label}
  </ButtonStyled>
);

CancelButton.propTypes = {
  onClick: PropTypes.func,
  label: PropTypes.string,
};

export default CancelButton;
