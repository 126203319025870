const MSG_STATUS = {
  SENT: { VALUE: 'sent', PRIORITY: 0 },
  QUEUED: { VALUE: 'queued', PRIORITY: 1 },
  TRYING_AGAIN: { VALUE: 'trying_again', PRIORITY: 2 },
  ERROR: { VALUE: 'error', PRIORITY: 3 },
  CHANNEL_SENT: { VALUE: 'channel_sent', PRIORITY: 4 },
  CHANNEL_DELIVERED: { VALUE: 'channel_delivered', PRIORITY: 5 },
  CHANNEL_READ: { VALUE: 'channel_read', PRIORITY: 6 },
  CHANNEL_ERROR: { VALUE: 'channel_error', PRIORITY: 7 },
  CHANNEL_DELETED: { VALUE: 'channel_deleted', PRIORITY: 8 },
};

Object.freeze(MSG_STATUS);

export default MSG_STATUS;
