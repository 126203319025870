import React from 'react';
import PropTypes from 'prop-types';
import { LinkButtonStyled } from './styled';

const LinkButton = ({ text, to }) => {
  return (
    <LinkButtonStyled>
      <a href={to}>{text}</a>
    </LinkButtonStyled>
  );
};

LinkButton.propTypes = {
  text: PropTypes.string,
  to: PropTypes.string,
};

export default LinkButton;
