import React from 'react';
import PropTypes from 'prop-types';
import { LinkButton } from '../../atoms';
import { NotFoundStyled } from './styled';
import { withI18n } from '../../../hocs';
import { INDIGITALL_URL } from '../../../config/constants';
import images from '../../../assets/images';
import Paths from '../../../config/routes';

const ErrorCard = ({ errorText }) => (
  <div className="auth-error-card">
    <p className="error-text">{errorText}</p>
  </div>
);

ErrorCard.propTypes = {
  errorText: PropTypes.string,
};

const NotFound = ({ i18n, noMatch }) => {
  const errorText = noMatch ? i18n.errors.noMatch : i18n.errors.unauthorized;
  const buttonText = noMatch ? i18n.buttons.backToHome : i18n.errors.goBackToIndigitall;
  const linkPath = noMatch ? Paths.LOGIN : INDIGITALL_URL;
  return (
    <NotFoundStyled>
      <div className="logo-holder">
        <img className="image-logo-big" src={images.logo} alt="logo" />
      </div>
      <ErrorCard errorText={errorText} />
      <LinkButton text={buttonText} to={linkPath} />
    </NotFoundStyled>
  );
};

NotFound.propTypes = {
  i18n: PropTypes.object,
  noMatch: PropTypes.bool,
};

export default withI18n(NotFound);
