import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import _ from 'lodash';
import classNames from 'classnames';
import ModalStyled from './styled';
import { withI18n } from '../../../hocs';
import { useSocketContext } from '../../../sockets/context';
import { UserIcon } from '../../atoms';
import { AGENT_STATUS } from '../../../models';

const customStyles = {
  container: (provided) => ({
    ...provided,
    width: '100%',
    marginTop: 20,
    marginBottom: 20,
  }),
  input: (provided) => ({
    ...provided,
    paddingTop: 10,
    paddingBottom: 10,
  }),
  singleValue: (provided) => ({
    ...provided,
    overflow: 'visible',
  }),
};

const AgentRow = ({ isFocused, isSelected, isDisabled, label, image }) => (
  <div
    className={classNames({
      'select-custom-option': true,
      selected: isSelected,
      disabled: isDisabled,
      focused: isFocused,
    })}
  >
    <span>{label}</span>
    {image ? <img src={image} alt="profile pic" /> : <UserIcon fill="#CECECE" />}
  </div>
);

AgentRow.propTypes = {
  isDisabled: PropTypes.bool,
  label: PropTypes.string,
  isFocused: PropTypes.bool,
  isSelected: PropTypes.bool,
  image: PropTypes.string,
};

const CustomOption = ({ innerProps, isFocused, isSelected, isDisabled, data }) => {
  const { label, image } = data;
  return !isDisabled ? (
    <div {...innerProps}>
      <AgentRow isFocused={isFocused} isSelected={isSelected} isDisabled={isDisabled} label={label} image={image} />
    </div>
  ) : null;
};

CustomOption.propTypes = {
  innerProps: PropTypes.object,
  isDisabled: PropTypes.bool,
  isFocused: PropTypes.bool,
  isSelected: PropTypes.bool,
  data: PropTypes.object,
};

const ModalTransferAgents = ({ show, i18n, onModalCancel, onModalFinish, conversationSessionId }) => {
  const title = i18n.formatString(i18n.supervisor.modalTransfer.title, { conversationId: conversationSessionId });
  const { agentId: paramAgentId } = useParams();

  const { agents } = useSocketContext();
  const onlyAvailableAgents = _.filter(agents, (agent) => agent.agentId !== parseInt(paramAgentId, 10) && agent.status === AGENT_STATUS.AVAILABLE);

  const options = _.map(onlyAvailableAgents, ({ agentId, name, userImage }) => ({
    value: agentId,
    label: name,
    image: userImage,
  }));

  const [selected, setSelected] = useState(null);
  const handleChange = ({ value }) => setSelected(value);
  useEffect(() => setSelected(null), [show]);

  const handleModalFinish = () => {
    if (selected) onModalFinish(selected);
  };

  return (
    <ModalStyled show={show}>
      <div className="modal-content">
        <h2 className="modal-title">{title}</h2>
        <div className="modal-body">
          <p className="modal-text">{i18n.supervisor.modalTransfer.body}</p>
          <div className="modal-select">
            <Select
              options={options}
              styles={customStyles}
              placeholder={i18n.supervisor.modalTransfer.selectPlaceholder}
              noOptionsMessage={() => i18n.supervisor.modalTransfer.selectNoOptionsMessage}
              components={{ Option: CustomOption }}
              onChange={handleChange}
              value={options.filter((option) => option.value === selected)}
            />
          </div>
          {selected && (
            <div className="modal-selected">
              <AgentRow
                label={`${_.find(options, (option) => option.value === selected).label} - ${i18n.formatString(i18n.supervisor.modalTransfer.agent, {
                  agentId: selected,
                })}`}
                image={_.find(options, (option) => option.value === selected).image}
              />
            </div>
          )}
          <hr />
          <div className="modal-buttons-row">
            <CancelButton onClick={onModalCancel} label={i18n.buttons.cancel} />
            <SubmitButton disabled={_.isEmpty(onlyAvailableAgents)} onClick={handleModalFinish} label={i18n.buttons.transfer} />
          </div>
        </div>
      </div>
    </ModalStyled>
  );
};

ModalTransferAgents.propTypes = {
  show: PropTypes.bool,
  i18n: PropTypes.object,
  onModalFinish: PropTypes.func,
  onModalCancel: PropTypes.func,
  conversationSessionId: PropTypes.number,
};

const CancelButton = ({ onClick, label }) => (
  <button onClick={onClick} className="modal-button-cancel ts ts-normal" type="button">
    {label}
  </button>
);

CancelButton.propTypes = {
  onClick: PropTypes.func,
  label: PropTypes.string,
};

const SubmitButton = ({ onClick, label, disabled }) => (
  <button disabled={disabled} onClick={onClick} className="modal-button-finish ts ts-normal" type="button">
    {label}
  </button>
);

SubmitButton.propTypes = {
  onClick: PropTypes.func,
  label: PropTypes.string,
  disabled: PropTypes.bool,
};

export default withI18n(ModalTransferAgents);
