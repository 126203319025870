import styled from 'styled-components';

const StatusStyled = styled.div`
  min-height: 46px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 20px;
  background-color: ${(props) => props.theme.colors.grey[500]};
  position: relative;
  z-index: 100;

  .menu-icon {
    width: 30px;
    max-width: 100%;
    height: auto;
  }

  .image-logo {
    height: 46px;
  }
`;

export default StatusStyled;
