import styled from 'styled-components';

const ComponentStyled = styled.div`
  display: flex;
  align-items: center;

  .menu-label {
    font-size: 20px;
    color: ${(props) => props.theme.colors.primary.main};
  }

  svg,
  img {
    width: 50px;
    max-width: 100%;
    height: auto;
    margin-right: 15px;
  }

  svg {
    fill: ${(props) => props.theme.colors.primary.main};
  }
`;

export default ComponentStyled;
