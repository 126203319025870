import { isIOS, isIPad13 } from 'react-device-detect';
import i18n from '../assets/i18n';
import images from '../assets/images';

class NotificationService {
  requestPermission = () => {
    if (!isIOS && !isIPad13) {
      Notification.requestPermission();
    }
  };

  sendLocalPush = () => {
    if (!isIOS && !isIPad13) {
      Notification.requestPermission((result) => {
        if (result === 'granted') {
          navigator.serviceWorker.ready.then((registration) => {
            registration.showNotification(i18n.localPush.title, { icon: images.logoPush, body: i18n.localPush.body });
          });
        }
      });
    }
  };
}

const instance = new NotificationService();
export default instance;
