import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { withI18n } from '../../../hocs';
import { useSocketContext } from '../../../sockets/context';
import ComponentStyled from './styled';

function AgentConversationCard({ i18n, showTitle }) {
  const { stats } = useSocketContext();
  const { avgTimeInQueue, maxTimeInQueue, agentsAvailable, conversationsInQueue } = stats || {};
  const avgTime = moment.duration(Math.floor(avgTimeInQueue * 1000));
  const maxTime = moment.duration(Math.floor(maxTimeInQueue * 1000));

  return (
    <ComponentStyled>
      {showTitle && <p className="title">{i18n.agentStats.title}</p>}
      <p className="stat">
        <span>{i18n.agentStats.waitingTime}</span>
        {`${avgTime.minutes()}m  ${avgTime.seconds()}s`}
      </p>
      <p className="stat">
        <span>{i18n.agentStats.maxWaitingTime}</span>
        {`${maxTime.minutes()}m  ${maxTime.seconds()}s`}
      </p>
      <p className="stat">
        <span>{i18n.agentStats.queuedUsers}</span>
        {conversationsInQueue}
      </p>
      <p className="stat">
        <span>{i18n.agentStats.onlineAgents}</span>
        {agentsAvailable}
      </p>
    </ComponentStyled>
  );
}

AgentConversationCard.defaultProps = {
  showTitle: false,
};

AgentConversationCard.propTypes = {
  i18n: PropTypes.object,
  showTitle: PropTypes.bool,
};

export default withI18n(AgentConversationCard);
