import LocalizedStrings from 'react-localization';
import es from './es';
import en from './en';

import 'moment/locale/es';

export default new LocalizedStrings({
  en,
  es,
});
