import styled from 'styled-components';

const ComponentStyled = styled.div`
  .title {
    font-weight: 700;
    font-size: 24px;
    color: ${(props) => props.theme.colors.primary.main};
    margin-bottom: 30px;
  }

  .application-wrapper {
    padding: 15px 0;
    display: flex;
    align-items: center;
    cursor: pointer;
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey[100]};

    .application-logo-wrapper {
      width: 40px;
      height: 40px;
      object-fit: cover;
      margin-right: 15px;

      .application-logo {
        width: 100%;
        height: 100%;
      }
    }
  }
`;

export default ComponentStyled;
