/* eslint-disable import/no-cycle */
import io from 'socket.io-client';
import { socketEvents } from './events';
import * as API_CONFIG from '../config/api';
import { IO_TRANSPORTS } from '../models';
import SocketEventManager from './socket-event-manager';

export const socket = io(`${API_CONFIG.SOCKET_BASE_URL}/agent_chat`, {
  path: API_CONFIG.SOCKET_PATH,
  transports: [IO_TRANSPORTS.WEBSOCKET],
  autoConnect: false,
});

export const initSockets = ({ setGlobalState, removeConversation, setScrollAllowed, history, insertOneMessage, insertHistoricalMessages }) => {
  socket.connect();
  socketEvents(socket);
  SocketEventManager.shared().init(setGlobalState, removeConversation, setScrollAllowed, history, socket, insertOneMessage, insertHistoricalMessages);
};
