import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import _get from 'lodash/get';
import Countdown from 'react-countdown';
import moment from 'moment';
import { toast } from 'react-toastify';
import { RegularInput, BackButton, GeneralButton } from '../../atoms';
import { AuthService } from '../../../services';
import { STORAGE_ITEM } from '../../../models';
import * as API from '../../../api';
import Paths from '../../../config/routes';
import { withI18n } from '../../../hocs';
import useInterval from '../../../hooks/useInterval';
import ComponentStyled from './styled';


const DoubleFactorForm = ({ i18n, history }) => {



  const [startValidityDate, setStartValidityDate] = useState(null);
  const [invalidToken, setInvalidToken] = useState(false);
  const [shortToken, setShortToken] = useState(null);
  const [isFetchingSend, setIsFetchingSend] = useState();
  const [isFetchingRefresh, setIsFetchingRefresh] = useState();
  const [errorVerifCode, setErrorVerifCode] = useState(null);


  const handleDoubleFactorLogin = async (c) => {
    setIsFetchingSend(true);
    if (shortToken) {
      const res = await API.authUserTotp(c, shortToken);
      setIsFetchingSend(false)
      if (res.ok === false || !res || res.statusCode >= 400) {
        if (res && res.statusCode === 401) {
          switch (res.errorCode) {
            case 'AS_INVALID_TOTP':
              setErrorVerifCode(i18n.login.invalidTotpError);
              break;
            case 'TOKEN_EXPIRED':
              setErrorVerifCode(i18n.login.tokenExpiredError);
              setInvalidToken(true);
              break;
              default:
              toast(i18n.login.errorWhileSendingCode, { type: toast.TYPE.ERROR });
              history.push(Paths.LOGIN)
              break;
            }
          }
        }
        if (res && !(res.ok !== undefined || res.ok === false)) {
          const accountId = res && res.user && res.user.accountId;
          const accessToken = _get(res, 'userToken');
          if (accountId && accessToken) {
          AuthService.saveAdminToken(accessToken);
          AuthService.saveAccountId(accountId);
          history.push(Paths.APPLICATIONS);
        }
      }
    }
    setIsFetchingSend(false)
  };
  

  const updateTokenInState = (data) => {
    if (data) {
      setShortToken(data.shortLived2FaToken);
      setStartValidityDate(data?.timeStamp);
    } else {
      const stateToken = localStorage.getItem(STORAGE_ITEM.SHORT_TOKEN);
      const jsonToken = JSON.parse(stateToken);
      setStartValidityDate(jsonToken?.timeStamp);
      setShortToken(jsonToken?.shortLived2FaToken);
    }
  };
  
  useEffect(() => {
    updateTokenInState();
  }, []);
  const refreshToken = async () => {
    setIsFetchingRefresh(true);
    const response = await API.refreshShortLivedToken(shortToken);
    setIsFetchingRefresh(false);
    updateTokenInState(response);
  };
  useInterval(() => {
    if (startValidityDate) {
      const now = moment(new Date());
      const endToken = moment(new Date(startValidityDate * 1000));
      const difference = moment.duration(endToken.diff(now));
      if (difference.asMinutes() < 0 && difference.asMinutes) {
        setInvalidToken(true);
      }
    }
  }, 1000);
  return (
    <ComponentStyled>
      <p className="login-title">{i18n.login.title}</p>
      <div className={errorVerifCode ? 'error-box' : 'hidden'}>
        {errorVerifCode}
      </div>
      <Formik
        initialValues={{
          verificationCode: '',
        }}
        validate={(values) => {
          const errors = {};
          if (!values.verificationCode) {
            errors.verificationCode = i18n.errors.requiredField;
          }
          return errors;
        }}
        onSubmit={({verificationCode}) => handleDoubleFactorLogin(verificationCode)}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            {startValidityDate &&
            <div className="countdown-container">
               <p className="double-factor-summary">{i18n.login.doubleFactorSummary}</p>
              <Countdown
                date={startValidityDate * 1000}
              />
            </div>
            }
            <RegularInput
              error={errors.verificationCode && touched.verificationCode && errors.verificationCode}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.verificationCode}
              name="verificationCode"
              placeholder={i18n.login.verificationCode}
              marginBottom={15}
            />
            <div className="btn-wrapper">
              <BackButton marginTop={0} onClick={() => history.push(Paths.LOGIN)} label={i18n.login.backToLogin} />
              <GeneralButton label={i18n.login.refreshToken} disabled={invalidToken} onClick={refreshToken} isFetching={isFetchingRefresh}/>
              <GeneralButton type="submit" onClick={handleSubmit} isFetching={isFetchingSend} label={i18n.login.send} color="secondary" disabled={!values.verificationCode || invalidToken} />
            </div>
          </form>
        )}
      </Formik>
    </ComponentStyled>
  );
};


DoubleFactorForm.propTypes = {
  i18n: PropTypes.object,
  history: PropTypes.object,
};

export default withI18n(DoubleFactorForm);
