import styled from 'styled-components';

const StyledButton = styled.button`
  border: solid;
  border-width: 2px;
  border-radius: 4px;
  padding: 0 16px;
  margin-left: 5px;
  min-width: 100px;
  font-size: 14px;
  font-weight: 500;
  height: 32px;
  text-transform: uppercase;
  cursor: pointer;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
`;

export const PrimaryButton = styled(StyledButton)`
  background-color: ${(props) => props.theme.colors.primary.main};
  color: ${(props) => props.theme.colors.grey[100]};

  &:hover {
    background-color: ${(props) => props.theme.colors.red};
    border-color: ${(props) => props.theme.colors.red};
  }

  &:active {
    background-color: ${(props) => props.theme.colors.grey[300]};
    color: ${(props) => props.theme.colors.primary.main};
  }
   &[disabled] {
    background-color: ${(props) => props.theme.colors.grey[400]};
    color: ${(props) => props.theme.colors.grey[200]};
    border-color: ${(props) => props.theme.colors.grey[200]};
    cursor: not-allowed;
  }
`;

export const GreyButton = styled(StyledButton)`
  background-color: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.primary.main};

  &:hover {
    border-color: ${(props) => props.theme.colors.secondary.main};
    color: ${(props) => props.theme.colors.secondary.main};
  }

  &:active {
    border-color: ${(props) => props.theme.colors.secondary.main};
    color: ${(props) => props.theme.colors.secondary.main};
  }
   &[disabled] {
    background-color: ${(props) => props.theme.colors.grey[400]};
    color: ${(props) => props.theme.colors.grey[200]};
    border-color: ${(props) => props.theme.colors.grey[200]};
    cursor: not-allowed;
  }
`;
export const SecondaryButton = styled(StyledButton)`
  background-color: ${(props) => props.theme.colors.secondary.main};
  color: ${(props) => props.theme.colors.primary.main};
  border-color: ${(props) => props.theme.colors.secondary.main};

  &:hover {
    background-color: ${(props) => props.theme.colors.primary.main};
    color: ${(props) => props.theme.colors.secondary.main};
    border-color: ${(props) => props.theme.colors.primary.main};
  }

  &:active {
    background-color: ${(props) => props.theme.colors.primary.main};
    color: ${(props) => props.theme.colors.secondary.main};
    border-color: ${(props) => props.theme.colors.primary.main};
  }
  &[disabled] {
    background-color: ${(props) => props.theme.colors.grey[400]};
    color: ${(props) => props.theme.colors.grey[200]};
    border-color: ${(props) => props.theme.colors.grey[200]};
    cursor: not-allowed;
  }
`;