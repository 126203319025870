import { compose } from 'recompose';
import { withTheme } from 'styled-components';
import { withI18n } from '../../../hocs';
import View from './view';

const enharce = compose(
  withTheme,
  withI18n
);

export default enharce(View);
