import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ClipLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import { ModalAttachStyled } from './styled';
import { withI18n } from '../../../hocs';
import { MODAL_TYPE, FILE_INPUT_SPECS, MSG_CONTENT_TYPES } from '../../../models';
import * as api from '../../../api';
import { sendMessage } from '../../../sockets/emit';
import { useSocketContext } from '../../../sockets/context';
import { SendIcon, CloudUploadIcon } from '../../atoms';

function CancelButton({ onClick, label }) {
  return <button onClick={onClick} className="modal-button-cancel ts" type="button">
    {label}
  </button>
}

CancelButton.propTypes = {
  onClick: PropTypes.func,
  label: PropTypes.string,
};

function SendButton({ onClick, label, disabled, isFetching }) {
  return <button disabled={disabled} type="button" className="upload-file-button ts" onClick={onClick}>
    <p>{label}</p>
    {isFetching ? <ClipLoader size={16} loading={isFetching} /> : <SendIcon className="upload-icon ts" />}
  </button>
}

SendButton.propTypes = {
  onClick: PropTypes.func,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  isFetching: PropTypes.bool,
};

const getModalSpecsFromAction = ({ action, i18n }) => {
  const modalSpecs = {};

  switch (action) {
    case MODAL_TYPE.ATTACH.IMAGE:
      modalSpecs.title = i18n.attachImageModal.title;
      modalSpecs.send = i18n.attachImageModal.send;
      modalSpecs.uploadButton = i18n.attachImageModal.button;
      modalSpecs.limitations = i18n.attachImageModal.limitations;
      modalSpecs.supportedFormat = i18n.attachImageModal.supportedFormat;
      modalSpecs.cancel = i18n.buttons.cancel;
      modalSpecs.fileAcceptFormats = FILE_INPUT_SPECS.ACCEPT.IMAGE;
      modalSpecs.maxFileSize = FILE_INPUT_SPECS.MAX_SIZE.IMAGE;
      modalSpecs.contentType = MSG_CONTENT_TYPES.IMAGE;
      break;
    case MODAL_TYPE.ATTACH.VIDEO:
      modalSpecs.title = i18n.attachVideoModal.title;
      modalSpecs.send = i18n.attachVideoModal.send;
      modalSpecs.uploadButton = i18n.attachVideoModal.button;
      modalSpecs.limitations = i18n.attachVideoModal.limitations;
      modalSpecs.supportedFormat = i18n.attachVideoModal.supportedFormat;
      modalSpecs.cancel = i18n.buttons.cancel;
      modalSpecs.fileAcceptFormats = FILE_INPUT_SPECS.ACCEPT.VIDEO;
      modalSpecs.maxFileSize = FILE_INPUT_SPECS.MAX_SIZE.VIDEO;
      modalSpecs.contentType = MSG_CONTENT_TYPES.VIDEO;
      break;
    case MODAL_TYPE.ATTACH.DOCUMENT:
      modalSpecs.title = i18n.attachDocumentModal.title;
      modalSpecs.send = i18n.attachDocumentModal.send;
      modalSpecs.uploadButton = i18n.attachDocumentModal.button;
      modalSpecs.limitations = i18n.attachDocumentModal.limitations;
      modalSpecs.supportedFormat = i18n.attachDocumentModal.supportedFormat;
      modalSpecs.cancel = i18n.buttons.cancel;
      modalSpecs.fileAcceptFormats = FILE_INPUT_SPECS.ACCEPT.DOCUMENT;
      modalSpecs.maxFileSize = FILE_INPUT_SPECS.MAX_SIZE.DOCUMENT;
      modalSpecs.contentType = MSG_CONTENT_TYPES.DOCUMENT;
      break;
    case MODAL_TYPE.ATTACH.AUDIO:
      modalSpecs.title = i18n.attachAudioModal.title;
      modalSpecs.send = i18n.attachAudioModal.send;
      modalSpecs.uploadButton = i18n.attachAudioModal.button;
      modalSpecs.limitations = i18n.attachAudioModal.limitations;
      modalSpecs.supportedFormat = i18n.attachAudioModal.supportedFormat;
      modalSpecs.cancel = i18n.buttons.cancel;
      modalSpecs.fileAcceptFormats = FILE_INPUT_SPECS.ACCEPT.AUDIO;
      modalSpecs.maxFileSize = FILE_INPUT_SPECS.MAX_SIZE.AUDIO;
      modalSpecs.contentType = MSG_CONTENT_TYPES.AUDIO;
      break;
    default:
  }

  return modalSpecs;
};

function ModalAttach({ show, i18n, onModalCancel, action }) {
  const [isFetching, setIsFetching] = useState(false);
  const [isTooBigFile, setIsTooBigFile] = useState(false);
  const [isValidFile, setIsValidFile] = useState(true);
  const [selectedFile, setSelectedFile] = useState();
  const [selectedFileName, setSelectedFileName] = useState();
  const [fileType, setFileType] = useState();

  const { conversations, selectedChatId, setIsMessageSent } = useSocketContext();

  const onFileInputchange = (e, maxFileSize, contentType) => {
    e.preventDefault();
    if (!e.currentTarget && !e.currentTarget.files && !e.currentTarget.files[0]) {
      return;
    }
    const { name, size } = e.currentTarget.files[0];

    if (name && !_.isEmpty(name) && (size && size > 0)) {
      setSelectedFileName(name);
      setFileType(contentType);
      setIsValidFile(true);
    } else {
      setIsValidFile(false);
    }

    if (size > maxFileSize) {
      setIsTooBigFile(true);
      setSelectedFileName(null);
      setFileType(null);
    } else {
      setIsTooBigFile(false);
    }

    if (isValidFile && !isTooBigFile) {
      setSelectedFile(e.currentTarget.files[0]);
    }
  };

  const closeModalAndReset = () => {
    setIsValidFile(true);
    setIsTooBigFile(false);
    setSelectedFileName(null);
    onModalCancel();
  };

  const sendMessageHandler = (id, contentType) => {
    const message = {
      conversationSessionId: selectedChatId,
      content: {
        id,
        caption: selectedFileName,
      },
      contentType,
    };
    sendMessage(message);
    setIsMessageSent(false);
  };

  const sendButtonHandler = () => {
    setIsFetching(true);
    const { channel } = conversations?.filter((conversation) => conversation.conversationSessionId === selectedChatId)[0];

    api
      .postMultimedia(channel, selectedFile, fileType)
      .then((response) => {
        if (response && response.status === 200) {
          const { id, multimediaType } = response.data.data;
          sendMessageHandler(id, multimediaType);
          closeModalAndReset();
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          setIsValidFile(false);
        }

        if (error.response && error.response.status === 500) {
          closeModalAndReset();
          toast(i18n.toast.error500Attachfile, { type: toast.TYPE.ERROR });
        }
      })
      .finally(() => {
        setIsFetching(false);
      });
  };

  const { title, send, cancel, uploadButton, limitations, supportedFormat, fileAcceptFormats, maxFileSize, contentType } = getModalSpecsFromAction({
    action,
    i18n,
  });
  const fileError = isTooBigFile || !isValidFile;
  const disabled = fileError || _.isNil(selectedFileName);
  return (
    <ModalAttachStyled show={show}>
      <div className="modal-content">
        <h2 className="modal-title">{title}</h2>
        <div className="modal-body">
          <form>
            <label className="input-file-label" htmlFor="file">
              <CloudUploadIcon className="upload-icon" />
              <span>{uploadButton}</span>
            </label>
            <input
              className="input-file"
              accept={fileAcceptFormats}
              name="file"
              id="file"
              type="file"
              onChange={(e) => {
                onFileInputchange(e, maxFileSize, contentType);
              }}
              onClick={(e) => {
                e.target.value = null;
              }}
            />
          </form>
          {fileError && (
            <div className="file-error">
              {isTooBigFile && <p className="file-error-text">{i18n.errors.fileTooBig}</p>}
              {!isValidFile && <p className="file-error-text">{i18n.errors.fileNoValid}</p>}
            </div>
          )}
          {!_.isNil(selectedFileName) && (
            <div className="file-name">
              <p className="file-name-text">{selectedFileName}</p>
            </div>
          )}
          <div className="modal-text-holder">
            <p className="modal-text">{limitations}</p>
            <p className="modal-text">{supportedFormat}</p>
          </div>

          <div className="modal-buttons-row">
            <CancelButton onClick={closeModalAndReset} label={cancel} />
            <SendButton isFetching={isFetching} label={send} disabled={disabled || isFetching} onClick={sendButtonHandler} />
          </div>
        </div>
      </div>
    </ModalAttachStyled>
  );
}

ModalAttach.propTypes = {
  show: PropTypes.bool,
  i18n: PropTypes.object,
  onModalCancel: PropTypes.func,
  action: PropTypes.oneOf([MODAL_TYPE.ATTACH.IMAGE, MODAL_TYPE.ATTACH.VIDEO, MODAL_TYPE.ATTACH.DOCUMENT, MODAL_TYPE.ATTACH.AUDIO]),
};

export default withI18n(ModalAttach);
