import React from 'react';
import PropTypes from 'prop-types';
import { ClipLoader } from 'react-spinners';
import AudioPlayer from 'react-h5-audio-player';
import { ErrorIcon } from '../../../atoms';
import i18n from '../../../../assets/i18n';

export const MessageAudioHolder = ({ isFetching, isDownloadError, audioSource }) => (
  <div className="multimedia-holder">
    {isFetching && <ClipLoader size={40} loading={isFetching} />}
    {isDownloadError ? (
      <>
        <ErrorIcon className="error-icon" />
        <p className="bubble-info-text">{i18n.errors.audioFileNotAvailable}</p>
      </>
    ) : (
      audioSource && (
        <>
          <AudioPlayer src={audioSource} showLoopControl={false} showVolumeControl={false} showSkipControls={false} showJumpControls={false} />
        </>
      )
    )}
  </div>
);

MessageAudioHolder.propTypes = {
  isFetching: PropTypes.bool,
  isDownloadError: PropTypes.bool,
  audioSource: PropTypes.string,
};