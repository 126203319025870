/* eslint-disable react/jsx-no-constructed-context-values */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import _, { throttle } from 'lodash';
import PropTypes from 'prop-types';
import SocketContext from './context';
import { initSockets } from '.';
import { AGENT_STATUS, USER_ROL } from '../models';
import * as api from '../api';
import { AuthService, BanUserService, NotificationService } from '../services';

const throttlePush = throttle(() => {
  if (document.hidden) {
    NotificationService.sendLocalPush();
  }
}, 300000);

const SocketProvider = ({ children }) => {
  const history = useHistory();

  // TODO refactor, instead useReducer
  const [globalState, setGlobalState] = useState({
    auth: { token: '', applicationId: '' },
    userInfo: {},
    conversations: [],
    auditorSubscribedConversations: [],
    conversationsAvailableAuditor: null,
    supervisorAgentStatus: [],
    supervisorSubscribedConversations: [],
    newMessages: [],
    countUnread: 0,
    selectedChatId: -1,
    isFetching: false,
    isMessageSent: false,
    socketError: {},
    crmIdentifyEnabled: false,
    crmExportConversationEnabled: false,
    crmCaseValues: false,
    stats: {},
    convTransferIds: [],
    scrollAllowed: { isAllowed: false, options: {} },
    agents: [],
    users: [],
    bannedUsers: [],
    formClose: null,
    setScrollAllowed: (allowed, options = {}) => {
      setGlobalState((state) => ({ ...state, scrollAllowed: { isAllowed: allowed, options } }));
    },
    setConversationsAvailableAuditor: (convsToSet) => {
      setGlobalState((state) => {
        return { ...state, conversationsAvailableAuditor: convsToSet };
      })
    },
    setUnSelectedChat: () => {
      setGlobalState((state) => {
        return { ...state, selectedChatId: -1 };
      });
    },
    setSelectedChatId: (convId) => {
      setGlobalState((state) => {
        const auxNewMessages = [...state.newMessages];

        const index = _.findIndex(auxNewMessages, (ob) => ob.convId === convId);
        if (index > -1) {
          auxNewMessages[index].count = 0;
        }

        return { ...state, selectedChatId: convId, newMessages: auxNewMessages };
      });
    },
    insertHistoricalMessages: (convId, messages) => {
      setGlobalState((state) => {
        const { conversations } = state;
        const aux = [...conversations];
        const index = _.findIndex(aux, (conv) => conv.conversationSessionId === convId);
        for (let i = 0; i < messages.length; i += 1) {
          const message = messages[i];
          if (
            !_.find(aux[index].messages, (mes) => {
              return mes && mes.messageId === message.messageId;
            })
          ) {
            aux[index].messages.unshift(message);
          }
        }
        return { ...state, conversations: aux };
      });
    },
    /* insertMessages: (convId, messages) => {
      setGlobalState((state) => {
        const { conversations } = state;
        const aux = [...conversations];
        const index = _.findIndex(aux, (conv) => conv.conversationSessionId === convId);
    
        messages.forEach((message) => {
          if (!_.find(aux[index].messages, (mes) => mes && mes.messageId === message.messageId)) {
            aux[index].messages.push(message);
          }
        });
    
        aux[index].messages = _.orderBy(aux[index].messages, ['timestamp'], ['asc']);
        return { ...state, conversations: aux };
      });
    }, */
    insertOneMessage: (convId, message) => {
      setGlobalState((state) => {
        const { conversations } = state;
        const aux = [...conversations];
        const index = _.findIndex(aux, (conv) => conv.conversationSessionId === convId);
        if (
          !_.find(aux[index].messages, (mes) => {
            return mes && mes.messageId === message.messageId;
          })
        ) {
          aux[index].messages.push(message);

        }

        return { ...state, conversations: aux };
      });
    },
    removeConversation: (convId) => {
      setGlobalState((state) => {
        const auxConversations = _.filter(state.conversations, (conv) => conv.conversationSessionId !== convId);
        const auxNewMessages = _.filter(state.newMessages, (mes) => mes.convId !== convId);
        return { ...state, conversations: auxConversations, selectedChatId: -1, newMessages: auxNewMessages };
      });
    },
    setIsMessageSent: (isMessageSent) => {
      setGlobalState((state) => ({ ...state, isMessageSent }));
    },
    agentStatus: AGENT_STATUS.HALTING,
    setAgentStatus: (agentStatus) => {
      setGlobalState((state) => ({ ...state, agentStatus }));
    },
    setFormClose: (formClose) => {
      setGlobalState((state) => ({ ...state, formClose }));
    },
    setSupervisorAgentStatus: (supervisorAgentStatus) => {
      setGlobalState((state) => ({ ...state, supervisorAgentStatus }));
    },
    addSubscribedSupervisorConv: (conversationSessionId) => {
      setGlobalState((state) => {
        const { supervisorSubscribedConversations } = state;
        const subscriptions = [...supervisorSubscribedConversations];
        subscriptions.push(conversationSessionId);
        return { ...state, supervisorSubscribedConversations: subscriptions };
      });
    },
    addSubscribedAuditorConv: (conversationSessionId) => {
      setGlobalState((state) => {
        const { auditorSubscribedConversations } = state;
        const subscriptions = [...auditorSubscribedConversations];
        subscriptions.push(conversationSessionId);

        return { ...state, auditorSubscribedConversations: subscriptions };
      });
    },
    setTopics: (topicsToSet) => {
      setGlobalState((state) => {
        return { ...state, topics: topicsToSet };
      });
    },
    setConversations: (conversationsToSet) => {
      setGlobalState((state) => {
        return { ...state, conversations: conversationsToSet };
      });
    },
    removeSubscribedSupervisorConv: (conversationSessionId) => {
      setGlobalState((state) => {
        const { supervisorSubscribedConversations } = state;
        const subscriptions = supervisorSubscribedConversations.filter((sub) => sub !== conversationSessionId);
        subscriptions.push(conversationSessionId);
        return { ...state, supervisorSubscribedConversations: subscriptions };
      });
    },
    removeSubscribedAuditorConv: (conversationSessionId) => {
      setGlobalState((state) => {
        const { auditorSubscribedConversations } = state;
        const subscriptions = auditorSubscribedConversations.filter((sub) => sub !== conversationSessionId);
        subscriptions.push(conversationSessionId);
        return { ...state, auditorSubscribedConversations: subscriptions };
      });
    },
  });

  const initApp = async () => {
    const getUsers = async () => {
      const admToken = AuthService.getAdminToken();
      const pageSize = 100;
      const currentPage = 0;
      api.tokenToAxiosHeader(admToken);
      const response = await api.getUsers({ params: { page: currentPage, limit: pageSize, chatPermission: USER_ROL.AGENT } });

      if (response && response.data && response.data.data) {
        let usersList = response.data.data;
        const nData = response.data.count;
        const nPages =  Math.ceil(nData / pageSize); 
        const prs = [];

        // get all users using the API pagination
        for (let i = (currentPage + 1); i < nPages;  i += 1) {
          // eslint-disable-next-line no-await-in-loop
          const pr = await api.getUsers({ params: { page: i, limit: pageSize, chatPermission: USER_ROL.AGENT } });
          prs.push(pr);
        }
        usersList = (await Promise.all(prs) || []).reduce( (prev, res) => prev.concat(res?.data?.data || []), usersList);
        setGlobalState((state) => ({ ...state, users: usersList}));
      }
    };
    const setUserInfo = async () => {
      const userInfo = AuthService.getLoggedUserInfo();
      const response = await api.getUser(userInfo?.webUserId);
      if (response && response.data && response.data.data) {
        userInfo.extraInfo = response.data.data;
      }
      setGlobalState((state) => ({ ...state, userInfo }));
    };

    await getUsers();
    await setUserInfo();
  };

  const updateBannedUsers = (bannedUsers) => {
    setGlobalState((state) => ({ ...state, bannedUsers }));
  };

  const addBannedUser = (bannedUser) => {
    setGlobalState((state) => ({ ...state, bannedUsers: [...state.bannedUsers, bannedUser] }));
  };

  const deleteBannedUser = (userId) => {
    setGlobalState((state) => ({ ...state, bannedUsers: state.bannedUsers?.filter((user) => user.conversationSessionId !== userId) }));
  };

  useEffect(() => {
    if (!document.hidden) {
      throttlePush.cancel();
    }
    if (document.hidden && globalState.countUnread > 0) {
      throttlePush();
    }
  }, [globalState.countUnread]);

  useEffect(() => {
    const admToken = AuthService.getAdminToken();
    api.tokenToAxiosHeader(admToken);

    BanUserService.getList()
      .then((bannedUsersData) => updateBannedUsers(bannedUsersData))
      .catch(() => updateBannedUsers([]));
  }, []);

  useEffect(() => {
    initApp().then(() => {
      initSockets({
        setGlobalState,
        removeConversation: globalState.removeConversation,
        setScrollAllowed: globalState.setScrollAllowed,
        history,
        insertOneMessage: globalState.insertOneMessage,
        insertHistoricalMessages: globalState.insertHistoricalMessages,
      });
    });
  }, [globalState.removeConversation, globalState.setScrollAllowed, globalState.insertOneMessage, globalState.insertHistoricalMessages, history]);

  return (
    <SocketContext.Provider
      value={{
        ...globalState,
        setGlobalState,
        setCountUnread: (countUnread) => setGlobalState((state) => ({ ...state, countUnread })),
        updateBannedUsers,
        addBannedUser,
        deleteBannedUser,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

SocketProvider.propTypes = {
  children: PropTypes.any,
};

export default SocketProvider;
