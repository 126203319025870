import React from 'react';
import PropTypes from 'prop-types';
import { ClipLoader } from 'react-spinners';
import { DocumentMessageBubbleStyled } from './styled';
import i18n from '../../../assets/i18n';
import { FileIcon, DownloadIcon, ErrorIcon } from '../../atoms';

const DocumentTemplateHeader = ({ isFetching, isDownloadError, docSource, viewRef }) => {
  return (
    <DocumentMessageBubbleStyled ref={viewRef}>
        <div className="document-holder">
          <FileIcon className="icon-size icon-margin-right" />
          {isDownloadError ? (
            <>
              <p className="caption-text">{i18n.errors.documentFileNotAvailable}</p>
              <ErrorIcon className="error-icon" />
            </>
          ) : (
            <>
              <p className="caption-text">{i18n.chats.unknownDocumentCaption}</p>
              {isFetching ? (
                <div className="spinner-holder">
                  <ClipLoader size={16} loading={isFetching} />
                </div>
              ) : (
                <a className="btn-download ts" href={docSource} target="_blank" rel="noopener noreferrer">
                  <DownloadIcon className="icon-size btn-icon ts" />
                </a>
              )}
            </>
          )}
        </div>
    </DocumentMessageBubbleStyled>
  );
};

DocumentTemplateHeader.propTypes = {
  isFetching: PropTypes.bool,
  isDownloadError: PropTypes.bool,
  docSource: PropTypes.string,
  viewRef: PropTypes.any,
};

export default DocumentTemplateHeader;
