import styled from 'styled-components';

const ComponentStyled = styled.div`
  .login-title {
    text-align: center;
    margin-bottom: 30px;
    font-weight: bold;
    font-size: 23px;
  }
  .error-box {
    padding: 16px;
    background: #b71c1c;
    margin-top: 8px;
    color: white;
    margin-bottom: 20px;
  }
  .btn-wrapper {
    text-align: center;
    margin-bottom: 50px;
  }
  .hidden {
    display: none;
  }
`;

export default ComponentStyled;
