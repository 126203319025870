import axios from 'axios';
import Paths from '../config/routes';
import * as ApiConfig from '../config/api';

const instance = axios.create({
  baseURL: ApiConfig.ADMIN_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const addTokenHeader = (token) => {
  instance.defaults.headers.common.Authorization = `Bearer ${token}`;
};

export const removeTokenHeader = () => {
  instance.defaults.headers.common.Authorization = '';
};

export const get = (url, config) => {
  return instance.get(url, config);
};

export const post = (url, data, config) => {
  return instance.post(url, data, config);
};

export const put = (url, data, config) => {
  return instance.put(url, data, config);
};

export const fetchDelete = (url, config) => {
  return instance.delete(url, config);
};

instance.interceptors.response.use(
  (response) => response,
  async (err) => {
    const status = (err && err.response && err.response.status) || 1;

    if (status === 403 || status === 401) {
      window.location.replace(Paths.LOGIN);
    }

    return Promise.reject(err);
  }
);
