import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { withTheme } from 'styled-components';
import { withI18n } from '../../../hocs';
import { ReactComponent as ChatIcon } from '../../../assets/images/chat.svg';
import { ReactComponent as BarChartIcon } from '../../../assets/images/bar-chart.svg';
import { ReactComponent as LogoutIcon } from '../../../assets/images/logout.svg';
import Paths from '../../../config/routes';
import { AuthService } from '../../../services';
import { MenuItem } from '../../atoms';
import ComponentStyled from './styled';

const MenuResponsive = ({ i18n, onClickChats, onClickStatistics }) => {
  const history = useHistory();

  const logout = () => {
    AuthService.logout();
    history.replace(Paths.LOGIN);
  };

  return (
    <ComponentStyled>
      <div className="menu-item-wrapper">
        <MenuItem onClick={onClickChats} icon={<ChatIcon />} label={i18n.chats.conversations} />
      </div>
      <div className="menu-item-wrapper">
        <MenuItem onClick={onClickStatistics} icon={<BarChartIcon />} label={i18n.agentStats.title} />
      </div>
      <div className="menu-item-wrapper">
        <MenuItem onClick={logout} icon={<LogoutIcon />} label={i18n.chats.logout} />
      </div>
    </ComponentStyled>
  );
};

MenuResponsive.defaultProps = {
  onClickChats: () => {},
  onClickStatistics: () => {},
};

MenuResponsive.propTypes = {
  i18n: PropTypes.object,
  onClickChats: PropTypes.func,
  onClickStatistics: PropTypes.func,
};

export default withTheme(withI18n(MenuResponsive));
