import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import ReactTooltip from 'react-tooltip';
import { useMediaQuery } from 'react-responsive';
import { useInView } from 'react-intersection-observer';
import 'react-h5-audio-player/lib/styles.css';
import { MultimediaMessageBubbleStyled } from './styled';
import DocumentMessageBubble from '../document-message-bubble';
import { MODAL_TYPE } from '../../../models';
import i18n from '../../../assets/i18n';
import { ResourcesService } from '../../../services';
import bp from '../../../config/breakpoints';
import { MessageLocationHolder } from './components/MessageLocationHolder';
import { MessageImageHolder } from './components/MessageImageHolder';
import { MessageVideoHolder } from './components/MessageVideoHolder';
import { MessageAudioHolder } from './components/MessageAudioHolder'
import { MessageContactsHolder } from './components/MessageContactsHolder';
import { MessageInteractiveHolder } from './components/MessageInteractiveHolder';
import { InteractiveExtraContent } from './components/InteractiveExtraContent';

const MultimediaMessageBubble = ({
  date,
  colorClass,
  sendType,
  caption,
  id,
  onMultimediaClick,
  image,
  video,
  audio,
  interactive,
  sticker,
  contacts,
  document,
  location,
  statusIcon,
  errorIcon,
  resourceURL,
  content
}) => {
  const isMediumScreen = useMediaQuery({ query: `(max-width: ${bp.md}px)` });
  const [isFetching, setIsFetching] = useState(false);
  const [resourceSource, setResourceSource] = useState();
  const [isDownloadError, setIsdownloadError] = useState(false);
  const [ref, inView] = useInView({
    /* Optional options */
    threshold: 0.25,
  });
  
  const isMounted = useRef(true);
  const SCROLL_DELAY_TIME = 100;

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const getMultimedia = async () => {
    setIsFetching(true);
    setIsdownloadError(false);
    try {
      const url = id ? await ResourcesService.getResource(id) : resourceURL;
      setResourceSource(url);
    } catch (error) {
      setIsdownloadError(true);
    } finally {
      setIsFetching(false);
    }
  };

  const fetchMultimedia = useCallback(getMultimedia, []);

  useEffect(() => {
    let timer;
    if (inView && !resourceSource && isMounted.current) {
      timer = setTimeout(() => {
        fetchMultimedia();
      }, SCROLL_DELAY_TIME);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [fetchMultimedia, inView, resourceSource]);

  const handleClick = () => {
    const action = image || sticker ? MODAL_TYPE.PREVIEW.IMAGE : MODAL_TYPE.PREVIEW.VIDEO;
    onMultimediaClick(action, resourceSource);
  };

  if (document)
    return (
      <DocumentMessageBubble
        viewRef={ref}
        colorClass={colorClass}
        caption={caption}
        date={date}
        sendType={sendType}
        docSource={resourceSource}
        isFetching={isFetching}
        isDownloadError={isDownloadError}
        statusIcon={statusIcon}
        errorIcon={errorIcon}
      />
    );
  const interactiveButtons = interactive && content.interactive.type === 'button' && content.interactive.action.buttons
  return (
    <div>
      <MultimediaMessageBubbleStyled
        ref={ref}
        sendType={sendType}
        isError={isDownloadError}
        isVideo={video}
        isAudio={audio}
        isLocation={location}
        isContact={contacts}
        interactiveButtons={interactiveButtons}
        isDoc={document}
        isThereErrorIcon={!_.isNil(errorIcon)}
      >
        {errorIcon && (
          <div className="error-icon-holder" data-tip={i18n.errors.tooltipError} data-offset="{'left': 5}" data-tip-disable={isMediumScreen}>
            {errorIcon}
          </div>
        )}
        <div className={`${colorClass} img-text-container`}>
          {(image || sticker) && <MessageImageHolder isFetching={isFetching} isDownloadError={isDownloadError} handleClick={handleClick} imageSource={resourceSource} />}
          {video && <MessageVideoHolder isFetching={isFetching} isDownloadError={isDownloadError} handleClick={handleClick} videoSource={resourceSource} />}
          {audio && <MessageAudioHolder isFetching={isFetching} isDownloadError={isDownloadError} audioSource={resourceSource} />}
          {contacts && <MessageContactsHolder isFetching={isFetching} isDownloadError={isDownloadError} handleClick={handleClick} data={content}/>}
          {location && <MessageLocationHolder isFetching={isFetching} isDownloadError={isDownloadError} data={content}/>}
          {interactive && <MessageInteractiveHolder isFetching={isFetching} isDownloadError={isDownloadError} data={content.interactive}/> }
          <div className="img-text-holder">
            <p className="message-text message-caption">{caption}</p>
            <div className="info-row">
              <p className="timespan-message">{date}</p>
              {statusIcon}
            </div>
          </div>
        </div>
        <ReactTooltip type="info" />
        {interactiveButtons &&
            <InteractiveExtraContent
              data={content.interactive}
            />
        }
      </MultimediaMessageBubbleStyled>
    </div>

  );
}

MultimediaMessageBubble.propTypes = {
  colorClass: PropTypes.string,
  date: PropTypes.string,
  sendType: PropTypes.string,
  caption: PropTypes.string,
  id: PropTypes.number,
  onMultimediaClick: PropTypes.func,
  image: PropTypes.bool,
  video: PropTypes.bool,
  audio: PropTypes.bool,
  sticker: PropTypes.bool,
  location: PropTypes.bool,
  document: PropTypes.bool,
  contacts: PropTypes.bool,
  statusIcon: PropTypes.any,
  errorIcon: PropTypes.any,
  resourceURL: PropTypes.string,
  content: PropTypes.object,
  interactive: PropTypes.bool,
};

export default MultimediaMessageBubble;
