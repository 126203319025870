/* eslint-disable no-restricted-syntax */
/* eslint-disable no-shadow */
/* eslint-disable no-prototype-builtins */
/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { EventFlowMessageBubbleStyled } from './styled';
import i18n from '../../../assets/i18n';

const EventFlowMessageBubble = ({
  colorClass,
  date,
  sendType,
  statusIcon,
  deletedIcon,
  errorIcon,
  content,
  linkedMessage,
}) => {
  const transformObjectIntoTable = (content) => {
    
    let tableHTML = '<table style="border-collapse: collapse; border: 1px solid black; width: 100%; text-align: left;" cellspacing="0"dcxf>';
    tableHTML += `<thead><tr><th style="text-align: center;" colspan="2">${i18n.chats.flowResponse}</th></tr><tr><th style="text-align: left; border: 1px solid black; padding: 0 10px">${i18n.chats.key}</th><th style="text-align: left; border: 1px solid black; padding: 0 10px">${i18n.chats.value}</th></tr></thead><tbody>`;

    function loopObject(obj) {
      for (const key in obj) {
          if (obj.hasOwnProperty(key) && key !== 'flowToken' && obj.hasOwnProperty(key) && key !== 'eventName') { // Excluir flowToken
              if (typeof obj[key] === 'object' && !Array.isArray(obj[key]) && obj[key] !== null) {
                  loopObject(obj[key], key);
              } else {
                  const valor = Array.isArray(obj[key]) ? obj[key].join(', ') : obj[key];
                  tableHTML += `<tr><td style="text-align: left; border: 1px solid black; font-weight: bold; padding: 0 10px">${key}</td><td style="border: 1px solid black; text-align: left; padding: 0 10px">${valor}</td></tr>`;
              }
          }
      }
  }

    loopObject(content);

    tableHTML += '</tbody></table>';
    return tableHTML;
}

  return (
  <EventFlowMessageBubbleStyled sendType={sendType} isThereErrorIcon={!_.isNil(errorIcon)}>
    {errorIcon && (
      <div className="error-icon-holder" data-tip={i18n.errors.tooltipError} data-offset="{'left': 5}">
        {errorIcon}
      </div>
    )}
    <div className={colorClass}>

      {/** referenciamos al mensaje aquí */}
      {linkedMessage && (
        <div className="linked-message-box">
          {linkedMessage}
        </div>
      )}
      {deletedIcon ? (
        <div className="deleted-info-holder">
          {deletedIcon}
          <p className="deleted-text" dangerouslySetInnerHTML={{__html: transformObjectIntoTable(content) }} />
        </div>
      ) : (
        <>
          <p className="message-text" dangerouslySetInnerHTML={{__html: transformObjectIntoTable(content) }} />
          <div className="info-row">
            <p className="timespan-message">{date}</p>
            {statusIcon}
          </div>
        </>
      )}
    </div>
  </EventFlowMessageBubbleStyled>
)}

EventFlowMessageBubble.propTypes = {
  colorClass: PropTypes.string,
  content: PropTypes.string,
  date: PropTypes.string,
  sendType: PropTypes.string,
  statusIcon: PropTypes.any,
  deletedIcon: PropTypes.any,
  errorIcon: PropTypes.any,
  linkedMessage: PropTypes.node,
};

export default EventFlowMessageBubble;
