import React from 'react';
import PropTypes from 'prop-types';
import Spinner from '../spinner'
import { PrimaryButton, GreyButton, SecondaryButton } from './styled';

const GeneralButton = ({ onClick, label, color, isFetching, disabled }) => {
  if (color === 'primary') {
    return (
      <PrimaryButton onClick={onClick} className="ts ts-normal" type="button" disabled={disabled}>
        {isFetching ? (
          <div className="loading-wrapper">
            <Spinner size={20} />
          </div>
        ) : label}
      </PrimaryButton>
    );
  }
  if (color === 'secondary') {
    return (
      <SecondaryButton onClick={onClick} className="ts ts-normal" type="button" disabled={disabled}>
        {isFetching ? (
          <div className="loading-wrapper">
            <Spinner size={20} />
          </div>
        ) : label}
      </SecondaryButton>
    );
  }
    return (
      <GreyButton onClick={onClick} className="ts ts-normal" type="button" disabled={disabled}>
        {isFetching ? (
          <div className="loading-wrapper">
            <Spinner size={20} />
          </div>
        ) : label}
      </GreyButton>
    );

};

GeneralButton.propTypes = {
  onClick: PropTypes.func,
  color: PropTypes.string,
  label: PropTypes.string,
  isFetching: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default GeneralButton;
