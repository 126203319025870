import styled from 'styled-components';

export default styled.div`
  width: 362px;
  max-width: 100%;
  background-color: white;
  padding: 24px;
  margin: 0 10px 25px 10px;
  border-radius: 4px;
  -webkit-box-shadow: 3px 2px 6px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 3px 2px 6px 0px rgba(0, 0, 0, 0.3);

  .agent-data-card--header {
    display: flex;
    flex-direction: row;
    padding-bottom: 4px;
    border-bottom: 1px solid #ccc;

    img {
      width: 46px;
      height: 46px;
      border-radius: 50%;
    }

    .agent-data-card--header-text-holder {
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding-left: 16px;
      .agent-data-card--header-text-holder--text {
        margin-top: 6px;
        font-size: 12px;
      }
      .off {
        color: ${({ theme }) => theme.colors.red};
      }
      .on {
        color: ${({ theme }) => theme.colors.secondary.main};
      }
    }
  }

  .agent-data-card--body {
    font-size: 14px;
    padding-left: 8px;
    padding-top: 4px;
    .agent-data-card--body--text {
      margin-top: 8px;
    }
  }

  .agent-data-card--footer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding: 16px 16px 0;

    button {
      width: 36px;
      height: 36px;
      background-color: transparent;
      border: none;
      border-radius: 50%;

      &:hover {
        background-color: ${(props) => props.theme.colors.grey[400]};
        cursor: pointer;
      }

      &:active {
        background-color: ${({ theme }) => theme.colors.secondary.main};
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.bp.xs}px) {
    padding: 15px;
  }
`;
