import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { withI18n } from '../../../hocs';
import { Spinner } from '../../atoms';
import { AuthStyled } from './styled';
import images from '../../../assets/images';
import { AuthService } from '../../../services';
import Paths from '../../../config/routes';
import { USER_ROL } from '../../../models';

const Auth = ({ i18n, location }) => {
  const history = useHistory();

  useEffect(() => {
    const authAsync = async () => {
      try {
        await AuthService.doAuth(location.search);
        const userRol = AuthService.getUserRol();
        if (userRol === USER_ROL.AGENT) {
          window.location.href = Paths.AGENT;
        } else if (userRol === USER_ROL.AGENT_SUPERVISOR) {
          window.location.href = Paths.SUPERVISOR;
        } else if (userRol === USER_ROL.AUDITOR) {
          window.location.href = Paths.AGENT;
        } else {
          AuthService.logout();
          history.push(Paths.ERROR);
        }
      } catch (e) {
        history.push(Paths.LOGIN);
      }
    };
    authAsync();
  }, [location.search, history]);

  return (
    <AuthStyled>
      <div className="logo-holder">
        <img className="image-logo-big" src={images.logo} alt="logo" />
      </div>
      <div className="loading-holder">
        <Spinner size={40} />
        <p className="loading-text">{i18n.auth.loading}</p>
      </div>
    </AuthStyled>
  );
};

Auth.propTypes = {
  i18n: PropTypes.object,
  location: PropTypes.object,
};

const ErrorCard = ({ errorText }) => (
  <div className="auth-error-card">
    <p className="error-text">{errorText}</p>
  </div>
);

ErrorCard.propTypes = {
  errorText: PropTypes.string,
};

export default withI18n(Auth);
