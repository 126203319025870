import styled, { css } from 'styled-components';

const ComponentStyled = styled.button`
  background: ${({ theme }) => theme.colors.secondary.light};
  padding: 15px;
  cursor: pointer;
  position: relative;
  border: 1px solid ${({ theme }) => theme.colors.secondary.light};
  outline: none;
  transition: all 0.5s ease;

  .loading-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  span {
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.5px;
    color: ${({ theme }) => theme.colors.primary.main};
    display: flex;
    align-items: center;
    transition: all 0.5s ease;
    text-transform: uppercase;

    svg {
      margin-left: 15px;
      max-width: 19px;
      height: auto;
      path {
        fill: ${({ theme }) => theme.colors.white};
        transition: all 0.5s ease;
      }
    }
  }

  ${(props) =>
    props.disable &&
    css`
      background: ${props.theme.colors.grey[400]};
      border-color: ${props.theme.colors.grey[400]};

      span {
        color: ${props.theme.colors.grey[100]};
      }
    `};
`;

export default ComponentStyled;
