const TRANFER_STATUS_MESSAGES = {
  AGENT_TRANSFER_START: 'AGENT_TRANSFER_START',
  AGENT_TRANSFER_ERROR: 'AGENT_TRANSFER_ERROR',
  AGENT_TRANSFER_NO_AGENTS: 'AGENT_TRANSFER_NO_AGENTS',
  AGENT_TRANSFER_SAME_AGENT: 'AGENT_TRANSFER_SAME_AGENT',
  AGENT_TRANSFER_DONE: 'AGENT_TRANSFER_DONE',
};

Object.freeze(TRANFER_STATUS_MESSAGES);

export default TRANFER_STATUS_MESSAGES;
