import styled from 'styled-components';

const MessagesStyled = styled.div`

  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  
  background-color: ${(props) => props.theme.colors.chatBackground};
  .messages-list-container {


    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    background-color: ${(props) => props.theme.colors.chatBackground};
      .messages-list-holder {
        flex: 1;
        padding: 8px 16px 8px 16px;
        overflow-y: auto;
      }

    .message-list-scroll-to-bottom-btn {
      opacity: ${({ showScrollToBottomBtn }) => (showScrollToBottomBtn ? '1' : '0')};
      position: absolute;
      right: 0;
      bottom: 97px;
      background-color: white;
      display: ${({ showScrollToBottomBtn }) => (showScrollToBottomBtn ? 'flex' : 'none')};
      align-items: center;
      justify-content: center;
      padding: 8px 14px 8px 8px;
      border-top-left-radius: 12px;
      border-bottom-left-radius: 12px;
      -webkit-box-shadow: -4px 0px 4px 2px rgba(20, 20, 20, 0.15);
      box-shadow: -4px 0px 4px 2px rgba(20, 20, 20, 0.15);

      .message-list-scroll-to-bottom-btn-icon {
        border: 2px solid ${({ theme }) => theme.colors.cyan};
        border-radius: 50%;
        fill: ${({ theme }) => theme.colors.cyan};
      }

      &:hover {
        cursor: pointer;

        .message-list-scroll-to-bottom-btn-icon {
          opacity: 0.6;
        }
      }
    }
  }

  .grey-p {
    padding: 0px;
    margin-top: 15px;
  }
  .button-load-more-messages {
    display: block;
    margin: 0 auto;
    background-color: #0084ff;
    border: none;
    color: #ffffff;
    padding: 10px 20px;
    border-radius: 25px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    font-size: 16px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
  
    &:hover {
      background-color: #005daa;
      transform: translateY(-2px);
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    }
  }
  .messages-bar-holder {


    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 3px 15px;
    background-color: #f5f5f5;
    border-bottom: 1px solid #ddd;
    -webkit-box-shadow: 0px 5px 5px -3px rgba(97, 97, 97, 1);
    -moz-box-shadow: 0px 5px 5px -3px rgba(97, 97, 97, 1);
    box-shadow: 0px 5px 5px -3px rgba(97, 97, 97, 1);

    .chat-title {
      flex: 1;
      text-align: center;

      .bar-small-text {
        font-size: 16px;
        font-style: italic;
        color: ${(props) => props.theme.colors.grey[200]};
      }
    }
    button {
      width: 36px;
      height: 36px;
      background-color: transparent;
      border: none;
      border-radius: 50%;
      margin-left: 8px;

      &:hover {
        background-color: ${(props) => props.theme.colors.grey[400]};
        cursor: pointer;

        .positive-action {
          fill: ${({ theme }) => theme.colors.secondary.main};
        }

        .negative-action {
          fill: ${({ theme }) => theme.colors.red};
        }
      }

      &:active {
        .negative-action {
          fill: ${({ theme }) => theme.colors.white};
        }

        .positive-action {
          fill: ${({ theme }) => theme.colors.primary.main};
        }
      }

      .msg-bar-icon {
        position: relative;
        top: 1px;
        fill: ${({ theme }) => theme.colors.grey[200]};
      }
    }
    .close-btn:active {
      background-color: ${(props) => props.theme.colors.red};
    }
    .transfer-btn:active {
      background-color: ${({ theme }) => theme.colors.secondary.main};
    }
  }
  .empty-messages-holder {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;

    .empty-messages-card {
      max-width: 75%;
      background-color: ${({ theme }) => theme.colors.grey[400]};
      padding: 20px 40px;
      border-radius: 8px;
      -webkit-box-shadow: 0px 0px 8px 0px rgba(75, 75, 75, 1);
      -moz-box-shadow: 0px 0px 8px 0px rgba(75, 75, 75, 1);
      box-shadow: 0px 0px 8px 0px rgba(75, 75, 75, 1);

      .empty-messages-title {
        color: ${(props) => props.theme.colors.primary.darker};
        font-size: 21px;
        margin-bottom: 20px;
      }
      .empty-messages-text {
        font-size: 14px;
        color: ${(props) => props.theme.colors.grey[200]};
        text-align: center;
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.bp.md}px) {
    .messages-bar-holder {
      .chat-title {
        text-align: left;
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.bp.sm}px) {
    .empty-messages-holder {
      .empty-messages-card {
        padding: 15px;
      }
    }
  }
`;

export { MessagesStyled };
