import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { withI18n } from '../../../hocs';
import { CancelButton, SubmitButton } from '../../atoms';

import ModalTemplateStyled from './styled';

const ModalTemplate = ({ i18n, show, title, templateData, onSubmit, onCancel }) => {
  const [templateName, setTemplateName] = useState(templateData && templateData.title);
  const [templateNameTouched, setTemplateNameTouched] = useState(false);
  const [message, setMessage] = useState(templateData && templateData.message);
  const [messageTouched, setMessageTouched] = useState(false);

  const getTemplateIfValid = () => templateName && message && { title: templateName, message };

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const values = getTemplateIfValid();

    setTemplateNameTouched(true);
    setMessageTouched(true);

    if (values) {
      onSubmit(values);
      setTemplateName('');
      setMessage('');
      setTemplateNameTouched(false);
      setMessageTouched(false);
    }
  };

  const handleCancel = () => {
    setTemplateName('');
    setMessage('');
    setTemplateNameTouched(false);
    setMessageTouched(false);
    onCancel();
  };

  return (
    <ModalTemplateStyled show={show}>
      <div className="modal__template-form">
        <h2 className="modal__title">{title}</h2>
        <input
          required
          type="text"
          placeholder={i18n.templateModal.titlePlaceholder}
          value={templateName || ''}
          onClick={() => setTemplateNameTouched(true)}
          onChange={(e) => setTemplateName(e.target.value)}
          className={`input-modal ${templateNameTouched && !templateName && 'invalid-input'} `}
        />
        <textarea
          required
          placeholder={i18n.templateModal.messagePlaceholder}
          value={message || ''}
          onClick={() => setMessageTouched(true)}
          onChange={(e) => setMessage(e.target.value)}
          className={`text-area-modal input-modal ${messageTouched && !message && 'invalid-input'} `}
        />
        <div className="modal__bottom-buttons">
          <CancelButton onClick={handleCancel} label={i18n.buttons.cancel} />
          <SubmitButton onClick={handleSubmit} label={i18n.modalSF.saveAndCloseConv} />
        </div>
      </div>
    </ModalTemplateStyled>
  );
};

ModalTemplate.propTypes = {
  i18n: PropTypes.object,
  show: PropTypes.bool.isRequired,
  title: PropTypes.string,
  templateData: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    message: PropTypes.string,
  }),
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

export default withI18n(ModalTemplate);
