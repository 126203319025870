import styled from 'styled-components';

const EventFlowMessageBubbleStyled = styled.li`
  position: relative;
  padding-top: 8px;
  padding-bottom: 8px;
  ${({ isThereErrorIcon }) => (isThereErrorIcon ? 'padding-right: 40px;' : '')}
  text-align: ${({ sendType }) => ((sendType === 'operator' || sendType === 'proactive') ? 'left' : 'right')};

  .error-icon-holder {
    position: absolute;
    top: 20px;
    right: 0;
    cursor: pointer;
    .info-icon {
      width: 28px;
      height: 28px;
      margin-left: 8px;
      margin-bottom: 4px;
    }
    .error {
      fill: ${({ theme }) => theme.colors.red};
    }
  }

  .message-text {
    white-space: pre-wrap;
  }

  .info-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    .info-icon {
      width: 16px;
      height: 16px;
      margin-left: 8px;
      margin-bottom: 4px;
      fill: gray;
    }
    .read {
      fill: ${({ theme }) => theme.colors.checkMarkBlue};
    }
    .error {
      fill: ${({ theme }) => theme.colors.red};
    }
  }

  .deleted-info-holder {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 4px;
    padding-right: 8px;

    .info-icon {
      width: 16px;
      height: 16px;
      margin-right: 8px;
      margin-bottom: 4px;
      fill: gray;
    }
    .deleted-text {
      color: gray;
      font-size: 14px;
      font-style: italic;
    }
  }
    .linked-message-box {
    background-color: ${({ theme }) => theme.colors.grey[400]}
    padding: 10px;
    margin-bottom: 10px;
    color:  ${({ theme }) => theme.colors.grey[200]}
  }
  .interactive-header-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 5px;
  }
  .interactive-body-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .interactive-body-footer {
    color: #8696a0;
  }
  .interactive-header-text {
    font-size: 15px;
    padding-right: 12px;
    color: #333333;
    font-weight: bold;
  }
  .interactive-body-text {
    font-size: 13px;
    padding-right: 12px;
    color: #333333;
    margin-bottom: 7px;
  }
  .header-template-text {
    font-weight: bold;
    font-size: 15px;
    margin-bottom: 10px;
  }
  .header-template-container {
    display: flex;
  }
  img {
    width: 100%;
  }
`;

export { EventFlowMessageBubbleStyled };
