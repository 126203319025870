/* eslint-disable react/prop-types */
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { USER_ROL } from '../../../models';
import { AuthService } from '../../../services';
import Paths from '../../../config/routes';

export default function SupervisorRoute({ component: Component, ...rest }) {
  const userRol = AuthService.getUserRol();
  if (userRol !== USER_ROL.AGENT_SUPERVISOR) {
    return <Redirect to={{ pathname: Paths.NOT_FOUND }} />;
  }

  return <Route {...rest} render={(props) => <Component {...props} />} />;
}
