import React from 'react';
import PropTypes from 'prop-types';
import StatisticPanelStyled from './styled';
import { AgentsSupStatsIcon, ConversationsSupStatsIcon, SettingsInputComponentIcon, UsersSupStatsIcon, WebchatIcon, MessengerIcon } from '../../atoms';
import WhatsAppLogo from '../../../assets/images/whatsapp_logo.svg';
import { useSocketContext } from '../../../sockets/context';
import i18n from '../../../assets/i18n';

const {
  titleAgents,
  titleUsers,
  titleConversations,
  titleChannels,
  registeredAgentsPlural,
  registeredAgentsSing,
  onlineAgentsPlural,
  onlineAgentsSing,
  availableAgentsPlural,
  availableAgentsSing,
  quededUsersPlural,
  quededUsersSing,
  avgWaitTimeStr,
  maxWaitTimeStr,
  openedConvsNowPlural,
  openedConvsNowSing,
  convsPerAgentSing,
  convsPerAgentPlural,
  convSing,
  convPlural,
} = i18n.supervisor.statistics;

const AgentsStats = ({ title, registeredAgents, onlineAgents, availableAgents }) => (
  <div className="stats-panel--card-body">
    <div className="stats-panel--card-header">
      <AgentsSupStatsIcon className="card-header--icon" />
      <p className="card-header--title">{title}</p>
    </div>
    <div className="stats-panel--card-text-holder">
      <p className="stats-panel--stats-text">
        {registeredAgents} {registeredAgents !== 1 ? registeredAgentsPlural : registeredAgentsSing}
      </p>
      <p className="stats-panel--stats-text">
        {onlineAgents} {onlineAgents !== 1 ? onlineAgentsPlural : onlineAgentsSing}
      </p>
      <p className="stats-panel--stats-text">
        {availableAgents} {availableAgents !== 1 ? availableAgentsPlural : availableAgentsSing}
      </p>
    </div>
  </div>
);

AgentsStats.propTypes = {
  title: PropTypes.string,
  registeredAgents: PropTypes.number,
  onlineAgents: PropTypes.number,
  availableAgents: PropTypes.number,
};

const UsersStats = ({ title, quededUsers, avgWaitingTime, maxWaitingTime }) => (
  <div className="stats-panel--card-body">
    <div className="stats-panel--card-header">
      <UsersSupStatsIcon className="card-header--icon" />
      <p className="card-header--title">{title}</p>
    </div>
    <div className="stats-panel--card-text-holder">
      <p className="stats-panel--stats-text">
        {quededUsers} {quededUsers !== 1 ? quededUsersPlural : quededUsersSing}
      </p>
      <p className="stats-panel--stats-text">
        {avgWaitTimeStr} {avgWaitingTime}m
      </p>
      <p className="stats-panel--stats-text">
        {maxWaitTimeStr} {maxWaitingTime}m
      </p>
    </div>
  </div>
);

UsersStats.propTypes = {
  title: PropTypes.string,
  quededUsers: PropTypes.number,
  avgWaitingTime: PropTypes.number,
  maxWaitingTime: PropTypes.number,
};

const ConversationsStats = ({ title, openedConversations, convPerAgent }) => (
  <div className="stats-panel--card-body">
    <div className="stats-panel--card-header">
      <ConversationsSupStatsIcon className="card-header--icon" />
      <p className="card-header--title">{title}</p>
    </div>
    <div className="stats-panel--card-text-holder">
      <p className="stats-panel--stats-text">
        {openedConversations} {openedConversations !== 1 ? openedConvsNowPlural : openedConvsNowSing}
      </p>
      <p className="stats-panel--stats-text">
        {convPerAgent} {convPerAgent !== 1 ? convsPerAgentPlural : convsPerAgentSing}
      </p>
    </div>
  </div>
);

ConversationsStats.propTypes = {
  title: PropTypes.string,
  openedConversations: PropTypes.number,
  convPerAgent: PropTypes.string,
};

const ChannelsStats = ({ title, waChannel, webChatChannel, messengerChannel }) => (
  <div className="stats-panel--card-body">
    <div className="stats-panel--card-header">
      <SettingsInputComponentIcon className="card-header--icon" />
      <p className="card-header--title">{title}</p>
    </div>
    <div className="stats-panel--card-text-holder">
      <div className="card-text-holder--channel-info">
        <img src={WhatsAppLogo} alt="whatsapp logo" />
        <p className="stats-panel--stats-text">
          {waChannel} {waChannel !== 1 ? convPlural : convSing}
        </p>
      </div>
      <div className="card-text-holder--channel-info">
        <MessengerIcon className="icon" />
        <p className="stats-panel--stats-text">
          {messengerChannel} {messengerChannel !== 1 ? convPlural : convSing}
        </p>
      </div>
      <div className="card-text-holder--channel-info">
        <WebchatIcon className="icon" />
        <p className="stats-panel--stats-text">
          {webChatChannel} {webChatChannel !== 1 ? convPlural : convSing}
        </p>
      </div>
    </div>
  </div>
);

ChannelsStats.propTypes = {
  title: PropTypes.string,
  waChannel: PropTypes.number,
  messengerChannel: PropTypes.number,
  webChatChannel: PropTypes.number,
};

const StatisticPanel = () => {
  const { stats, supervisorAgentStatus } = useSocketContext();
  const { avgTimeInQueue, maxTimeInQueue, conversationsInQueue, conversationsActive, agentsOnline, agentsAvailable } = stats || {};
  let waChannelCount = 0;
  let webChatChannelCount = 0;
  let messengerChannelCount = 0;
  let totalConversationCount = 0;
  let avgConversationsByAgent;
  const registeredAgents = supervisorAgentStatus.length;

  supervisorAgentStatus.forEach((agentStatus) => {
    const { conversations } = agentStatus;
    if (conversations?.length > 0) {
      totalConversationCount += conversations?.length;
      conversations?.forEach((conv) => {
        const { /* assignedAt, */ channel } = conv;
        // const isAsignedToday = moment(assignedAt).isSame(moment(), 'day');

        if (channel === 'whatsapp') {
          waChannelCount += 1;
        } else if (channel === 'messenger') {
          messengerChannelCount +=1;
        } else {
          webChatChannelCount += 1;
        }
      });
    }
  });

  if (totalConversationCount > 0) {
    avgConversationsByAgent = agentsOnline ? (totalConversationCount / agentsOnline).toFixed(2) : 0;
  }

  return (
    <StatisticPanelStyled>
      <AgentsStats title={titleAgents} registeredAgents={registeredAgents} onlineAgents={agentsOnline} availableAgents={agentsAvailable} />
      <UsersStats
        title={titleUsers}
        quededUsers={conversationsInQueue}
        avgWaitingTime={Math.floor(avgTimeInQueue)}
        maxWaitingTime={Math.floor(maxTimeInQueue)}
      />
      <ConversationsStats title={titleConversations} openedConversations={conversationsActive} convPerAgent={avgConversationsByAgent} />
      <ChannelsStats title={titleChannels} waChannel={waChannelCount} messengerChannel={messengerChannelCount} webChatChannel={webChatChannelCount} />
    </StatisticPanelStyled>
  );
};

export default StatisticPanel;
