import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { RegularInput, GeneralButton } from '../../atoms';
import { withI18n } from '../../../hocs';
import ComponentStyled from './styled';

const LoginForm = ({ i18n, onSubmit, isFetching, errorLoginMessage }) => {
  return (
    <ComponentStyled>
      <p className="login-title">{i18n.login.title}</p>
      <div className={errorLoginMessage ? 'error-box' : 'hidden'}>
        {errorLoginMessage}
      </div>
      <Formik
        initialValues={{
          mail: '',
          password: '',
        }}
        validate={(values) => {
          const errors = {};
          if (!values.mail) {
            errors.mail = i18n.errors.requiredField;
          }
          if (!values.password) {
            errors.password = i18n.errors.requiredField;
          }
          return errors;
        }}
        onSubmit={onSubmit}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <RegularInput
              error={errors.mail && touched.mail && errors.mail}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.mail}
              name="mail"
              placeholder={i18n.login.email}
              marginBottom={15}
            />
            <RegularInput
              error={errors.password && touched.password && errors.password}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              name="password"
              placeholder={i18n.login.pass}
              type="password"
            />
            <div className="btn-wrapper">
              <GeneralButton color="secondary" onClick={handleSubmit} isFetching={isFetching} label={i18n.login.login} />
            </div>
           
          </form>
        )}
      </Formik>
    </ComponentStyled>
  );
};

LoginForm.defaultProps = {
  onSubmit: () => {},
  isFetching: false,
};

LoginForm.propTypes = {
  i18n: PropTypes.object,
  onSubmit: PropTypes.func,
  isFetching: PropTypes.bool,
};

export default withI18n(LoginForm);
