import React from 'react';
import PropTypes from 'prop-types';
import { ButtonsTemplateBubbleStyled } from './styled';
import { replacePlaceholders } from './utils';



export const InteractiveExtraContent = ({ buttonsContent, buttonsVariables, disableClick }) => {
  const buttonsToPaint = buttonsContent?.buttons;
  return (
    <ButtonsTemplateBubbleStyled>
      {buttonsToPaint ?
        <div className="template-buttons-wrapper">
          {buttonsToPaint?.length && buttonsToPaint.map((el, index) => {

            const { type, text, /* url, example, phoneNumber */ } = el || {};
            let onClickComposed;
            /* if (type === 'QUICK_REPLY') {

            } */
            if (type === 'URL') {
              onClickComposed = () => window.open(replacePlaceholders(el, buttonsVariables), '_blank');
            }
            /* if (type === 'PHONE_NUMBER') {

            } */
            if (disableClick) {
              onClickComposed = null;
            }
            return (
              <div onClick={onClickComposed} key={el} className={`button-interactive ${((index < 2 && buttonsToPaint.length > 1)) ? 'button-not-last' : 'button-last'}`}>
                <span>{text}</span>
              </div>
            );
          })}
        </div> : <div/>
      }
    </ButtonsTemplateBubbleStyled>
  )
};

InteractiveExtraContent.propTypes = {
  buttonsContent: PropTypes.object,
  buttonsVariables: PropTypes.object,
  disableClick: PropTypes.bool,
};
