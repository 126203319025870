/* eslint-disable no-useless-escape */
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { TextMessageBubbleStyled } from './styled';
import i18n from '../../../assets/i18n';

const TextMessageBubble = ({ colorClass, message, date, sendType, statusIcon, deletedIcon, errorIcon }) => {
  const regExpressionForStrong = /\*(.*?)\*/g;
  const regExpressionForEm = /\_(.*?)\_/g;
  const regExpressionForScratch = /\~(.*?)\~/g;
  let formattedMessage = message;
  const isBold = regExpressionForStrong.test(message);
  const isItalic = regExpressionForEm.test(message);
  const isScratched = regExpressionForScratch.test(message);

  if (isBold) {
    formattedMessage = formattedMessage.replace(regExpressionForStrong, "<strong>$1</strong>");
  }
  if (isItalic) {
    formattedMessage = formattedMessage.replace(regExpressionForEm, "<em>$1</em>");
  }
  if (isScratched) {
    formattedMessage = formattedMessage.replace(regExpressionForScratch, "<del>$1</del>");
  }

  return (
  <TextMessageBubbleStyled sendType={sendType} colorClass={colorClass} isThereErrorIcon={!_.isNil(errorIcon)}>
    {errorIcon && (
      <div className="error-icon-holder" data-tip={i18n.errors.tooltipError} data-offset="{'left': 5}">
        {errorIcon}
      </div>
    )}
    <div className={colorClass}>
      {deletedIcon ? (
        <div className="deleted-info-holder">
          {deletedIcon}
          <p className="deleted-text" dangerouslySetInnerHTML={{__html: formattedMessage }} />
        </div>
      ) : (
        <>
          <p className="message-text" dangerouslySetInnerHTML={{__html: formattedMessage }} />
          <div className="info-row">
            <p className="timespan-message">{date}</p>
            {statusIcon}
          </div>
        </>
      )}
    </div>
  </TextMessageBubbleStyled>
)}

TextMessageBubble.propTypes = {
  colorClass: PropTypes.string,
  message: PropTypes.string,
  date: PropTypes.string,
  sendType: PropTypes.string,
  statusIcon: PropTypes.any,
  deletedIcon: PropTypes.any,
  errorIcon: PropTypes.any,
};

export default TextMessageBubble;
