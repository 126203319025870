import styled from 'styled-components';

const NewMessageBadgeStyled = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 9px;
  position: relative;
  align-self: flex-start;
  margin-top: 4px;
  /* top: 10px; */
  /* left: -10px; */
  left: 0px;
  display: flex;
  background-color: ${({ theme }) => theme.colors.secondary.light};
  align-items: center;
  justify-content: center;

  span {
    font-size: 8px;
  }
`;

export { NewMessageBadgeStyled };
