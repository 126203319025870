import React from 'react';
import PropTypes from 'prop-types';
import { ClipLoader } from 'react-spinners';
import { ErrorIcon } from '../../../atoms';
import i18n from '../../../../assets/i18n';
import { BulletedListIcon } from '../../../atoms/svg-icons';
 

export const MessageInteractiveHolder = ({ isFetching, isDownloadError, data }) => {
  const { type, action, body, footer, header } = data;
  return (
    <div className="interactive-holder">
      {isFetching && <ClipLoader size={40} loading={isFetching} />}
      {isDownloadError ? (
        <>
          <ErrorIcon className="error-icon" />
          <p className="bubble-info-text">{i18n.errors.interactiveNotAvailable}</p>
        </>
      ) : (
        data &&
          <div className="interactive-wrapper">
            {header?.text ? <div className="interactive-header-wrapper">
              <p className="interactive-header-text">{header?.text}</p>
            </div> : <div/>}
            {body?.text ? <div className="interactive-body-wrapper">
              <p className="interactive-body-text">{body?.text}</p>
            </div> : <div/>}
            {footer?.text ? <div className="interactive-body-wrapper">
                <p className="interactive-body-footer">{footer?.text}</p>
            </div> : <div/>}
            {type === 'ctaUrl' && (
              <div className='interactive-action-ctaUrl-container'>
                <a className="interactive-action-ctaUrl" href={action?.url} target="_blank" rel="noreferrer">
                  <svg
                    viewBox="0 0 19 18"
                    height="18"
                    width="19"
                    preserveAspectRatio="xMidYMid meet"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <title>launch</title>
                    <path
                      d="M14,5.41421356 L9.70710678,9.70710678 C9.31658249,10.0976311 8.68341751,10.0976311 8.29289322,9.70710678 C7.90236893,9.31658249 7.90236893,8.68341751 8.29289322,8.29289322 L12.5857864,4 L10,4 C9.44771525,4 9,3.55228475 9,3 C9,2.44771525 9.44771525,2 10,2 L14,2 C15.1045695,2 16,2.8954305 16,4 L16,8 C16,8.55228475 15.5522847,9 15,9 C14.4477153,9 14,8.55228475 14,8 L14,5.41421356 Z M14,12 C14,11.4477153 14.4477153,11 15,11 C15.5522847,11 16,11.4477153 16,12 L16,13 C16,14.6568542 14.6568542,16 13,16 L5,16 C3.34314575,16 2,14.6568542 2,13 L2,5 C2,3.34314575 3.34314575,2 5,2 L6,2 C6.55228475,2 7,2.44771525 7,3 C7,3.55228475 6.55228475,4 6,4 L5,4 C4.44771525,4 4,4.44771525 4,5 L4,13 C4,13.5522847 4.44771525,14 5,14 L13,14 C13.5522847,14 14,13.5522847 14,13 L14,12 Z"
                      fill="currentColor"
                      /* fill-rule="nonzero" */
                    />
                  </svg>
                  <span className="span-display-text-ctaUrl">
                    {action?.displayText}
                  </span>
                </a>
              </div>
            )}
            {type === 'list' && (
              <div className="interactive-bottom-list">
                <p className="interactive-icon"><BulletedListIcon /></p>
                <p className="interactive-bottom-list-text">{action?.button}</p>
              </div>
            )}
          </div>
      )}
    </div>
  )
};

MessageInteractiveHolder.propTypes = {
  isFetching: PropTypes.bool,
  isDownloadError: PropTypes.bool,
  data: PropTypes.object,
};
