import React from 'react';
import { ThemeProvider } from 'styled-components';
import Routes from '../routes';
import { theme, GlobalStyle } from '../../../config/styled';
import { Toast } from '../../organisms';


import 'react-toastify/dist/ReactToastify.min.css';

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Routes />
      <Toast />
    </ThemeProvider>
  );
};

export default App;
