import styled from 'styled-components';

export default styled.div`
  display: ${(props) => (props.show ? 'block' : 'none')};
  position: fixed;
  z-index: 500;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 100px 15px 15px 15px;

  .modal-banned-users_legend {
    flex-flow: row;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .table-banned {
    width: 100%;
  }

  .tr-table_banned {
    border-bottom: 1px solid #a6a8a8;
    align-items: center;
  }

  .th-banned {
    text-align: left;
    padding: 8px 8px 20px 0px;
    font-weight: bold;
  }

  .td-banned {
    text-align: left;
    padding: 16px 20px 16px 0px;
    vertical-align: middle;
  }

  .delete-icon {
    z-index: 100;
  }

  .modal-banned-users {
    display: ${(props) => (props.show ? 'flex' : 'none')};
    width: 800px;
    max-width: 100%;
    padding: 40px;
    border-radius: 8px;
    background-color: white;
    margin: auto;
    flex-flow: column;

    .modal-banned-users__title {
      color: ${({ theme }) => theme.colors.primary.main};
      padding-bottom: 20px;
      font-size: 21px;
      font-weight: bold;
    }

    .modal-banned-users__body {
      padding: 0 24px 20px;
      .modal-banned-users__user {
        flex-flow: row;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    .modal-buttons-row {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      padding-top: 10px;
      align-items: flex-end;
      flex: 2;
    }
  }

  @media (max-width: ${({ theme }) => theme.bp.md}px) {
    .modal-banned-users {
      padding: 15px;
    }
  }

  @media (max-width: ${({ theme }) => theme.bp.md}px) {
    padding: 15px;
    .modal-banned-users {
      padding: 15px;
      width: 100%;
      min-height: 100%;
    }
  }
`;
