import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import { BackButton } from '../../atoms';
import { Statistics } from '../../molecules';
import bp from '../../../config/breakpoints';
import { ReactComponent as MenuIcon } from '../../../assets/images/menu.svg';
import images from '../../../assets/images';
import StatusStyled from './styled';

const Status = ({ onClickMenu, isMainMenu, isChatMenu }) => {
  const isMediumScreen = useMediaQuery({ query: `(max-width: ${bp.md}px)` });

  const renderMenuIcon = () => {
    if (!isMainMenu && !isChatMenu) {
      return <MenuIcon onClick={onClickMenu} className="menu-icon" />;
    }

    if (isChatMenu) {
      return <BackButton onClick={onClickMenu} marginTop={0} />;
    }

    return null;
  };

  return (
    <StatusStyled>
      <img className="image-logo" src={images.logo} alt="logo" />
      {isMediumScreen ? renderMenuIcon() : <Statistics />}
    </StatusStyled>
  );
};

Status.defaultProps = {
  onClickMenu: () => {},
  isMainMenu: false,
  isChatMenu: false,
};

Status.propTypes = {
  onClickMenu: PropTypes.func,
  isMainMenu: PropTypes.bool,
  isChatMenu: PropTypes.bool,
};

export default Status;
