import React from 'react';
import PropTypes from 'prop-types';
import { withI18n } from '../../../hocs';
import { ButtonStyled } from './styled';
import { AGENT_STATUS } from '../../../models';

const ButtonAcceptChats = ({ i18n, onClick, agentStatus }) => {
  const label = agentStatus === AGENT_STATUS.AVAILABLE ? i18n.chats.noNewConversations : i18n.chats.acceptNewConversation;
  const status = agentStatus === AGENT_STATUS.AVAILABLE ? i18n.chats.available : i18n.chats.unavailable;

  return (
    <ButtonStyled agentStatus={agentStatus}>
      <div className="status-holder">
        <p className="my-status-label">{i18n.chats.myStatus}</p>
        <p className="my-status-value">{status}</p>
      </div>
      <div className="button-holder">
        <button onClick={onClick} className="chat-button ts ts-quick" type="button">
          {label}
        </button>
      </div>
    </ButtonStyled>
  );
};

ButtonAcceptChats.propTypes = {
  i18n: PropTypes.object,
  onClick: PropTypes.func,
  agentStatus: PropTypes.string,
};

export default withI18n(ButtonAcceptChats);
