import styled from 'styled-components';

export default styled.button`
  border: solid;
  border-width: 2px;
  border-radius: 4px;
  padding: 0 16px;
  min-width: 100px;
  margin-left: 5px;
  height: 32px;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);

  &:hover {
    border-color: ${(props) => props.theme.colors.secondary.main};
    color: ${(props) => props.theme.colors.secondary.main};
    cursor: pointer;
  }

  &:active {
    border-color: ${(props) => props.theme.colors.secondary.main};
    color: white;
  }
`;