import styled from 'styled-components';

export default styled.div`
  padding: 24px;
  height: 100%;
  display: flex;
  flex-direction: column;

  .conversation-detail--title {
    color: ${({ theme }) => theme.colors.primary.main};
    margin-bottom: 16px;
  }

  .conversation-detail--body {
    width: 800px;
    height: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    align-self: center;
    padding: 15px;

    .conversation-detail--body--messages-list {
      border: 1px solid #ccc;
      padding: 24px;
      width: 100%;
      height: 50vh;
      overflow: hidden;
      overflow-y: scroll;
      background-color: ${({ theme }) => theme.colors.chatBackground};
      border-radius: 4px;
      -webkit-box-shadow: 2px 2px 15px 2px rgba(0, 0, 0, 0.3);
      box-shadow: 2px 2px 15px 2px rgba(0, 0, 0, 0.3);

      .conversation-detail--body--messages-list--copy {
        color: gray;
        text-align: center;
        font-size: 16px;
        font-style: italic;
        margin-top: 32px;
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.bp.md}px) {
    padding: 15px 0;

    .conversation-detail--body {
      padding: 15px 0;
    }
  }
`;
