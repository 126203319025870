import styled from 'styled-components';

const LinkButtonStyled = styled.div`
  background-color: ${({ theme }) => theme.colors.primary.main};
  padding: 10px 16px;
  border-radius: 4px;
  a {
    color: white;
    font-size: 20px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.secondary.main};
  }
  &:active {
    background-color: ${({ theme }) => theme.colors.secondary.darker};
  }
`;

export { LinkButtonStyled };
