import styled, { css } from 'styled-components';

const ComponentStyled = styled.div`
  margin-bottom: ${(props) => props.marginBottom}px;
  display: flex;
  flex-flow: column;

  .label {
    font-weight: normal;
    font-size: 22px;
    line-height: 22px;
    color: ${({ theme }) => theme.colors.primary.main};
    margin-bottom: 25px;
  }

  .input-error {
    font-weight: normal;
    font-size: 11px;
    line-height: 11px;
    margin-top: 5px;
    color: ${({ theme }) => theme.colors.red};
    margin-bottom: 0;
  }

  .regular-input-wrapper {
    position: relative;
    flex: 1;
    display: flex;

    input {
      width: 100%;
      outline: none;
      border: 0;
      border-bottom: 1px solid ${({ theme }) => theme.colors.primary.main};
      padding: 15px;
      font-weight: normal;
      font-size: 15px;
      line-height: 15px;
      letter-spacing: 0.5px;
      color: ${({ theme }) => theme.colors.primary.main};
      transition: border-color 0.5s ease;
      background-color: ${({ theme }) => theme.colors.white};

      &[type='number']::-webkit-inner-spin-button,
      &[type='number']::-webkit-outer-spin-button,
      &[type='time']::-webkit-inner-spin-button,
      &[type='time']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &::-ms-clear {
        width: 0;
        height: 0;
      }

      &[type='time']::-webkit-clear-button {
        display: none;
      }

      ::placeholder {
        color: ${(props) => props.theme.colors.grey[100]};
      }

      :focus {
        border-color: ${({ theme }) => theme.colors.secondary.light};
      }
    }
  }

  ${(props) =>
    props.error !== '' &&
    css`
      .regular-input-wrapper {
        input {
          border-color: ${props.theme.colors.red};
        }
      }
    `};
`;

export default ComponentStyled;
