import React from 'react';
import PropTypes from 'prop-types';
import { AiOutlineEllipsis } from 'react-icons/ai';
import { OptionWrapper } from './styles';


export const TagOption = ({ children, innerRef, innerProps, data, setActionsMenu, isCreatingOption, canEdit }) => {
  const handleIconClick = (e) => {
    e.stopPropagation();
    const rect = e.target.getBoundingClientRect();
    setActionsMenu({
      isOpen: true,
      top: rect.bottom,
      left: rect.left,
      tagData: data,
    });
  };

  return (
    <OptionWrapper ref={innerRef} {...innerProps}>
      {children}
      {!isCreatingOption && canEdit && (
        <div style={{ position: 'relative' }}>
          <AiOutlineEllipsis onClick={handleIconClick} style={{ cursor: 'pointer' }} />
        </div>
      )}
    </OptionWrapper>
  );
};
TagOption.propTypes = {
  children: PropTypes.node,
  innerRef: PropTypes.func,
  innerProps: PropTypes.object,
  data: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    code: PropTypes.number,
  }),
  setActionsMenu: PropTypes.func,
  isCreatingOption: PropTypes.bool,
  canEdit: PropTypes.bool,
};