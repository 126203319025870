const FILE_INPUT_SPECS = {
  ACCEPT: {
    IMAGE: '.jpeg,.jpg,.png',
    VIDEO: '.mp4,.3gpp',
    AUDIO: '.mpeg,.aac,.mp4,.amr,.ogg,.mp3',
    DOCUMENT: '',
  },
  MAX_SIZE: {
    IMAGE: 5000000,
    VIDEO: 16000000,
    AUDIO: 16000000,
    DOCUMENT: 100000000,
  },
};
Object.freeze(FILE_INPUT_SPECS);

export default FILE_INPUT_SPECS;
