import React from 'react';
// import PropTypes from 'prop-types';
import { NewMessageBadgeStyled } from './styled';

// const NewMessageBadge = ({ num }) => (
//   <NewMessageBadgeStyled>
//     <span>{num}</span>
//   </NewMessageBadgeStyled>
// );

// NewMessageBadge.propTypes = {
//   num: PropTypes.number,
// };

const NewMessageBadge = () => <NewMessageBadgeStyled />;

export default NewMessageBadge;
