import React from 'react';
import PropTypes from 'prop-types';
import { ChatCellInfoStyled, TitleStyled, AliasStyled } from './styled';
import i18n from '../../../assets/i18n';
import TagsBox from '../../molecules/tags-box';

const ChatCellInfo = ({ title, profileName, row, dateLastInputMessage, dateLastUserMessage, topics,
  dateLastAgentMessage }) => (
  <ChatCellInfoStyled row={row}>
    <p title={title}>
      <TitleStyled title={title}>{title}</TitleStyled>
      <AliasStyled>{`${profileName && profileName !== '' ? `~${profileName}` : ''}`}</AliasStyled>
    </p>
    {dateLastInputMessage ? <p>
      <small>{`${i18n.chats.lastMessage} ${dateLastInputMessage || ''}`}</small>
    </p> : ''}
    {dateLastUserMessage ? <p>
      <small>{`${i18n.chats.lastMsgUser} ${dateLastUserMessage || ''}`}</small>
    </p> : ''}
    {dateLastAgentMessage ? <p>
      <small>{`${i18n.chats.lastMsgAgent} ${dateLastAgentMessage || ''}`}</small>
    </p> : ''}
    {topics?.length ? <TagsBox tags={topics} /> : <div/> }
  </ChatCellInfoStyled>
);

ChatCellInfo.propTypes = {
  title: PropTypes.string,
  profileName: PropTypes.string,
  row: PropTypes.bool,
  dateLastInputMessage: PropTypes.string,
  dateLastAgentMessage: PropTypes.string,
  dateLastUserMessage: PropTypes.string,
  topics: PropTypes.array,
};

export default ChatCellInfo;
