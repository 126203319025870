import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import { withRouter } from 'react-router-dom';
import { LoginForm } from '../../organisms';
import images from '../../../assets/images';
import i18n from '../../../assets/i18n';
import ComponentStyled from './styled';
import * as API from '../../../api';
import Paths from '../../../config/routes';
import PopupInstallPWA from '../../organisms/popup-install-pwa/PopupInstallPWA';

const Login = ({ history }) => {
  const [isFetchingLogin, setIsFetchingLogin] = useState(false);
  const [errorLoginMessage, setErrorLoginMessage] = useState('');

  const handleSubmitLogin = async (loginData) => {
    setIsFetchingLogin(true);
    const res = await API.logIn(loginData);
    setIsFetchingLogin(false);
    if (res && res.data) {
      setErrorLoginMessage('');
    }
    if (res && res.data && res.data.shortLived2FaToken) {
      history.push(Paths.DOUBLE_FACTOR_FORM)
    } else if (!res || res.ok === false || res.statusCode >= 400) {
      if (res && res.statusCode === 401) {
        switch (res.blockingReason) {
          case 'INACTIVITY':
            setErrorLoginMessage(i18n.login.userLockedInactivity);
            break;
          case 'RENEW_PASSWORD':
            setErrorLoginMessage(i18n.login.tooOldPassword);
            await API.requestPasswordReset(loginData.mail);
            break;
          case 'TOO_MANY_LOGIN_ATTEMPTS':
            setErrorLoginMessage(i18n.login.tooManyAttempts);
            break;
          default:
            setErrorLoginMessage(i18n.login.invalidBadges);
            break;
        }
      }
    }
    if (res && res.user && res.data) {
      const accountId = _get(res, 'user.accountId');
      const accessToken = _get(res, 'data.userToken');
      if (accountId && accessToken) {
        history.push(Paths.APPLICATIONS);
      }
    }
  };


  return (
    <ComponentStyled>
      <PopupInstallPWA />
      <div className="logo-holder">
        <img className="logo" src={images.logoWhite} alt="logo" />
      </div>
      <div className="login-container">
        <LoginForm onSubmit={handleSubmitLogin} isFetching={isFetchingLogin} errorLoginMessage={errorLoginMessage} />
      </div>
    </ComponentStyled>
  );
};

Login.propTypes = {
  history: PropTypes.object,
};

export default withRouter(Login);
