import React from 'react'; 
import {
  GoogleMap,
  Marker,
  useLoadScript,
} from "@react-google-maps/api";

import PropTypes from 'prop-types';
import i18n from '../../../assets/i18n';

const mapStyles = {
  width: 'unset',
  height: 'unset',
  margin: 3,
  zIndex: 1,
};
const containerStyle = {
  position: 'static',  
  width: '100%',
  height: '100%'
}
const MapContainer = ({data}) => {
  const {latitude, longitude, address, name} = data;
  const center = {
    lat: latitude,
    lng: longitude
  }
  const titleMessage = (_name, _address) => {
    if (_name && _address) {
      return `${_name} ${_address}`;
    }
    if (_name) {
      return `${_name}`;
    }
    if (_address) {
      return `${_address}`;
    }
  }
  const {isLoaded, loadError} = useLoadScript({
    googleMapsApiKey: "AIzaSyBI8qgM3N2b-FolN3I_xDoMvrkv-ofOcps"
  })
    return (
      <>
        {isLoaded ?
        <>
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={15}
            options={{ disableDefaultUI: true }}
            style={mapStyles}
          >
            <Marker title={titleMessage(name, address)} position={{ lat:  latitude, lng: longitude}} />
          </GoogleMap>
          {(name || address) &&
            <div className="map-texts">
              <p className="map-text-name">{name}</p>
              <p>{address}</p>
            </div>
          }
        </>
        : <p>{i18n.auth.loading}</p>
        }
        {loadError && <p>{i18n.errors.locationFileNotAvailable}</p>}
    </>
    );

};

MapContainer.propTypes = {
  data: PropTypes.object,
};

export default (MapContainer)