import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import ConversationDetailStyled from './styled';
import { useSocketContext } from '../../../sockets/context';
import { supervisorUnsubscribeToConversation, closeConversation, supervisorTransferConversation } from '../../../sockets/emit';
import i18n from '../../../assets/i18n';
import { BackButton } from '../../atoms';
import { MessagesList, AgentConversationCard, ModalTransferAgents } from '../../molecules';
import Paths from '../../../config/routes';

const locale = navigator.language;
const formatDateString = locale === 'es' ? 'DD-MM-YYYY HH:mm:ss' : 'YYYY-MM-DD hh:mm:ss A';

function ConversationDetailPanel() {
  const history = useHistory();
  const { conversations, supervisorAgentStatus, removeSubscribedSupervisorConv } = useSocketContext();
  const { agentId, conversationId } = useParams();

  const [agentStats, setAgentStats] = useState();
  const [statsConv, setStatsConv] = useState();
  useEffect(() => {
    const agentStatsData = supervisorAgentStatus.find((stats) => {
      return stats.agentId === parseInt(agentId, 10);
    });
    const statsConvData =
      agentStats &&
      agentStats.conversations.find((conv) => {
        return conv.conversationSessionId === parseInt(conversationId, 10);
      });
    setAgentStats(agentStatsData);
    setStatsConv(statsConvData);
  }, [supervisorAgentStatus, agentStats, agentId, conversationId]);

  const [conversation, setConversation] = useState({});

  useEffect(() => {
    const convData = conversations.find((conv) => {
      return conv.conversationSessionId === parseInt(conversationId, 10);
    });
    setConversation(convData);
  }, [conversations, conversationId]);

  const data = {};
  if (statsConv) {
    data.channel = statsConv.channel;
    data.conversationSessionId = statsConv.conversationSessionId;
    data.profileName = statsConv.profileName;
    data.lastMessageReceived = statsConv.lastMessageReceived;
    data.lastMessageSent = statsConv.lastMessageSent;
  }

  const agentName = agentStats ? agentStats.name : i18n.supervisor.notAvailableAKA;

  const handleCloseConversationClick = (conversationSessionId) => {
    closeConversation(conversationSessionId);
  };

  const [showTransferModal, setShowTransferModal] = useState(false);
  const [selectedConversationSessionId, setSelectedConversationSessionId] = useState(-1);
  const toggleShowTransferModal = () => setShowTransferModal(!showTransferModal);

  const handleTransferConversationClick = (conversationSessionId) => {
    setSelectedConversationSessionId(conversationSessionId);
    setShowTransferModal(true);
  };

  const handleFinishModalTransfer = (transferAgentId) => {
    toggleShowTransferModal();
    supervisorTransferConversation(selectedConversationSessionId, transferAgentId);
    history.push(Paths.SUPERVISOR_AGENT_DETAIL.replace(':agentId', agentId));
  };

  const handleGoBack = () => {
    supervisorUnsubscribeToConversation(data.conversationSessionId);
    removeSubscribedSupervisorConv(data.conversationSessionId);
    history.goBack();
  };

  return (
    <ConversationDetailStyled>
      {agentStats && statsConv ? (
        <>
          <p className="conversation-detail--title">{i18n.formatString(i18n.supervisor.conversationsOf, { agentName })}</p>
          <div className="conversation-detail--body">
            <AgentConversationCard
              data={data}
              hideSeeButton
              onCloseConversationClick={handleCloseConversationClick}
              onTransferConversationClick={handleTransferConversationClick}
            />
            <div className="conversation-detail--body--messages-list">
              {conversation ? (
                <MessagesList chat={conversation} i18n={i18n} formatDateString={formatDateString} onMultimediaClick={() => {}} />
              ) : (
                <p className="conversation-detail--body--messages-list--copy">{i18n.supervisor.noMessagesAvailable}</p>
              )}
            </div>
            <BackButton onClick={handleGoBack} />
          </div>
        </>
      ) : null}
      <ModalTransferAgents
        show={showTransferModal}
        onModalCancel={toggleShowTransferModal}
        onModalFinish={handleFinishModalTransfer}
        conversationSessionId={selectedConversationSessionId}
      />
    </ConversationDetailStyled>
  );
}

export default ConversationDetailPanel;
