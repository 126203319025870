import styled from 'styled-components';

export default styled.div`
  .className {
  }

  .toastClassName {
    border-radius: 8px;
  }

  .bodyClassName {
    font-family: Rubik;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    text-align: center;
  }

  .progressClassName {
  }

  .Toastify__close-button--default {
    color: white !important;
    font-size: 20px !important;
    font-weight: 300 !important;
    opacity: 0.75 !important;
  }

  .Toastify__toast--default {
    background: #a6a8a8;
    color: #333333;
  }

  .Toastify__toast--info {
    background: #009eff;
    color: white;
  }

  .Toastify__toast--warning {
    background: #f9d066;
    color: #333333;
  }

  .Toastify__toast--error {
    background: #ed5656;
    color: white;
  }

  .Toastify__toast--success {
    background: #7fcb39;
    color: white;
  }
`;
