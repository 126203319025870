import React from 'react';
import PropTypes from 'prop-types';
import { ModalStyled } from './styled';
import { withI18n } from '../../../hocs';
import { MODAL_TYPE } from '../../../models';
import { AlertIcon } from '../../atoms';

const CancelButton = ({ onClick, label }) => (
  <button onClick={onClick} className="modal-button-cancel ts ts-normal" type="button">
    {label}
  </button>
);

CancelButton.propTypes = {
  onClick: PropTypes.func,
  label: PropTypes.string,
};

const SubmitButton = ({ onClick, action, label }) => (
  <button onClick={onClick} className={`${action === MODAL_TYPE.TRANSFER_CONV ? 'modal-button-transfer' : 'modal-button-finish'} ts ts-normal`} type="button">
    {label}
  </button>
);

SubmitButton.propTypes = {
  onClick: PropTypes.func,
  label: PropTypes.string,
  action: PropTypes.oneOf([MODAL_TYPE.TRANSFER_CONV, MODAL_TYPE.CLOSE_CONV]),
};

const getModalTextFromAction = ({ action, i18n }) => {
  const modalText = {};
  switch (action) {
    case MODAL_TYPE.TRANSFER_CONV:
      modalText.title = i18n.chats.transferChatModal.title;
      modalText.body = i18n.chats.transferChatModal.body;
      modalText.end = i18n.chats.transferChatModal.end;
      modalText.cancel = i18n.chats.transferChatModal.cancel;
      break;
    case MODAL_TYPE.CLOSE_CONV:
      modalText.title = i18n.chats.endChatModal.title;
      modalText.body = i18n.chats.endChatModal.body;
      modalText.end = i18n.chats.endChatModal.end;
      modalText.cancel = i18n.chats.endChatModal.cancel;
      break;
    default:
  }
  return modalText;
};

const Modal = ({ show, i18n, onModalCancel, onModalFinish, action }) => {
  const { title, body, end, cancel } = getModalTextFromAction({ action, i18n });
  return (
    <ModalStyled show={show}>
      <div className="modal-content">
        <h2 className="modal-title">{title}</h2>
        <div className="modal-body">
          <AlertIcon className="modal-icon" />
          <p className="modal-text">{body}</p>
          <hr />
          <div className="modal-buttons-row">
            <CancelButton onClick={onModalCancel} label={cancel} />
            <SubmitButton onClick={onModalFinish} label={end} action={action} />
          </div>
        </div>
      </div>
    </ModalStyled>
  );
};

Modal.propTypes = {
  show: PropTypes.bool,
  i18n: PropTypes.object,
  onModalFinish: PropTypes.func,
  onModalCancel: PropTypes.func,
  action: PropTypes.oneOf([MODAL_TYPE.TRANSFER_CONV, MODAL_TYPE.CLOSE_CONV]),
};

export default withI18n(Modal);
