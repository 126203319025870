import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import images from '../../../assets/images';
import Paths from '../../../config/routes';
import { withI18n } from '../../../hocs';
import ComponentStyled from './styled';

const SelectApplication = ({ projectName, projectImage, i18n }) => {
  const history = useHistory();

  return (
    <ComponentStyled>
      <p className="title">{i18n.applications.applicationSelected}</p>
      <div className="info-wrapper">
        <div className="application-wrapper">
          <div className="application-logo-wrapper">
            <img className="application-logo" alt="Application" src={projectImage} />
          </div>
          <p>{projectName}</p>
        </div>
        <span className="change-application" onClick={() => history.push(Paths.APPLICATIONS)}>
          {i18n.applications.change}
        </span>
      </div>
    </ComponentStyled>
  );
};

SelectApplication.defaultProps = {
  projectImage: images.logoPush,
  projectName: '',
};

SelectApplication.propTypes = {
  projectName: PropTypes.string,
  projectImage: PropTypes.string,
  i18n: PropTypes.object,
};

export default withI18n(SelectApplication);
