/* eslint-disable import/no-cycle */
import { socket } from './index';
import { IO_EVENTS } from '../models';

export const authenticateSocket = (token) => {
  socket.emit(IO_EVENTS.EMIT.AUTHENTICATE, { token });
};

export const sendMessage = (message) => {
  socket.emit(IO_EVENTS.EMIT.SEND_MESSAGE, message);
};

export const sendAgentStatus = (status, agentId) => {
  socket.emit(IO_EVENTS.EMIT.AGENT_STATUS, { status, agentId });
};

export const closeConversation = (conversationSessionId, crmCase) => {
  socket.emit(IO_EVENTS.EMIT.CLOSE_CONVERSATION, { conversationSessionId, crmCase });
};

export const transferConversation = (conversationSessionId, agentId) => {
  socket.emit(IO_EVENTS.EMIT.TRANSFER_CONVERSATIONS, { conversationSessionId, agentId });
};

export const supervisorTransferConversation = (conversationSessionId, agentId) => {
  socket.emit(IO_EVENTS.EMIT.TRANSFER_CONVERSATIONS, { conversationSessionId, agentId });
};

export const supervisorSubscribeToConversation = (conversationSessionId) => {
  socket.emit(IO_EVENTS.EMIT.SUPERVISOR_SUBSCRIBE_CONVERSATION, { conversationSessionId });
};

export const supervisorUnsubscribeToConversation = (conversationSessionId) => {
  socket.emit(IO_EVENTS.EMIT.SUPERVISOR_UNSUBSCRIBE_CONVERSATION, { conversationSessionId });
};

export const auditorSubscribeToConversation = (conversationSessionId) => {
  socket.emit(IO_EVENTS.EMIT.AUDITOR_SUBSCRIBE_CONVERSATION, { conversationSessionId });
};

export const auditorUnsubscribeToConversation = (conversationSessionId) => {
  socket.emit(IO_EVENTS.EMIT.AUDITOR_UNSUBSCRIBE_CONVERSATION, { conversationSessionId });
};

export const assignConvSelf = (conversationSessionId) => {
  socket.emit(IO_EVENTS.EMIT.AUDITOR_ATTACH_CONVERSATION, { conversationSessionId });
};

export const getHistoricMessages = (conversationSessionId, lastId) => {
  socket.emit(IO_EVENTS.EMIT.REQUEST_HISTORICAL_MESSAGES, { conversationSessionId, lastId });
};

export const sendFormResponse = (formResponse) => {
  socket.emit(IO_EVENTS.EMIT.SEND_FORM_RESPONSE, formResponse);
};
