import React, { useState } from 'react';
import PropTypes from 'prop-types';

import StyledTextArea from './styled';

const TextArea = ({ value, placeholder, onChangeValue, required }) => {
  const [touched, setTouched] = useState(false);

  return (
    <StyledTextArea>
      <textarea
        required={required}
        placeholder={placeholder}
        value={value}
        onClick={() => setTouched(true)}
        onChange={(e) => onChangeValue(e.target.value)}
        className={`text-area-modal input-modal ${(touched && !value && required) && 'invalid-input'} `}
      />
    </StyledTextArea>
  );
};

TextArea.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChangeValue: PropTypes.func,
  required: PropTypes.bool,
};

export default TextArea;
