const IO_EVENTS = {
  ON: {
    MESSAGE_RECEIVED: 'message_received',
    MESSAGE_STATUS: 'message_status',
    SEND_MESSAGE_RESULT: 'send_message_result',
    STATS_AGENTS: 'stats_agents',
    CONNECT: 'connect',
    DISCONNECT: 'disconnect',
    AUTHENTICATED: 'authenticated',
    UNAUTHORIZED: 'unauthorized',
    AGENT_TRANSFER_STATUS: 'agent_transfer_status',
    SUPERVISOR_AGENT_STATUS: 'supervisor:agent_status',
    CONVERSATION_CLOSED: 'conversation_closed',
    AGENT_STATUS: 'agent:agent_status',
    AUDITOR_AGENT_STATUS: 'auditor:agent_status',
    FORM: 'form',
  },
  EMIT: {
    SEND_MESSAGE: 'send_message',
    AGENT_STATUS: 'agent_status',
    CLOSE_CONVERSATION: 'close_conversation',
    AUTHENTICATE: 'authenticate',
    TRANSFER_CONVERSATIONS: 'transfer_conversation',
    SUPERVISOR_SUBSCRIBE_CONVERSATION: 'supervisor:subscribe_conversation',
    SUPERVISOR_UNSUBSCRIBE_CONVERSATION: 'supervisor:unsubscribe_conversation',
    AUDITOR_SUBSCRIBE_CONVERSATION: 'auditor:subscribe_conversation',
    AUDITOR_UNSUBSCRIBE_CONVERSATION: 'auditor:unsubscribe_conversation',
    REQUEST_HISTORICAL_MESSAGES: 'request_messages',
    AUDITOR_ATTACH_CONVERSATION: 'attach_conversation',
    SEND_FORM_RESPONSE: 'form_response',
  },
};

Object.freeze(IO_EVENTS);

export default IO_EVENTS;
