export default {
  xs: 480,
  sm: 768,
  md: 900,
  lg: 1024,
  xl: 1300,
};

// const isExtraSmallScreen = useMediaQuery({ query: `(max-width: ${bp.xs}px)` });
// const isSmallScreen = useMediaQuery({ query: `(max-width: ${bp.sm}px)` });
// const isMediumScreen = useMediaQuery({ query: `(max-width: ${bp.md}px)` });
// const isLargeScreen = useMediaQuery({ query: `(max-width: ${bp.lg}px)` });
// const isExtraLargeScreen = useMediaQuery({ query: `(max-width: ${bp.xl}px)` });
