import React from 'react';
import { ToastContainer } from 'react-toastify';
import ToastStyled from './styled';

const Toast = () => (
  <ToastStyled>
    <ToastContainer
      closeButton={false}
      autoClose={5000}
      position="bottom-center"
      hideProgressBar
      pauseOnHover={false}
      pauseOnFocusLoss={false}
      draggable={false}
      className="className"
      toastClassName="toastClassName"
      bodyClassName="bodyClassName"
      progressClassName="progressClassName"
    />
  </ToastStyled>
);

export default Toast;
