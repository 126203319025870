import styled from 'styled-components';

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: ${(props) => props.marginTop}px;
  margin-bottom: ${(props) => props.marginBottom}px;

  .footer--button {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    border: none;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.primary.main};
    background-color: ${({ theme }) => theme.colors.white};

    &:hover {
      color: ${({ theme }) => theme.colors.secondary.main};
      .icon {
        fill: ${({ theme }) => theme.colors.secondary.main};
      }
    }

    &:active {
      color: ${({ theme }) => theme.colors.secondary.light};
      .icon {
        fill: ${({ theme }) => theme.colors.secondary.light};
      }
    }

    .icon {
      fill: ${({ theme }) => theme.colors.primary.main};
    }
  }
`;
