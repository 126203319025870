import styled from 'styled-components';

const ComponentStyled = styled.div`
  .login-title {
    text-align: center;
    margin-bottom: 30px;
    font-weight: bold;
    font-size: 23px;
  }

  .btn-wrapper {
    text-align: center;
    display: flex;
    justify-content: space-between
    margin-bottom: 50px;
  }
  .double-factor-summary {
    margin-bottom: 10px;
  }
  .back-button {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    border: none;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.primary.main};
    background-color: ${({ theme }) => theme.colors.white};

    &:hover {
      color: ${({ theme }) => theme.colors.secondary.main};
      .icon {
        fill: ${({ theme }) => theme.colors.secondary.main};
      }
    }

    &:active {
      color: ${({ theme }) => theme.colors.secondary.light};
      .icon {
        fill: ${({ theme }) => theme.colors.secondary.light};
      }
    }

    .icon {
      fill: ${({ theme }) => theme.colors.primary.main};
    }
  }
  .countdown-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 15px;
  }
  .error-box {
    padding: 16px;
    background: #b71c1c;
    margin-top: 8px;
    color: white;
    margin-bottom: 20px;
  }
  .hidden {
    display: none;
  }
`;

export default ComponentStyled;
