/* eslint-disable import/no-cycle */

import { IO_EVENTS } from '../models';
import SocketEventManager from './socket-event-manager';

const socketEventManager = SocketEventManager.shared();

export const socketEvents = (socket) => {
  socket.on(IO_EVENTS.ON.CONNECT, socketEventManager.onConnectReceived);

  socket.on(IO_EVENTS.ON.DISCONNECT, socketEventManager.onDisconnectReceived);

  socket.on(IO_EVENTS.ON.AUTHENTICATED, socketEventManager.onAuthenticatedReceived);

  socket.on(IO_EVENTS.ON.UNAUTHORIZED, socketEventManager.onUnauthorizedReceived);

  socket.on(IO_EVENTS.ON.STATS_AGENTS, socketEventManager.onStatsAgentsReceived);

  socket.on(IO_EVENTS.ON.MESSAGE_RECEIVED, socketEventManager.onMessageReceived);

  socket.on(IO_EVENTS.ON.SEND_MESSAGE_RESULT, socketEventManager.onSendMessageResultReceived);

  socket.on(IO_EVENTS.ON.MESSAGE_STATUS, socketEventManager.onMessageStatusReceived);

  socket.on(IO_EVENTS.ON.AGENT_TRANSFER_STATUS, socketEventManager.onAgentTransferStatusReceived);

  socket.on(IO_EVENTS.ON.SUPERVISOR_AGENT_STATUS, socketEventManager.onSupervisorAgentStatusReceived);

  socket.on(IO_EVENTS.ON.CONVERSATION_CLOSED, socketEventManager.onConversationClosed);

  socket.on(IO_EVENTS.ON.AGENT_STATUS, socketEventManager.onAgentStatusReceived);

  socket.on(IO_EVENTS.ON.FORM, socketEventManager.onForm);
};
