import _ from 'lodash';

class CacheService {
  cache = {};

  isCached = (key) => _.has(this.cache, key);

  add = (key, payload) => {
    this.cache[key] = payload;
  };

  getData = (key) => _.get(this.cache, key);

  toString = () => {
    // eslint-disable-next-line
    console.table(this.cache);
  };
}

const instance = new CacheService();

export default instance;
