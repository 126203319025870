import React from 'react';
import PropTypes from 'prop-types';
import ComponentStyled from './styled';

const MenuItem = ({ label, icon, onClick }) => (
  <ComponentStyled onClick={onClick}>
    {icon}
    <span className="menu-label">{label}</span>
  </ComponentStyled>
);

MenuItem.defaultProps = {
  onClick: () => {},
};

MenuItem.propTypes = {
  onClick: PropTypes.func,
  label: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
};

export default MenuItem;
