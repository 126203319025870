import React from 'react';
import PropTypes from 'prop-types';
import ComponentStyled from './styled';

const RegularInput = ({ variant, marginBottom, autoComplete, placeholder, type, name, onChange, onBlur, error, label, smallLabel, disabled, value }) => (
  <ComponentStyled disabled={disabled} variant={variant} error={error} marginBottom={marginBottom} smallLabel={smallLabel}>
    {label && <p className="label">{label}</p>}
    <div className="regular-input-wrapper">
      <input
        value={value}
        placeholder={placeholder}
        type={type}
        name={name}
        autoComplete={autoComplete}
        onBlur={onBlur}
        disabled={disabled}
        onChange={onChange}
      />
    </div>
    {error && <p className="input-error">{error}</p>}
  </ComponentStyled>
);

RegularInput.defaultProps = {
  type: 'text',
  error: '',
  autoComplete: 'off',
  marginBottom: 40,
  onChange: () => {},
  onBlur: () => {},
  placeholder: '',
  name: '',
  label: '',
  variant: '',
  smallLabel: false,
  disabled: false,
  value: '',
};

RegularInput.propTypes = {
  marginBottom: PropTypes.number,
  placeholder: PropTypes.string,
  variant: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  error: PropTypes.string,
  autoComplete: PropTypes.string,
  label: PropTypes.string,
  smallLabel: PropTypes.bool,
  disabled: PropTypes.bool,
  value: PropTypes.any,
};

export default RegularInput;
