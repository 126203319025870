import React from 'react';
import styled, { keyframes } from 'styled-components';

const spin = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

const SpinnerContainer = styled.div`
  display: inline-block;
  width: 15px;
  height: 15px;
  border: 2px solid #ccc;
  border-radius: 50%;
  margin-left: 5px;
  margin-right: 5px;
  border-top-color: #333;
  animation: ${spin} 0.6s linear infinite;
`;

const Spinner = () => {
  return <SpinnerContainer />;
};

export default Spinner;