import styled from 'styled-components';

export default styled.div`
  display: ${(props) => (props.show ? 'block' : 'none')};
  position: absolute;
  background-color: white;
  padding: 24px;
  margin-bottom: 16px;
  border-radius: 4px;
  -webkit-box-shadow: 2px 2px 15px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 2px 2px 15px 2px rgba(0, 0, 0, 0.3);
`;