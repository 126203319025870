import styled from 'styled-components';

const ChatStyled = styled.div`
  width: 100%;
  height: 100%;
  overflow: ${({ auditor }) => auditor ? 'hidden' : 'auto'};
  background-color: white;

  ul {
    list-style: none;
    ${({ auditor }) => auditor ? '' : 'padding-bottom: 30px;'}
    ${({ auditor }) => auditor ? 'overflow: auto;' : ''}
    height: ${({ auditor }) => auditor ? '45%' : 'auto'};
    li:last-child {
      border-bottom: 1px solid transparent;
    }
  }
  .chat-list-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .chat-list__settings-icon {
    fill: ${({ theme }) => theme.colors.grey[200]};
  }

  h1 {
    font-weight: 700;
    font-size: 24px;
    color: ${(props) => props.theme.colors.primary.main};
  }

  .empty-convs-holder {
    height: ${({ auditor }) => auditor ? '45%' : '80%'};
    display: flex;
    align-items: center;
    justify-content: center;

    .empty-convs-text {
      font-size: 20px;
      color: ${(props) => props.theme.colors.grey[200]};
      text-shadow: 0px 0px 2px #cecece;
    }
  }

  .chat-list__settings__menu {
    right: 0;
    z-index: 1;

    .select-app-wrapper {
      margin-top: 15px;
      border-top: 1px solid ${(props) => props.theme.colors.primary.main};
      padding-top: 15px;
    }

    .logout-item {
      display: flex;
      align-items: center;

      .logout-icon {
        margin-right: 10px;
      }
    }

    .option-item {
      padding-bottom: 15px;
      cursor: pointer;

      &:last-child {
        padding-bottom: 0;
      }
    }
  }
`;

export { ChatStyled };
