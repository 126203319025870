import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';

import {
  TagManagerWrapper,
  DropdownMenu,
  MenuItem,
  customStyles,
  StyledEditionInput,
  StyledAiOutlineDown,
  Container,
  HiddenCount,
} from './styles';
import Spinner from './Spinner';
import { TagOption } from './TagOption';

const TagManager = ({
  tags,
  setTags,
  setSelectedTags,
  selectedTags,
  onCreate,
  onDelete,
  onEditSave,
  createLabel,
  deleteLabel,
  editLabel,
  placeholderSelect,
  onAddSelectedTags,
  onDeleteSelectedTags,
  canEdit,
}) => {
  const deleteMenuRef = useRef(null);
  const editInputRef = useRef(null);
  const containerRef = useRef(null);
  const selectRef = useRef(null);
  const [actionsMenu, setActionsMenu] = useState({
    isOpen: false,
    top: 0,
    left: 0,
    tagData: null,
  });
  const [editText, setEditText] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [isLoadingOptions, setIsLoadingOptions] = useState(false);
  const [showAllTags, setShowAllTags] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);

  const handleMouseEnter = () => {
    clearTimeout(timeoutId);
    setShowAllTags(true);
  };

  const handleMouseLeave = () => {
    const id = setTimeout(() => {
      setShowAllTags(false);
    }, 200);
    setTimeoutId(id);
  };

  const handleChange = async (selectedOptions) => {
    setIsLoadingOptions(true);

    const currentSelectedTags = selectedTags || [];
    const currentSelectedOptions = selectedOptions || [];

    const addedTags = currentSelectedOptions
      .filter((option) => !currentSelectedTags.some((tag) => tag?.id === option?.id))
      .map((option) => option?.id);

    const removedTags = currentSelectedTags
      .filter((tag) => !currentSelectedOptions.some((option) => option?.id === tag?.id))
      .map((tag) => tag?.id);

    if (addedTags?.length > 0) {
      await onAddSelectedTags(addedTags);
    }

    if (removedTags?.length > 0) {
      await onDeleteSelectedTags(removedTags);
    }

    setSelectedTags(currentSelectedOptions);
    setActionsMenu({ ...actionsMenu, isOpen: false });
    setIsLoadingOptions(false);
  };

  const handleCreate = async (inputValue) => {
    setIsLoadingOptions(true);
    const createdTag = await onCreate(inputValue);
    if (createdTag) {
      handleChange([...selectedTags, createdTag]);
    }
    setActionsMenu({ ...actionsMenu, isOpen: false });
    setIsLoadingOptions(false);
  };

  const handleDelete = async () => {
    setIsLoadingOptions(true);
    if (actionsMenu?.tagData?.id) {
      setActionsMenu({ ...actionsMenu, isOpen: false });
      const respDelete = await onDelete(actionsMenu.tagData.id);
      if (respDelete) {
        const updatedTags = tags.filter((tag) => tag.id !== actionsMenu?.tagData?.id);
        setTags(updatedTags);
        setActionsMenu({ ...actionsMenu, isOpen: false, tagData: null });
      }
    }
    setIsLoadingOptions(false);
  };

  const handleEditStart = (tagData) => {
    setEditText(tagData.name);
    setIsEditing(true);
    setActionsMenu({ ...actionsMenu, isOpen: false });
  };

  const handleEditInputChange = (e) => {
    setEditText(e.target.value);
  };

  const handleEditInputKeyPress = async (e) => {
    if (e.key === 'Enter') {
      setIsLoadingOptions(true);
      const updatedTag = { ...actionsMenu.tagData, name: editText };
      const respSave = await onEditSave(updatedTag);
      if (respSave) {
        const updatedTags = tags.map((tag) =>
          tag.id === actionsMenu.tagData.id ? updatedTag : tag
        );
        setTags(updatedTags);
        const updatedSelectedTags = selectedTags.map((tag) =>
          tag.id === actionsMenu.tagData.id ? updatedTag : tag
        );
        setSelectedTags(updatedSelectedTags);
        setIsEditing(false);
        setEditText('');
      }
      setIsLoadingOptions(false);
    }
  };

  const formatCreateLabel = (inputValue) => (
    <div>
      {createLabel} &quot;{inputValue}&quot;
    </div>
  );

  const optionComponents = {
    // eslint-disable-next-line react/no-unstable-nested-components
    Option: (props) => (
      <TagOption
        {...props}
        canEdit={canEdit}
        setActionsMenu={setActionsMenu}
        // eslint-disable-next-line no-underscore-dangle
        isCreatingOption={props?.data?.__isNew__}
      />
    ),
  };

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (
        (!deleteMenuRef?.current?.contains(e.target)) &&
        (!editInputRef?.current?.contains(e.target)) &&
        (!selectRef?.current?.contains(e.target))
      ) {
        setActionsMenu({ ...actionsMenu, isOpen: false });
        setIsEditing(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [actionsMenu]);

  const SelectComponent = canEdit ? CreatableSelect : Select;

  const hiddenCount = selectedTags?.length > 3 ? selectedTags.length - 3 : 0;

  return (
    <Container
      ref={containerRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <TagManagerWrapper ref={selectRef}>
        <SelectComponent
          isMulti
          value={showAllTags ? selectedTags : (selectedTags?.slice(0, 3) || selectedTags)}
          options={tags}
          onChange={handleChange}
          onCreateOption={canEdit ? handleCreate : undefined}
          formatCreateLabel={canEdit ? formatCreateLabel : undefined}
          styles={customStyles}
          placeholder={placeholderSelect}
          isClearable
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
          getNewOptionData={(inputValue, optionLabel) => ({
            id: inputValue,
            name: optionLabel,
            __isNew__: true,
          })}
          components={{
            ...optionComponents,
            // eslint-disable-next-line react/no-unstable-nested-components
            DropdownIndicator: () => (isLoadingOptions ? <Spinner /> : <StyledAiOutlineDown />),
          }}
          menuPlacement="auto"
          closeMenuOnSelect={false}
        />
        
        {isEditing && (
          <StyledEditionInput
            type="text"
            value={editText}
            onChange={handleEditInputChange}
            onBlur={onEditSave}
            onKeyPress={handleEditInputKeyPress}
            autoFocus
            ref={editInputRef}
          />
        )}
        <DropdownMenu ref={deleteMenuRef} isOpen={actionsMenu.isOpen} top={actionsMenu.top} left={actionsMenu.left}>
          <MenuItem onClick={handleDelete}>{deleteLabel}</MenuItem>
          <MenuItem onClick={() => handleEditStart(actionsMenu.tagData)}>{editLabel}</MenuItem>
        </DropdownMenu>
      </TagManagerWrapper>
      {hiddenCount > 0 && (
        <HiddenCount hiddenCount={hiddenCount} showAllTags={showAllTags}>+{hiddenCount}</HiddenCount>
      )}
    </Container>
  );
};

TagManager.propTypes = {
  tags: PropTypes.array,
  setTags: PropTypes.func,
  setSelectedTags: PropTypes.func,
  selectedTags: PropTypes.array,
  onCreate: PropTypes.func,
  onDelete: PropTypes.func,
  onEditSave: PropTypes.func,
  createLabel: PropTypes.string,
  deleteLabel: PropTypes.string,
  editLabel: PropTypes.string,
  placeholderSelect: PropTypes.string,
  onAddSelectedTags: PropTypes.func,
  onDeleteSelectedTags: PropTypes.func,
  canEdit: PropTypes.bool,
  // eslint-disable-next-line react/no-unused-prop-types
  data: PropTypes.array,
  
};

export default TagManager;
