import styled from 'styled-components';

export default styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;

  .title {
    font-weight: 700;
    font-size: 24px;
    color: ${(props) => props.theme.colors.primary.main};
    margin-bottom: 30px;
  }

  .stat {
    font-size: 16px;
    font-weight: 300;
    padding-left: 30px;
    span {
      color: ${(props) => props.theme.colors.primary.main};
      font-weight: 400;
    }
  }

  @media (max-width: ${({ theme }) => theme.bp.lg}px) {
    .stat {
      font-size: 15px;
    }
  }

  @media (max-width: ${({ theme }) => theme.bp.md}px) {
    flex-flow: column;
    align-items: flex-start;

    .stat {
      margin-bottom: 25px;
      padding-left: 0;
      font-size: 18px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;
