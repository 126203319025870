import React from 'react';
import PropTypes from 'prop-types';
import ButtonStyled from './styled';

const SubmitButton = ({ onClick, label, disabled }) => (
  <ButtonStyled disabled={disabled} onClick={onClick} className="ts ts-normal" type="button">
    {label}
  </ButtonStyled>
);

SubmitButton.propTypes = {
  onClick: PropTypes.func,
  label: PropTypes.string,
  disabled: PropTypes.bool,
};

export default SubmitButton;
