import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from '../../atoms';
import ComponentStyled from './styled';

const RegularButton = ({ label, onClick, type, isFetching, disable }) => (
  <ComponentStyled disable={isFetching || disable} type={type} onClick={isFetching || disable ? () => {} : onClick}>
    {label && <span>{label}</span>}
    {isFetching && (
      <div className="loading-wrapper">
        <Spinner size={20} />
      </div>
    )}
  </ComponentStyled>
);

RegularButton.defaultProps = {
  onClick: () => {},
  isFetching: false,
  disable: false,
  type: 'button',
};

RegularButton.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  isFetching: PropTypes.bool,
  disable: PropTypes.bool,
  type: PropTypes.oneOf(['submit', 'button', 'reset']),
};

export default RegularButton;
