import styled from 'styled-components';

export default styled.div`
  padding: 24px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;

  .stats-panel--card-body {
    width: 362px;
    max-width: 100%;
    background-color: white;
    padding: 24px;
    margin: 0 10px 25px 10px;
    border-radius: 4px;
    -webkit-box-shadow: 2px 2px 15px 2px rgba(0, 0, 0, 0.3);
    box-shadow: 2px 2px 15px 2px rgba(0, 0, 0, 0.3);

    .stats-panel--card-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      border-bottom: 2px solid gray;
      padding-bottom: 6px;

      .card-header--icon {
        width: 30px;
        height: 30px;
        fill: gray;
      }
      .card-header--title {
        font-size: 18px;
        color: gray;
        margin-left: 8px;
      }
    }
    .stats-panel--card-text-holder {
      padding-top: 10px;
      .stats-panel--stats-text {
        margin: 8px 0;
      }
      .card-text-holder--channel-info {
        display: flex;
        align-items: center;
        img {
          width: 24px;
          height: 24px;
          margin-right: 8px;
        }

        .icon {
          width: 24px;
          height: 24px;
          margin-right: 8px;
          color: #cccccc;
        }
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.bp.xs}px) {
    .stats-panel--card-body {
      padding: 15px;
    }
  }
`;
