import React from 'react';
import AgentsPanelStyled from './styled';
import { useSocketContext } from '../../../sockets/context';
import { AgentDataCard } from '../../molecules';
import { sortAgents } from './utils';

const AgentsPanel = () => {
  const { supervisorAgentStatus } = useSocketContext();
  const orderedAgents = sortAgents([...supervisorAgentStatus]);

  return (
    <AgentsPanelStyled>
      {orderedAgents.map(agentData => (
        <AgentDataCard key={agentData.agentId} data={agentData} />
      ))}
    </AgentsPanelStyled>
  );
};

export default AgentsPanel;
