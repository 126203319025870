import styled from 'styled-components';

const ModalAttachStyled = styled.div`
  display: ${(props) => (props.show ? 'block' : 'none')};
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  padding: 100px 15px 15px 15px;

  .modal-content {
    background-color: ${(props) => props.theme.colors.white};
    margin: auto;
    border-radius: 8px;
    width: 600px;
    max-width: 100%;
    display: flex;
    flex-flow: column;

    .modal-title {
      color: ${({ theme }) => theme.colors.primary.main};
      padding: 24px 24px 32px 24px;
      font-size: 21px;
      border-bottom-width: 1px;
    }

    .modal-icon {
      color: #ed5656;
      width: 40px;
      height: 40px;
      margin-bottom: 12px;
    }

    hr {
      width: 100%;
      border: 0.5px solid #cecece;
      margin-bottom: 10px;
      margin-top: 16px;
    }

    .modal-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0 24px 20px;
      flex: 2;

      .input-file {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
      }
      .input-file-label {
        display: flex;
        flex-direction: row;
        align-items: center;
        border: solid;
        border-width: 2px;
        border-radius: 4px;
        padding: 0 16px;
        min-width: 100px;
        height: 32px;
        font-size: 14px;
        font-weight: 500;
        background-color: #fdfdfd;
        text-transform: uppercase;
        color: ${(props) => props.theme.colors.grey[100]};
        box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);

        span {
          margin-left: 16px;
        }

        &:hover {
          color: ${(props) => props.theme.colors.secondary.main};
          border-color: ${(props) => props.theme.colors.secondary.main};
          cursor: pointer;
          .upload-icon {
            fill: ${(props) => props.theme.colors.secondary.main};
          }
        }
      }

      .file-error {
        padding-top: 12px;
        .file-error-text {
          font-style: italic;
          font-weight: 400;
          font-size: 14px;
          color: ${(props) => props.theme.colors.red};
        }
      }
      .file-name {
        padding-top: 12px;
        .file-name-text {
          font-style: italic;
          font-weight: 400;
          font-size: 14px;
        }
      }

      .upload-file-button {
        display: flex;
        flex-direction: row;
        align-items: center;
        border: solid;
        border-width: 2px;
        border-radius: 4px;
        margin-left: 5px;
        padding: 0 16px;
        min-width: 100px;
        height: 32px;
        font-size: 14px;
        font-weight: 500;
        background-color: #fdfdfd;
        text-transform: uppercase;
        color: ${(props) => props.theme.colors.grey[100]};
        box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);

        &:hover {
          color: ${(props) => props.theme.colors.secondary.main};
          border-color: ${(props) => props.theme.colors.secondary.main};
          cursor: pointer;
          .upload-icon {
            fill: ${(props) => props.theme.colors.secondary.main};
          }
        }

        &:disabled {
          color: ${(props) => props.theme.colors.grey[200]};
          border-color: ${(props) => props.theme.colors.grey[200]};
          cursor: not-allowed;
          .upload-icon {
            fill: ${(props) => props.theme.colors.grey[200]};
          }
        }

        &:active {
          background-color: ${(props) => props.theme.colors.grey[300]};
          color: ${(props) => props.theme.colors.primary.main};
        }

        p {
          margin-right: 16px;
        }
      }
    }

    .modal-text-holder {
      padding-top: 42px;
      .modal-text {
        font-size: 14px;
        color: ${(props) => props.theme.colors.grey[200]};
        text-align: center;
        margin-bottom: 8px;
      }
    }

    .modal-buttons-row {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      padding-top: 32px;
      align-items: flex-end;
      flex: 2;

      .modal-button-cancel {
        border: solid;
        border-width: 2px;
        border-radius: 4px;
        padding: 0 16px;
        margin-left: 5px;
        min-width: 100px;
        font-size: 14px;
        font-weight: 500;
        height: 32px;
        background-color: #fdfdfd;
        text-transform: uppercase;
        color: ${(props) => props.theme.colors.grey[100]};
        box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);

        &:hover {
          color: ${(props) => props.theme.colors.red};
          border-color: ${(props) => props.theme.colors.red};
          cursor: pointer;
        }

        &:active {
          background-color: ${(props) => props.theme.colors.grey[300]};
          color: ${(props) => props.theme.colors.primary.main};
        }
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.bp.md}px) {
    padding: 15px;
    .modal-content {
      width: 100%;
      min-height: 100%;
    }
  }
`;

export { ModalAttachStyled };
