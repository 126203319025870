import React, { useState, useEffect } from 'react';
import { ClipLoader } from 'react-spinners';
import PropTypes from 'prop-types';
import { TemplateService } from '../../../services';
import { ModalPreviewTemplateStyled } from './styled';
import { withI18n } from '../../../hocs';
import { useSocketContext } from '../../../sockets/context';
import { paintHeader, paintBody, paintFooter, paintButtons } from './utils';
import { InteractiveExtraContent } from '../template-message-bubble/InteractiveExtraContent';

const CancelButton = ({ onClick, label }) => (
  <button onClick={onClick} className="modal-button-cancel ts ts-normal" type="button">
    {label}
  </button>
);

CancelButton.propTypes = {
  onClick: PropTypes.func,
  label: PropTypes.string,
};

const SendButton = ({ onClick, label, disabled }) => (
  <button onClick={onClick} disabled={disabled} className="modal-button-cancel ts ts-normal" type="button">
    {label}
  </button>
);
SendButton.propTypes = {
  onClick: PropTypes.func,
  label: PropTypes.string,
  disabled: PropTypes.bool,
};

const ModalPreviewTemplate = ({ show, i18n, onClose, templateToPreview, sendTemplate }) => {
  const { title, close, send } = i18n.previewTemplateModal;
  const [templateDataToDisplay, setTemplateDataToDisplay] = useState(null);
  const { auth } = useSocketContext();

  const handleSendTemplate = () => {
    if (templateToPreview?.template) {
      sendTemplate(templateToPreview.template);
    }
    onClose();
  }
  const getInfoTemplate = async (appId, tempName, tempLang) => {
    const templateToSet = await TemplateService.getTemplateByName(appId, tempName, tempLang);
    setTemplateDataToDisplay(templateToSet);
  };

  useEffect(() => {
    if (templateToPreview) {
      const templateName = templateToPreview?.template?.content?.template?.name;
      const templateLang = templateToPreview?.template?.content?.template?.language?.code;
      getInfoTemplate(auth?.applicationId, templateName, templateLang);
    }
  }, [templateToPreview, auth]);

  const paintTemplate = (_content) => {
    const headerPart = paintHeader(_content, () => {});
    const bodyPart = paintBody(_content);
    const footerPart = paintFooter(_content);
    const buttonsJsx = paintButtons(_content);
    return (
        <div className="container-template">
          <div>
            {headerPart}
            {bodyPart}
            {footerPart}

          </div>
          {buttonsJsx &&
            <InteractiveExtraContent
              buttonsContent={buttonsJsx}
              disableClick
              /* buttonsVariables={variablesButtons} */
            />
          }
        </div>
    );
  };

  const handleClose = () => {
    setTemplateDataToDisplay(null);
    onClose();
  };

  return (
    <ModalPreviewTemplateStyled show={show}>
      <div className="modal-content">
        <h2 className="modal-title">{title}</h2>
        <div className="modal-body">
          {templateDataToDisplay ? paintTemplate(templateDataToDisplay) : <ClipLoader size={40} loading />}
          <hr />
          <div className="modal-buttons-row">
            <CancelButton onClick={handleClose} label={close} />
            <SendButton disabled={!templateDataToDisplay} label={send} onClick={handleSendTemplate} />
          </div>
        </div>
      </div>
    </ModalPreviewTemplateStyled>
  );
};

ModalPreviewTemplate.propTypes = {
  show: PropTypes.bool,
  i18n: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  templateToPreview: PropTypes.object,
  sendTemplate: PropTypes.func,
};

export default withI18n(ModalPreviewTemplate);
