import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { ClipLoader } from 'react-spinners';
import { ErrorIcon } from '../../../atoms';
import { UserIcon, PhoneIcon, EmailIcon, LocationIcon, BaggageIcon, KeyboardDownArrowIcon} from '../../../atoms/svg-icons';
import i18n from '../../../../assets/i18n';
 
// data for testing
// const contactData = {
//   addresses: [
//     {
//       CustomElementRegistry: "Menlo Park",
//       country: "United States",
//       country_code: "us",
//       state: "CA",
//       street: "1 Hacker Way",
//       type: "HOME",
//       zip: "94025"
//     },
//     {
//       CustomElementRegistry: "Menlo Park",
//       country: "United States",
//       country_code: "us",
//       state: "CA",
//       street: "200 Jefferson Dr",
//       type: "WORK",
//       zip: "94025"
//     }
//   ],
//   birthday: "2012-08-18",
//   emails: [
//     {
//       email: "test@fb.com",
//       type: "WORK"
//     },
//     {
//       email: "test@whatsapp.com",
//       type: "WORK"
//     }
//   ],
//   name: {
//     first_name: "John",
//     formatted_name: "John Smith",
//     last_name: "Smith"
//   },
//   org: {
//     company: "WhatsApp",
//     department: "Design",
//     title: "Manager"
//   },
//   phones: [
//     {
//       phone: "+1 (940) 555-1234",
//       type: "HOME"
//     },
//     {
//       phone: "+1 (650) 555-1234",
//       type: "WORK",
//       wa_id: "16505551234"
//     }
//   ],
//   urls: [
//     {
//       url: "https://www.facebook.com",
//       type: "WORK"
//     }
//   ]
// } 

export const MessageContactsHolder = ({ isFetching, isDownloadError, data }) => {
  const [openExtraContent, setOpenExtraContent] = useState(false);
  const { addresses, emails, name, org, phones, urls, birthday } = data;
  const handleClick = () => {
    setOpenExtraContent(state => !state)
  }
  return (
    <div className="contact-holder">
      {isFetching && <ClipLoader size={40} loading={isFetching} />}
      {isDownloadError ? (
        <>
          <ErrorIcon className="error-icon" />
          <p className="bubble-info-text">{i18n.errors.contactNotAvailable}</p>
        </>
      ) : (
        data && 
          <>
          <div onClick={handleClick} className={`contact-wrapper border ${openExtraContent ? 'open' : ''}`}>
            <div className="contact-header-wrapper">
              <p className="contact-icon"><UserIcon /></p>
              <div className="contact-item-data">
                {name && <p className={birthday && "contact-name-birthday"}>{name.formatted_name}</p>}  
                {birthday && <p className="contact-birthday">{birthday}</p>}
              </div>  
            </div>
            <p className="contact-arrow"><KeyboardDownArrowIcon /></p>
          </div>
          <div className={`contact-content ${openExtraContent ? 'open' : ''}`}>
            {addresses && <>
              {addresses.map((address, index)=> {
                return (
                  <div className="contact-item-wrapper" key={index}>
                    <p className="contact-icon"><LocationIcon /></p>
                    <div className="contact-item-data">
                      <span className="contact-item-each">{address.street}</span>
                      <span className="contact-item-each">{address.state}</span>
                      <span className="contact-item-each">{address.city}</span>
                      <span className="contact-item-each">{address.zip}</span>
                      <span className="contact-item-each">{address.country}</span>
                      <p className="contact-item-type">{address.type}</p>
                    </div>
                  </div>
                )
              })}
            </>} 
            {emails && <>
              {emails.map((email, index)=> {
                return (
                  <div className="contact-item-wrapper" key={index}>
                    <p className="contact-icon"><EmailIcon /></p>
                    <div className="contact-item-data">
                      <span className="contact-item-each">{email.email}</span>
                      <p className="contact-item-type">{email.type}</p>
                    </div>
                  </div>
                )
              })}
            </>}
            {org && <>
              <div className="contact-item-wrapper">
                <p className="contact-icon"><BaggageIcon /></p>
                <div className="contact-item-data">
                  <p className="contact-org-title">{org.title}</p>
                  <p className="contact-item-type"><span>{org.company}</span> - <span>{org.department}</span></p>
                </div>
              </div>
            </>} 
            {phones && <>
              {phones.map((phone, index)=> {
                return (
                  <div className="contact-item-wrapper" key={index}>
                    <p className="contact-icon"><PhoneIcon /></p>
                    <div className="contact-item-data">
                      <span className="contact-item-each">{phone.phone}</span>
                      <p className="contact-item-type">{phone.type}</p>
                    </div>
                  </div>
                )
              })}
            </>} 
            {urls && <>
              {urls.map((url, index)=> {
                return (
                  <div className="contact-item-wrapper" key={index}>
                    <p className="contact-icon"><LocationIcon /></p>
                    <div className="contact-item-data">
                      <span className="contact-item-each">{url.url}</span>
                      <p className="contact-item-type">{url.type}</p>
                    </div>
                  </div>
                )
              })}
            </>} 
          </div>
          </>
      )}
    </div>
  )
};

MessageContactsHolder.propTypes = {
  isFetching: PropTypes.bool,
  isDownloadError: PropTypes.bool,
  data: PropTypes.object,
};
