import styled from 'styled-components';

export default styled.div`
  width: 100%;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  min-height: -moz-fill-available;
  min-height: fill-available;

  .chat-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    position: relative;
    z-index: 100;
    box-shadow: 0px -1px 15px -10px ${(props) => props.theme.colors.black};

    .sidebar {
      width: 30%;
      height: calc(100vh - 46px);
      border-right: 1px solid ${(props) => props.theme.colors.grey[300]};
      padding-bottom: 60px;
      position: relative;
      background-color: ${(props) => props.theme.colors.white};
      z-index: 15;
      padding: 12px 12px 60px 12px;

      .menu-wrapper {
        padding-top: 10px;

        .select-application-wrapper {
          background-color: ${(props) => props.theme.colors.white};
          padding: 15px;
          border-top: 1px solid ${(props) => props.theme.colors.grey[300]};
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
        }
      }
    }

    .messages-list {
      height: calc(100vh - 46px);
      background-color: ${(props) => props.theme.colors.chatBackground};
      overflow: hidden;
      width: 70%;
    }
  }

  .status-bar {
    width: 100%;
  }

  @media (max-width: ${({ theme }) => theme.bp.xl}px) {
    .chat-container {
      .sidebar {
        width: 35%;
      }

      .messages-list {
        width: 65%;
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.bp.lg}px) {
    .chat-container {
      .sidebar {
        width: 42%;
      }

      .messages-list {
        width: 58%;
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.bp.md}px) {
    .chat-container {
      .sidebar {
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        padding-top: 15px;
        border: 0;
        overflow: hidden;

        &.open {
          left: 0;
        }
      }

      .messages-list {
        width: 100%;
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.bp.md}px) {
    display: flex;
    flex-flow: column;
    .chat-container {
      flex: 1;
      display: flex;
      flex-flow: column;

      .messages-list {
        flex: 1;
        max-height: calc(100vh - 46px);
      }
    }
  }
`;
