import React from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import { withI18n } from '../../../hocs';
import images from '../../../assets/images';
import ComponentStyled from './styled';
import Paths from '../../../config/routes';
import { AuthService } from '../../../services';

const ApplicationsList = ({ i18n, applications }) => {
  const accessToken = AuthService.getAdminToken();

  const handleClickApplication = (id) => {
    AuthService.saveApplicationId(id);
    window.location.replace(`${Paths.AUTH}?token=${accessToken}&applicationId=${id}`);
  };

  const formatApplications = applications.map((application) => {
    const id = _get(application, 'id');
    const logoFile = _get(application, 'logoFile');
    const name = _get(application, 'name');

    return (
      <div key={id} className="application-wrapper" onClick={() => handleClickApplication(id)}>
        <div className="application-logo-wrapper">
          <img className="application-logo" alt="Application" src={logoFile || images.logoPush} />
        </div>
        <p>{name}</p>
      </div>
    );
  });

  return (
    <ComponentStyled>
      <p className="title">{i18n.applications.title}</p>
      {formatApplications.length > 0 ? formatApplications : <p>{i18n.applications.noApplications}</p>}
    </ComponentStyled>
  );
};

ApplicationsList.defaultProps = {
  applications: [],
};

ApplicationsList.propTypes = {
  i18n: PropTypes.object,
  applications: PropTypes.array,
};

export default withI18n(ApplicationsList);
