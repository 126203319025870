import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import { Link } from 'react-router-dom';
import AgentDataCardStyled from './styled';
import { UserIcon, ConversationsSupStatsIcon, AccountCancelIcon, AccountCheckIcon, IconButton } from '../../atoms';
import { AGENT_STATUS } from '../../../models';
import i18n from '../../../assets/i18n';
import Paths from '../../../config/routes';
import { sendAgentStatus } from '../../../sockets/emit';

const AgentAvailability = ({ status }) => {
  if (status === AGENT_STATUS.OFFLINE) {
    return <p className="agent-data-card--header-text-holder--text off">{i18n.supervisor.offline}</p>;
  }

  if (status === AGENT_STATUS.AVAILABLE) {
    return (
      <p className="agent-data-card--header-text-holder--text on">
        {i18n.supervisor.online} <span style={{ color: 'black' }}>|</span> <span className="on">{i18n.chats.available}</span>
      </p>
    );
  }

  return (
    <p className="agent-data-card--header-text-holder--text on">
      {i18n.supervisor.online} <span style={{ color: 'black' }}>|</span> <span className="off">{i18n.chats.unavailable}</span>
    </p>
  );
};

AgentAvailability.propTypes = {
  status: PropTypes.string,
};

const AgentCardHeader = ({ name, status, userImage }) => (
  <div className="agent-data-card--header">
    {userImage ? <img src={userImage} alt="profile pic" /> : <UserIcon fill="#009EFF6A" />}
    <div className="agent-data-card--header-text-holder">
      <p>{name}</p>
      <AgentAvailability status={status} />
    </div>
  </div>
);

AgentCardHeader.propTypes = {
  name: PropTypes.string,
  status: PropTypes.string,
  userImage: PropTypes.string,
};

const AgentCardBody = ({ activeConversations, lastConnectionString, lastMessageReceived, lastMessageSent }) => (
  <div className="agent-data-card--body">
    <p className="agent-data-card--body--text">
      {activeConversations} {activeConversations === 1 ? i18n.supervisor.activeConversations.singular : i18n.supervisor.activeConversations.plural}
    </p>
    {lastMessageReceived && (
      <p className="agent-data-card--body--text">
        {i18n.supervisor.lastMessageReceived} {lastMessageReceived}
      </p>
    )}
    {lastMessageSent && (
      <p className="agent-data-card--body--text">
        {i18n.supervisor.lastMessageSent} {lastMessageSent}
      </p>
    )}
    <p className="agent-data-card--body--text">
      {i18n.supervisor.lastTimeConnected} {lastConnectionString}
    </p>
  </div>
);

AgentCardBody.propTypes = {
  activeConversations: PropTypes.number,
  lastConnectionString: PropTypes.string,
  lastMessageReceived: PropTypes.string,
  lastMessageSent: PropTypes.string,
};

const ButtonSeeConversations = ({ agentId }) => (
  <Link to={`${Paths.SUPERVISOR_AGENTS}/${agentId}`}>
    <IconButton toolTip={i18n.supervisor.seeConversations}>
      <ConversationsSupStatsIcon className="button-icon ts ts-normal" />
    </IconButton>
  </Link>
);

ButtonSeeConversations.propTypes = {
  agentId: PropTypes.number,
};

const ButtonChangeAvailability = ({ status, agentId }) => {
  const isAvailable = status === AGENT_STATUS.AVAILABLE;
  const statusToEmit = isAvailable ? AGENT_STATUS.HALTING : AGENT_STATUS.AVAILABLE;
  const iconClassName = 'button-icon ts ts-normal';
  return (
    <IconButton
      toolTip={isAvailable ? i18n.supervisor.changeToUnavaible : i18n.supervisor.changeToAvailable}
      onClick={() => {
        sendAgentStatus(statusToEmit, agentId);
      }}
    >
      {isAvailable ? <AccountCancelIcon className={iconClassName} /> : <AccountCheckIcon className={iconClassName} />}
    </IconButton>
  );
};

ButtonChangeAvailability.propTypes = {
  status: PropTypes.string,
  agentId: PropTypes.number,
};

const AgentCardFooter = ({ agentId, showSeeConvsButton, status }) => {
  return (
    <>
      {showSeeConvsButton && <ButtonSeeConversations agentId={agentId} />}
      <ButtonChangeAvailability status={status} agentId={agentId} />
    </>
  );
};

AgentCardFooter.propTypes = {
  agentId: PropTypes.number,
  showSeeConvsButton: PropTypes.bool,
  status: PropTypes.string,
};

const AgentDataCard = ({ data }) => {
  const { agentId, status, lastConnection, conversations, name, userImage } = data;

  const [timeAgoLastMessageReceived, setTimeAgoLastMessageReceived] = useState(null);
  const [timeAgoLastMessageSent, setTimeAgoLastMessageSet] = useState(null);
  const [lastMessageRecievedISOString, setLastMessageRecievedISOString] = useState(null);
  const [lastMessageSentISOString, setLastMessageSentISOString] = useState(null);

  let lastMessageReceivedMillis = 0;
  let lastMessageSentMillis = 0;
  let lastMessageReceivedString;
  let lastMessageSentString;

  conversations?.forEach((conv) => {
    if (conv.lastMessageReceived) {
      const timeInMillisReceived = new Date(conv.lastMessageReceived).getTime();
      if (timeInMillisReceived > lastMessageReceivedMillis) {
        lastMessageReceivedMillis = timeInMillisReceived;
        lastMessageReceivedString = conv.lastMessageReceived;
      }
    }

    if (conv.lastMessageSent) {
      const timeInMillisSent = new Date(conv.lastMessageSent).getTime();
      if (timeInMillisSent > lastMessageSentMillis) {
        lastMessageSentMillis = timeInMillisSent;
        lastMessageSentString = conv.lastMessageSent;
      }
    }
  });

  const updateTimeInterval = 60 * 1000;

  useEffect(() => {
    setTimeAgoLastMessageReceived(lastMessageReceivedString ? moment(lastMessageReceivedString).fromNow() : null);
    setTimeAgoLastMessageSet(lastMessageSentString ? moment(lastMessageSentString).fromNow() : null);
    setLastMessageRecievedISOString(lastMessageReceivedString);
    setLastMessageSentISOString(lastMessageSentString);

    const interval = setInterval(() => {
      setTimeAgoLastMessageReceived(lastMessageRecievedISOString ? moment(lastMessageRecievedISOString).fromNow() : null);
      setTimeAgoLastMessageSet(lastMessageSentISOString ? moment(lastMessageSentISOString).fromNow() : null);
    }, updateTimeInterval);

    return () => clearInterval(interval);
  }, [lastMessageReceivedString, lastMessageSentString, lastMessageRecievedISOString, lastMessageSentISOString, updateTimeInterval]);

  const activeConversations = conversations?.length;
  const lastConnectionString = lastConnection ? moment(lastConnection).format('DD/MM/YYYY') : i18n.supervisor.notAvailableAKA;
  const showSeeConvsButton = activeConversations > 0;

  return (
    <>
      <AgentDataCardStyled>
        <AgentCardHeader name={name} status={status} userImage={userImage} />
        <AgentCardBody
          activeConversations={activeConversations}
          lastConnectionString={lastConnectionString}
          lastMessageReceived={timeAgoLastMessageReceived}
          lastMessageSent={timeAgoLastMessageSent}
        />
        <div className="agent-data-card--footer">
          {status !== AGENT_STATUS.OFFLINE && <AgentCardFooter agentId={agentId} showSeeConvsButton={showSeeConvsButton} status={status} />}
        </div>
      </AgentDataCardStyled>
      <ReactTooltip type="info" />
    </>
  );
};

AgentDataCard.propTypes = {
  data: PropTypes.object.isRequired,
};

export default AgentDataCard;
