import styled from 'styled-components';

export default styled.div`
  padding: 24px;
  height: 100%;
  display: flex;
  flex-direction: column;

  .agent-card-detail--title {
    color: ${({ theme }) => theme.colors.primary.main};
    margin-bottom: 16px;
  }

  .agent-detail-panel--list-container {
    width: 850px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    align-self: center;
    overflow-y: auto;
    padding: 16px 64px;
    list-style: none;
  }

  @media (max-width: ${({ theme }) => theme.bp.md}px) {
    padding: 15px 0;

    .agent-detail-panel--list-container {
      padding: 15px 0;
    }
  }
`;
