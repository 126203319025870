const MSG_CONTENT_TYPES = {
  TEXT: 'text',
  IMAGE: 'image',
  VIDEO: 'video',
  VOICE: 'voice',
  AUDIO: 'audio',
  STICKER: 'sticker',
  DOCUMENT: 'document',
  LOCATION: 'location',
  CONTACTS: 'contacts',
  INTERACTIVE: 'interactive',
  TEMPLATE: 'template',
  UNKNOWN: 'unknown',
  QUICK_REPLY: 'quickReplyEvent',
  EVENT: 'event',
};

Object.freeze(MSG_CONTENT_TYPES);

export default MSG_CONTENT_TYPES;
