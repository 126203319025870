import styled from 'styled-components';

const ComponentStyled = styled.div`
  .title {
    margin-bottom: 10px;
  }

  .info-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .application-wrapper {
      display: flex;
      align-items: center;
      padding-right: 15px;

      .application-logo-wrapper {
        width: 30px;
        height: 30px;
        object-fit: cover;
        margin-right: 15px;

        .application-logo {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .change-application {
    cursor: pointer;
    border-bottom: 1px solid ${(props) => props.theme.colors.primary.main};
    color: ${(props) => props.theme.colors.primary.main};
  }
`;

export default ComponentStyled;
