/* eslint-disable react/jsx-no-useless-fragment */
import React, { useState, useRef, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { ModalPreviewStyled } from './styled';
import { withI18n } from '../../../hocs';
import { MODAL_TYPE } from '../../../models';

import { RotateLeftIcon, RotateRightIcon, CloseIcon } from '../../atoms/svg-icons';

function ModalPreview({ show, i18n, onModalCancel, action, source }) {
  const isImageAction = action === MODAL_TYPE.PREVIEW.IMAGE;
  const [imagePosition, setImagePosition] = useState(0);
  const isVideoAction = action === MODAL_TYPE.PREVIEW.VIDEO;
  const [modalStyle, setModalStyle] = useState({});
  const [imgStyle, setImgStyle] = useState({});
  const [imgStyleWrapper, setImgStyleWrapper] = useState({});
  const [imgStyleRotate, setImgStyleRotate] = useState({});
  const imageRef = useRef(null);
  const [imageVertical, setImageVertical] = useState(false);
  
  const isImageOriginallyVertical = (_image) => {
    if (_image && (_image.width > _image.height)){
      return false;
    } 
    if (_image && (_image.height > _image.width)) {
      return true
    } 
      return false
  }

  useEffect(() => {
    if (imagePosition === 0 && show) {
      const image = imageRef.current
      setImageVertical(isImageOriginallyVertical(image));
      setModalStyle(prevValues => ({ ...prevValues, width: `${image?.clientWidth > 800 ? image?.clientWidth/2.5 : image?.clientWidth}px`, height: `${image?.clientWidth > 800 ? image?.clientHeight/2.5 + 95 : image?.clientHeight + 95 }px`}))
      setImgStyle(prevValues => ({ ...prevValues, width: `${image?.clientWidth > 800 ? image?.clientWidth/2.5 : image?.clientWidth}px`, height: `${image?.clientWidth > 800 ? image?.clientHeight/2.5 : image?.clientHeight}px`}))
      setImgStyleWrapper(prevValues => ({ ...prevValues, height: `${image?.clientWidth > 800 ? image?.clientWidth/2.5 : image?.clientWidth}px`, width: `${image?.clientWidth > 800 ? image?.clientHeight/2.5 : image?.clientHeight}px`}))
      setImgStyleRotate(prevValues => ({ ...prevValues, 
        transform: `rotateZ(0deg)`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }))
    } 
    if (!show) {
      setImagePosition(0);
      setImgStyle(prevValues => ({ ...prevValues, width: 'unset', height: 'unset'}));
      setImgStyleRotate(prevValues => ({ ...prevValues, width: 'unset', height: 'unset'}));
      setImgStyleWrapper(prevValues => ({ ...prevValues, width: 'unset', height: 'unset'}));
      setModalStyle(prevValues => ({ ...prevValues, width: 'unset', height: 'unset'}));
    }
  }, [show, imageRef.current]);

  const isPositionVertical = (_imagePosition) => {
    if (_imagePosition%180 === 0){
      return false;
    } 
    if (_imagePosition%90 === 0 && _imagePosition%180 !== 0) {
      return true
    } 
    if (_imagePosition === 0) {
      return false;
    }
  }

  const changeModalStyleHeight = (_imagePosition, _originalWidth, _originalHeigth, _closeButtonHeight, _rotateButtonsHeight) => {
    if (isPositionVertical(_imagePosition)) {
      if (_originalWidth > 800) {
        return _originalWidth/2.5 + _closeButtonHeight + _rotateButtonsHeight;
      } 
        return _originalWidth + _closeButtonHeight + _rotateButtonsHeight;
    } 
    if (_originalWidth > 800) {
      return _originalHeigth/2.5 + _closeButtonHeight + _rotateButtonsHeight;
    } 
      return _originalHeigth + _closeButtonHeight + _rotateButtonsHeight;
  }
  const changeModalStyleWidth = (_imagePosition, _originalWidth, _originalHeigth) => {
    if (isPositionVertical(_imagePosition)) {
      if (_originalWidth > 800) {
        return _originalHeigth/2.5;
      } 
        return _originalHeigth;
    } 
    if (_originalWidth > 800) {
      return _originalWidth/2.5;
    } 
      return _originalWidth;
  }
  const setRotateMargin = (_imagePosition, _originalWidth, _originalHeigth) => {
    let margin = '0px';
    if (
      imagePosition === 0 
      || (_imagePosition%90 === 0 && _imagePosition%180 !== 0 && imagePosition > 0) 
      || _imagePosition%360 === 0 
      || (_imagePosition%270 === 0 && imagePosition < 0)) {
      margin = '0'
    }
    if ((_imagePosition%90 === 0 && _imagePosition%180 !== 0 && _imagePosition%270 !== 0 && imagePosition < 0)) {
      margin = `${_originalWidth - _originalHeigth}px 0 0 0`
    }
    if (_imagePosition%180 === 0 && imagePosition !== 0 && !imageVertical) {
      margin = _originalWidth - _originalHeigth > 0 ? `0 -${_originalWidth - _originalHeigth}px 0 0` : `0 ${_originalWidth - _originalHeigth}px 0 0`
    }
    if ((_imagePosition%270 === 0 && imagePosition > 0)) {
      margin = `${_originalWidth - _originalHeigth}px 0 0 0`
    }
    return margin;
  }

  useEffect(() => {
    if (imagePosition === 360) {
      setImagePosition(0);
    }
  }, [imagePosition])

  useEffect(() => {
    const image = imageRef.current;
    if (!show) {
      const initialWidth = 'unset';
      const initialHeight = 'unset';
      setImgStyleRotate({ transform: `rotateZ(${imagePosition}deg)`, margin: '0' })
      setImgStyleWrapper({ width: initialHeight, height: initialWidth })
      setImgStyle({ height: initialHeight, width: initialWidth })
      setModalStyle({ height: initialHeight, width: initialWidth })
    }

    if (image && show) {
      const originalWidth = image.width;
      const originalHeight = image.height;

      const closeButtonHeight = 30;
      const rotateButtonsHeight = 85;
      setImgStyleRotate({
        transform: `rotateZ(${imagePosition}deg)`,
        margin: setRotateMargin(imagePosition, originalWidth, originalHeight),
      })
      setImgStyleWrapper({
        width: originalWidth > 800 ? `${originalHeight/2.5}px` : `${originalHeight}px`,
        height: originalWidth > 800 ? `${originalWidth/2.5}px` : `${originalWidth}px`,
      })
      setImgStyle({
        height: originalWidth > 800 ? `${originalHeight/2.5}px` : `${originalHeight}px`,
        width: originalWidth > 800 ? `${originalWidth/2.5}px` : `${originalWidth}px`,
      })
      setModalStyle({
        height: changeModalStyleHeight(imagePosition, originalWidth, originalHeight, closeButtonHeight, rotateButtonsHeight),
        width: changeModalStyleWidth(imagePosition, originalWidth, originalHeight, closeButtonHeight, rotateButtonsHeight),
      })
    } 
  }, [imagePosition, imageRef.current, show])

  const handleRotateImagePlus = () => { setImagePosition(prevValue => parseInt(prevValue, 10) + 90); }
  const handleRotateImageMinus = () => { setImagePosition(prevValue => parseInt(prevValue, 10) - 90); }

  return (
    <>
      {(show && source) ?
        <ModalPreviewStyled show={show}>
          <div className="modal-content" style={{ height: `${modalStyle.height}px`, width: `${modalStyle.width}px` }}>
            <div className="modal-wrapper" style={{ height: `${modalStyle.height}px`, width: `${modalStyle.width}px` }}>
              <div onClick={() => {
                onModalCancel();
              }} className="close-icon-position"><CloseIcon className="close-icon"/></div>
              <div style={imagePosition === 0 || imageVertical && !isPositionVertical(imagePosition) ? imgStyle : imgStyleWrapper }>
                <div className="modal-body" style={imgStyleRotate}>
                  {isImageAction && <img 
                    ref={imageRef}
                    style={imgStyle}
                    className="modal-img"
                    src={source}
                    alt="img-preview"
                  />}
                  {isVideoAction && (
                    <video className="modal-video" src={source} controls>
                      {i18n.errors.videoNotSupported}
                    </video>
                  )}  
                </div>
              </div>
              <div className="modal-buttons-row">
                  { isImageAction ? <div className="modal-buttons-rotate">
                    <p onClick={() => handleRotateImageMinus()}><RotateLeftIcon className="bigger-icon" /></p>
                    <p onClick={() => handleRotateImagePlus()}><RotateRightIcon className="bigger-icon" /></p>  
                  </div> : null}
                  <div className="wrapper-download">
                    <a className="modal-button-save ts ts-normal" href={source} download>
                      {i18n.buttons.download}
                    </a>   
                  </div>
              </div>
          </div>
        </div>
      </ModalPreviewStyled> : null
    }</>

  );
}

ModalPreview.propTypes = {
  show: PropTypes.bool,
  i18n: PropTypes.object,
  onModalCancel: PropTypes.func,
  action: PropTypes.oneOf([MODAL_TYPE.PREVIEW.IMAGE, MODAL_TYPE.PREVIEW.VIDEO]),
  source: PropTypes.string,
};

export default withI18n(ModalPreview);
