import styled from 'styled-components';

export default styled.li`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 16px 12px;
  margin-bottom: 20px;
  flex-wrap: wrap;

  .agent-conversation-card--info {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 15px;

    .agent-converstation-card--info--text {
      padding-left: 8px;

      .agent-converstation-card--info--text--subtitle {
        font-size: 14px;
        margin-top: 4px;
        color: gray;
      }
    }
  }

  .agent-conversation-card--channel-icon-holder {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;

    justify-content: flex-end;

    .agent-conversation-card--channel-icon-holder--icon {
      color: #cccccc;
      margin-right: 26px;
      height: 32px;
      width: 32px;
    }
  }

  .agent-conversation-card--buttons-row {
    min-width: 120px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;
