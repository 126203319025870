import React from 'react';
import PropTypes from 'prop-types';
import IconButtonStyled from './styled';

const IconButton = ({ disableToolTip, onClick, children, toolTip, className, toolTipPosition, toolTipPositionOffset, disabled }) => (
  <IconButtonStyled
    data-tip={toolTip}
    data-offset={`{'${toolTipPosition}': ${toolTipPositionOffset}}`}
    data-tip-disable={disableToolTip}
    className={`${className} ts ts-normal`}
    onClick={onClick}
    disabled={disabled}
  >
    {children}
  </IconButtonStyled>
);

IconButton.defaultProps = {
  disableToolTip: false,
  className: '',
  toolTipPosition: 'top',
  toolTipPositionOffset: 10,
  disabled: false,
};

IconButton.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.any,
  toolTip: PropTypes.string,
  className: PropTypes.string,
  toolTipPosition: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
  toolTipPositionOffset: PropTypes.number,
  disabled: PropTypes.bool,
  disableToolTip: PropTypes.bool,
};

export default IconButton;
