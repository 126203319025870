import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TagContainer, Tag, MoreTag, Popup, PopupTag } from './styled';

const TagBox = ({ tags, maxWidth = 300, isManager }) => {
  const [visibleTags, setVisibleTags] = useState([]);
  const [hiddenCount, setHiddenCount] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const [popupDirection, setPopupDirection] = useState('down');
  const tagBoxRef = useRef(null);

  const stringToColour = (str = '') => {
    const hash = Array.from(str).reduce((acc, char) => acc + char.charCodeAt(0), 0);
    const randomNumber = (hash * 9301 + 49297) % 233280;
    const hue = Math.abs(randomNumber % 360);
    return `hsl(${hue}, 70%, 50%)`;
  };

  const getTextWidth = (text, font) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context.font = font;
    return context.measureText(text).width;
  };
  useEffect(() => {
    const calculateVisibleTags = () => {
      const visibleTagsToSet = [];
      let totalWidth = 0;
      let hiddenCountToSet = 0;

      tags.forEach(tag => {
        const tagWidth = getTextWidth(tag.name, '14px Arial') + 16;
        if (totalWidth + tagWidth <= maxWidth) {
          totalWidth += tagWidth;
          visibleTagsToSet.push(tag);
        } else {
          hiddenCountToSet += 1;
        }
      });

      setVisibleTags(visibleTagsToSet);
      setHiddenCount(hiddenCountToSet);
    };

    const calculatePopupDirection = () => {
      const tagBoxRect = tagBoxRef.current.getBoundingClientRect();
      const spaceBelow = window.innerHeight - tagBoxRect.bottom;
      const spaceAbove = tagBoxRect.top;

      if (spaceBelow < 150 && spaceAbove > spaceBelow) {
        setPopupDirection('up');
      } else {
        setPopupDirection('down');
      }
    };

    calculateVisibleTags();
    calculatePopupDirection();

    window.addEventListener('resize', calculateVisibleTags);
    window.addEventListener('resize', calculatePopupDirection);
    return () => {
      window.removeEventListener('resize', calculateVisibleTags);
      window.removeEventListener('resize', calculatePopupDirection);
    };
  }, [tags, maxWidth]);


  const handleMouseEnter = () => {
    setShowPopup(true);
  };

  const handleMouseLeave = () => {
    setShowPopup(false);
  };

  return (
    <TagContainer 
      ref={tagBoxRef} 
      maxWidth={maxWidth}
      isManager={isManager}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {visibleTags.map(tag => {
        const color = stringToColour(tag?.name);
        return (
        <Tag key={tag.id} color={color}>
          {tag.name}
        </Tag>
      )})}
      {hiddenCount > 0 && (
        <MoreTag>
          +{hiddenCount}
        </MoreTag>
      )}
      {showPopup && (
        <Popup direction={popupDirection}>
          {tags.map(tag => {
            const color = stringToColour(tag?.name);
            return (
            <PopupTag key={tag.id} color={color}>
              {tag.name}
            </PopupTag>
          )})}
        </Popup>
      )}
    </TagContainer>
  );
};
TagBox.propTypes = {
  tags: PropTypes.array,
  maxWidth: PropTypes.number,
  isManager: PropTypes.bool,
};
export default TagBox;
