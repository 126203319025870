import styled from 'styled-components';

const DocumentMessageBubbleStyled = styled.div`
  position: relative;
  padding-top: 8px;
  padding-bottom: 8px;
  ${({ isThereErrorIcon }) => (isThereErrorIcon ? 'padding-right: 40px;' : '')}
  text-align: ${({ sendType }) => (sendType === 'operator' ? 'right' : 'left')};

  .message-bubble-shape {
    padding: 3px 3px 0 !important;
  }
  .message-bubble-shape-operator {
    padding: 3px 3px 0 !important;
  }

  .error-icon-holder {
    position: absolute;
    top: 30px;
    right: 0;
    cursor: pointer;
    .info-icon {
      width: 28px;
      height: 28px;
      margin-left: 8px;
      margin-bottom: 4px;
    }
    .error {
      fill: ${({ theme }) => theme.colors.red};
    }
  }

  .info-row {
    display: flex;
    flex-direction: row;
    margin-right: 9px;
    align-items: center;
    justify-content: flex-end;

    .info-icon {
      width: 16px;
      height: 16px;
      margin-left: 8px;
      margin-bottom: 4px;
      fill: gray;
    }
    .read {
      fill: ${({ theme }) => theme.colors.checkMarkBlue};
    }
    .error {
      fill: ${({ theme }) => theme.colors.red};
    }
  }

  .document-holder {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${(props) => props.theme.colors.grey[400]};
    padding: 8px;
    border-radius: 4px;

    .caption-text {
      font-size: 11px;
      font-style: italic;
      color: ${(props) => props.theme.colors.grey[100]};
      text-align: start;
    }

    .error-icon {
      fill: ${(props) => props.theme.colors.red};
      margin-left: 8px;
    }
    .btn-download {
      margin-left: 12px;
      background-color: white;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      -webkit-box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.1);
      box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.1);

      .btn-icon {
        margin-bottom: 3px;
      }

      &:hover {
        background-color: ${(props) => props.theme.colors.secondary.main};
        cursor: pointer;
        svg {
          fill: white;
        }
      }
    }
    .spinner-holder {
      margin-left: 12px;
      background-color: white;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      -webkit-box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.1);
      box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.1);
    }
    .icon-size {
      width: 24px;
      height: 24px;
    }
    .icon-margin-right {
      margin-right: 8px;
    }
  }
`;

export { DocumentMessageBubbleStyled };
