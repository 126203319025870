import styled from 'styled-components';

const ComponentStyled = styled.div`
  .menu-item-wrapper {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export default ComponentStyled;
