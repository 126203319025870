/* eslint-disable react/prop-types */
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Home, NotFound, Auth, Supervisor, TestPage, Login, Applications, DoubleFactor } from '../../pages';
import Paths from '../../../config/routes';
import PrivateRoute from './private-route';
import SupervisorRoute from './supervisor-route';
import AgentRoute from './agent-route';

const isDevEnv = process.env.NODE_ENV === 'development';

const Routes = () => (
  <Router>
    <Switch>
      {isDevEnv && <Route exact path={Paths.TEST} component={TestPage} />}
      <Route exact path={Paths.LOGIN} render={Login} />
      <Route exact path={Paths.DOUBLE_FACTOR_FORM} render={DoubleFactor} /> 
      <SupervisorRoute path={Paths.SUPERVISOR} component={Supervisor} />
      <AgentRoute exact path={Paths.AGENT} component={Home} />
      <AgentRoute exact path={Paths.AUDITOR} component={Home} />
      <PrivateRoute exact path={Paths.APPLICATIONS} component={Applications} />
      <Route exact path={Paths.AUTH} component={Auth} />
      <Route exact path={Paths.ERROR} component={NotFound} />
      <AgentRoute exact path={Paths.CONVERSATION} component={Home} />
      <AgentRoute exact path={Paths.STATS} component={Home} />
      <Route path="*" render={() => <NotFound noMatch />} />
    </Switch>
  </Router>
);

export default Routes;
