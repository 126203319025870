/* eslint-disable react/button-has-type */
import * as React from "react";
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';

import { useAddToHomescreenPrompt } from "./useAddToHomescreenPrompt";
import ModalConfirm from '../../molecules/modal-confirm'
import { withI18n } from '../../../hocs';


const PopupInstallPWA = ({ i18n }) => {
  const [prompt, promptToInstall] = useAddToHomescreenPrompt();
  const [isVisible, setVisibleState] = React.useState(false);
  
  const hide = () => setVisibleState(false);
  React.useEffect(
    () => {
      if (prompt) {
        setVisibleState(true);
      }
    },
    [prompt]
  );

  if (!isVisible) {
    return <div />;
  }

  return (
    <ModalConfirm 
      show={isVisible && isMobile}
      onModalCancel={hide}
      onModalDoAction={() => {
        promptToInstall();
        hide();
      }}
      title={i18n.modalInstallApp.title}
      body={i18n.modalInstallApp.body}
      actionLabel={i18n.modalInstallApp.install}
      cancelLabel={i18n.modalInstallApp.cancel}
    />
  );
};
PopupInstallPWA.propTypes = {
  i18n: PropTypes.object,
};

export default withI18n(PopupInstallPWA);