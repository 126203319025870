import styled from 'styled-components';

const ComponentStyled = styled.div`
  .logo-holder {
    background: ${({ theme }) => theme.colors.white};
    width: 100%;
    border-bottom: 1px solid ${(props) => props.theme.colors.grey[300]};
    margin-bottom: 30px;

    .logo {
      height: 46px;
    }
  }

  .load-more-wrapper {
    margin-top: 30px;
  }

  .spinner-wrapper {
    text-align: center;
  }

  .application-container {
    padding: 15px;
    max-width: 500px;
  }
`;

export default ComponentStyled;
