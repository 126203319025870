const MSG_SEND_TYPE = {
  INPUT: 'input',
  OPERATOR: 'operator',
  DIALOG_FLOW: 'dialogflow',
  PROACTIVE: 'proactive'
};

Object.freeze(MSG_SEND_TYPE);

export default MSG_SEND_TYPE;
