import React from 'react';
import PropTypes from 'prop-types';
import { ClipLoader } from 'react-spinners';
import GoogleMap from '../GoogleMap';
import { ErrorIcon } from '../../../atoms';
import i18n from '../../../../assets/i18n';

export const MessageLocationHolder = ({ isFetching, isDownloadError, data }) => (
  <div className="multimedia-holder">
    {isFetching && <ClipLoader size={40} loading={isFetching} />}
    {isDownloadError ? (
      <>
        <ErrorIcon className="error-icon" />
        <p className="bubble-info-text">{i18n.errors.locationNotAvailable}</p>
      </>
    ) : (
      data && <GoogleMap data={data} />
    )}
  </div>
)

MessageLocationHolder.propTypes = {
  isFetching: PropTypes.bool,
  isDownloadError: PropTypes.bool,
  data: PropTypes.object,
};