import styled from 'styled-components';

export default styled.section`
  display: ${(props) => (props.show ? 'block' : 'none')};
  position: fixed;
  place-items: center;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: 0px 0px 5px 0px rgba(97, 97, 97, 1);
  -moz-box-shadow: 0px 0px 5px 0px rgba(97, 97, 97, 1);
  box-shadow: 0px 0px 5px 0px rgba(97, 97, 97, 1);
  padding: 100px 15px 15px 15px;

  .modal__template-form {
    display: ${(props) => (props.show ? 'flex' : 'none')};
    flex-flow: column;
    padding: 1.5rem;
    background-color: ${(props) => props.theme.colors.white};
    margin: auto;
    border-radius: 8px;
    width: 600px;
    max-width: 100%;
  }

  .modal__title {
    color: ${({ theme }) => theme.colors.primary.main};
    font-weight: 700;
    font-size: 1.25rem;
  }

  .modal__bottom-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex: 2;
  }

  .input-modal {
    padding: 10px;
    width: 100%;
    border-radius: 4px;
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 20px;
    border-style: solid;
    border-width: 1px;
    border-color: hsl(0, 0%, 80%);
    &:hover {
      border-color: rgb(169, 169, 169);
    }
    &.invalid-input {
      border: solid 1px ${({ theme }) => theme.colors.red};
    }
  }

  .text-area-modal {
    height: 8em;
  }

  @media (max-width: ${({ theme }) => theme.bp.md}px) {
    padding: 15px;
    .modal__template-form {
      width: 100%;
      min-height: 100%;
    }
  }
`;
