import sampleUserIcon from './account_circle.svg';
import logo from './logo_indigitall@0.5x.png';
import logoWhite from './logo_indigitall_white@0.5x.png';
import iconClose from './icon-close.png';
import accountRemove from './account-remove.svg';
import accountArrowRight from './account-arrow-right.svg';
import imageMessagePh from './image_ph.png';
import attachFile from './attach_file-24px.svg';
import cloudUpload from './cloud_upload.svg';
import sfRed from './salesforce-red.png';
import sfGreen from './salesforce-green.png';
import logoPush from './logo_push.png';

export default {
  sampleUserIcon,
  logo,
  iconClose,
  accountRemove,
  accountArrowRight,
  imageMessagePh,
  attachFile,
  cloudUpload,
  sfGreen,
  sfRed,
  logoPush,
  logoWhite,
};
