import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import images from '../../../assets/images';
import { RegularButton } from '../../molecules';
import { Spinner } from '../../atoms';
import { ApplicationsList } from '../../organisms';
import ComponentStyled from './styled';
import { withI18n } from '../../../hocs';
import * as API from '../../../api';
import { AuthService } from '../../../services';

const APPLICATIONS_LIMIT = 10;

const Applications = ({ i18n }) => {
  const [applicationsPage, setApplicationsPage] = useState(0);
  const [applicationsTotal, setApplicationsTotal] = useState(0);
  const [isFetchingApplications, setIsFetchingApplications] = useState(true);
  const [applications, setApplications] = useState([]);

  useEffect(() => {
    async function fetchApplications() {
      const adminToken = AuthService.getAdminToken();
      const accountId = AuthService.getAccountId();
      API.tokenToAxiosHeader(adminToken);
      try {
        setIsFetchingApplications(true);
        const { data } = await API.getApplications(accountId, APPLICATIONS_LIMIT, applicationsPage);
        setApplications((prevState) => [...prevState, ...data.data]);
        setApplicationsTotal(data.count);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
      } finally {
        setIsFetchingApplications(false);
      }
    }

    fetchApplications();
  }, [applicationsPage]);

  const handleClickLoadMore = () => {
    setApplicationsPage((prevState) => prevState + 1);
  };

  const formatApplications = applications.filter((application) => application.chatEnabled);

  return (
    <ComponentStyled>
      <div className="logo-holder">
        <img className="logo" src={images.logo} alt="logo" />
      </div>
      <div className="application-container">
        {formatApplications.length === 0 && isFetchingApplications ? (
          <Spinner size={50} />
        ) : (
          <>
            <ApplicationsList applications={formatApplications} />
            {applicationsTotal > applications.length && (
              <div className="load-more-wrapper">
                <RegularButton isFetching={isFetchingApplications} label={i18n.applications.loadMore} onClick={handleClickLoadMore} />
              </div>
            )}
          </>
        )}
      </div>
    </ComponentStyled>
  );
};

Applications.propTypes = {
  i18n: PropTypes.object,
};

export default withI18n(Applications);
