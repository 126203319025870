import React from 'react';
import PropTypes from 'prop-types';
import { ClipLoader } from 'react-spinners';
import { LocationMessageBubbleStyled } from './styled';
import i18n from '../../../assets/i18n';
import { ErrorIcon } from '../../atoms';
import GoogleMap from '../multimedia-message-bubble/GoogleMap';

const LocationTemplateHeader = ({ isFetching, isDownloadError, locationParameters, viewRef }) => {
  const latitudeAsInt = parseInt(locationParameters.latitude, 10);
  const longitudeAsInt = parseInt(locationParameters.longitude, 10);
  const data = {
    ...locationParameters,
    latitude: latitudeAsInt,
    longitude: longitudeAsInt,
  };
  return (
    <LocationMessageBubbleStyled ref={viewRef}>
      <div className="multimedia-holder">
        {isFetching && <ClipLoader size={40} loading={isFetching} />}
        {isDownloadError ? (
          <>
            <ErrorIcon className="error-icon" />
            <p className="bubble-info-text">{i18n.errors.locationNotAvailable}</p>
          </>
        ) : (
          data && <GoogleMap data={data} />
        )}
      </div>
    </LocationMessageBubbleStyled>
  );
};

LocationTemplateHeader.propTypes = {
  isFetching: PropTypes.bool,
  isDownloadError: PropTypes.bool,
  locationParameters: PropTypes.object,
  viewRef: PropTypes.any,
};

export default LocationTemplateHeader;
