import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ClipLoader } from 'react-spinners';
import { DocumentMessageBubbleStyled } from './styled';
import i18n from '../../../assets/i18n';
import { FileIcon, DownloadIcon, ErrorIcon } from '../../atoms';

const DocumentMessageBubble = ({ colorClass, caption, date, sendType, isFetching, isDownloadError, docSource, viewRef, statusIcon, errorIcon }) => {
  return (
    <DocumentMessageBubbleStyled ref={viewRef} sendType={sendType} isThereErrorIcon={!_.isNil(errorIcon)}>
      {errorIcon && (
        <div className="error-icon-holder" data-tip={i18n.errors.tooltipError} data-offset="{'left': 5}">
          {errorIcon}
        </div>
      )}
      <div className={colorClass}>
        <div className="document-holder">
          <FileIcon className="icon-size icon-margin-right" />
          {isDownloadError ? (
            <>
              <p className="caption-text">{i18n.errors.documentFileNotAvailable}</p>
              <ErrorIcon className="error-icon" />
            </>
          ) : (
            <>
              <p className="caption-text">{caption}</p>
              {isFetching ? (
                <div className="spinner-holder">
                  <ClipLoader size={16} loading={isFetching} />
                </div>
              ) : (
                <a className="btn-download ts" href={docSource} target="_blank" rel="noopener noreferrer">
                  <DownloadIcon className="icon-size btn-icon ts" />
                </a>
              )}
            </>
          )}
        </div>
        <div className="info-row">
          <p className="timespan-message">{date}</p>
          {statusIcon}
        </div>
      </div>
    </DocumentMessageBubbleStyled>
  );
};

DocumentMessageBubble.propTypes = {
  colorClass: PropTypes.string,
  caption: PropTypes.string,
  date: PropTypes.string,
  sendType: PropTypes.string,
  isFetching: PropTypes.bool,
  isDownloadError: PropTypes.bool,
  docSource: PropTypes.string,
  viewRef: PropTypes.any,
  statusIcon: PropTypes.any,
  errorIcon: PropTypes.any,
};

export default DocumentMessageBubble;
