import styled from 'styled-components';

const StyledTextArea = styled.div`
  .text-area-modal {
    padding: 10px;
    width: 100%;
    border-radius: 4px;
    border-color: hsl(0,0%,80%);
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 20px;
    &:hover {
      border-color: rgb(169, 169, 169)
    }
  }
  .input-modal {
    border-style: solid;
    border-width: 1px;
  }

  .invalid-input {
    border-color: red;
  }
`;

export default StyledTextArea;
