const MIME_TYPE = {
  IMAGE: 'image/jpeg',
  DOCUMENT: 'application/pdf',
  AUDIO: 'audio/ogg; codecs=opus',
  VIDEO: 'video/*',
};

Object.freeze(MIME_TYPE);

export default MIME_TYPE;
