import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { useMediaQuery } from 'react-responsive';
import bp from '../../../config/breakpoints';
import StyledModalBannedUsers from './styled';
import { IconButton, DeleteIcon, CancelButton } from '../../atoms';
import { withI18n } from '../../../hocs';

const ModalBannedUsers = ({ show, bannedUsersList, title, onCancel, unbanTooltip, onUnbanUser, i18n, cancelLabel }) => {
  const isMediumScreen = useMediaQuery({ query: `(max-width: ${bp.md}px)` });

  const handleBackdropClick = (e) => {
    if (e.target === e.currentTarget) {
      e.preventDefault();
      onCancel();
    }
  };

  return (
    <StyledModalBannedUsers onClick={handleBackdropClick} show={show}>
      <div className="modal-banned-users">
        <div className="modal-banned-users__title">{title}</div>
        <table className="table-banned">
          <thead>
            <tr className="tr-table_banned">
              <th className="th-banned">{i18n.chats.conversationId}</th>
              <th className="th-banned">{i18n.chats.date}</th>
              <th className="th-banned">{i18n.chats.reason}</th>
              <th className="th-banned">{}</th>
            </tr>
          </thead>
          <tbody>
            {bannedUsersList?.map((bannedUser) => (
              <tr key={bannedUser?.conversationSessionId} className="tr-table_banned">
                <td className="td-banned">{bannedUser?.conversationSessionId}</td>
                <td className="td-banned">{bannedUser?.createdAt}</td>
                <td className="td-banned">{bannedUser?.reason}</td>
                <td className="td-banned">
                  <IconButton
                    className="delete-icon"
                    toolTip={unbanTooltip}
                    toolTipPosition="left"
                    toolTipPositionOffset={5}
                    disableToolTip={isMediumScreen}
                    onClick={() =>
                      onUnbanUser({
                        conversationSessionId: bannedUser?.conversationSessionId,
                        reason: bannedUser?.reason,
                      })
                    }
                  >
                    <DeleteIcon />
                  </IconButton>
                </td>
                <ReactTooltip type="info" />
              </tr>
            ))}
          </tbody>
        </table>
        <div className="modal-buttons-row">
          <CancelButton onClick={onCancel} label={cancelLabel} />
        </div>
      </div>
    </StyledModalBannedUsers>
  );
};

ModalBannedUsers.propTypes = {
  show: PropTypes.bool,
  bannedUsersList: PropTypes.array,
  title: PropTypes.string,
  onUnbanUser: PropTypes.func.isRequired,
  unbanTooltip: PropTypes.string,
  onCancel: PropTypes.func,
  i18n: PropTypes.object,
  cancelLabel: PropTypes.string,
};

export default withI18n(ModalBannedUsers);
