const STORAGE_ITEM = {
  WA_TOKEN: 'waToken',
  ADM_TOKEN: 'adminToken',
  APP_ID: 'applicationId',
  USER_ROL: 'userRol',
  ACCOUNT_ID: 'accountId',
  USER_WEB_ID: 'userWebId',
  AGENT_ID: 'agentId',
  APPLICATION_NAME: 'applicationName',
  APPLICATION_IMAGE: 'applicationImage',
  LONGLIVED2FATOKEN: 'longLived2FaToken',
  SHORT_TOKEN: 'shortToken',
};

Object.freeze(STORAGE_ITEM);

export default STORAGE_ITEM;
