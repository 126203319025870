import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import classNames from 'classnames';
import ModalStyled from './styled';
import { withI18n } from '../../../hocs';
import { useSocketContext } from '../../../sockets/context';

const customStyles = {
  container: (provided) => ({
    ...provided,
    width: '100%',
    marginTop: 20,
    marginBottom: 20,
  }),
  input: (provided) => ({
    ...provided,
    paddingTop: 10,
    paddingBottom: 10,
  }),
  singleValue: (provided) => ({
    ...provided,
    overflow: 'visible',
  }),
};

const AgentRow = ({ isFocused, isSelected, isDisabled, label }) => (
  <div
    className={classNames({
      'select-custom-option': true,
      selected: isSelected,
      disabled: isDisabled,
      focused: isFocused,
    })}
  >
    <span>{label}</span>
  </div>
);

AgentRow.propTypes = {
  isDisabled: PropTypes.bool,
  label: PropTypes.string,
  isFocused: PropTypes.bool,
  isSelected: PropTypes.bool,
};

const CustomOption = ({ innerProps, isFocused, isSelected, isDisabled, data }) => {
  const label = data;
  return !isDisabled ? (
    <div {...innerProps}>
      <AgentRow isFocused={isFocused} isSelected={isSelected} isDisabled={isDisabled} label={label} />
    </div>
  ) : null;
};

CustomOption.propTypes = {
  innerProps: PropTypes.object,
  isDisabled: PropTypes.bool,
  isFocused: PropTypes.bool,
  isSelected: PropTypes.bool,
  data: PropTypes.string,
};

const ModalSF = ({ show, i18n, onModalCancel, action }) => {
  const [selectedType, setSelectedType] = useState(null);
  const [selectedReason, setSelectedReason] = useState(null);
  const [selectedPriority, setSelectedPriority] = useState(null);
  const [description, setDescription] = useState(null);
  const [externalId, setExternalId] = useState(null);
  const { conversations, selectedChatId } = useSocketContext();
  let { crmCaseValues } = useSocketContext();
  if (!crmCaseValues) {
    crmCaseValues = {};
  }
  const { type, motive, priority } = crmCaseValues;
  useEffect(() => {
    setSelectedType(null);
    setSelectedReason(null);
    setDescription(null);
    setExternalId(null);
    setSelectedPriority(null);
    const conversation = conversations?.filter((conv) => conv.conversationSessionId === selectedChatId);
    if (conversation && conversation[0] && conversation[0].externalId) {
      setExternalId(conversation[0].externalId);
    }
  }, [show, conversations, selectedChatId]);
  const handleModalSubmit = () => {
    const crmCase = {
      externalId,
      type: selectedType,
      reason: selectedReason,
      priority: selectedPriority,
      description,
    };
    return crmCase;
  };
  const getValue = (value) => (value ? { label: value, value } : undefined);
  const isFormDisabled = () => !externalId;
  return (
    <ModalStyled show={show}>
      <div className="modal-content">
        <h2 className="modal-title">{i18n.modalSF.title}</h2>
        <div className="modal-body">
          <p className="modal-text body-p-modal">{i18n.modalSF.body}</p>
          <p className="modal-text">{i18n.modalSF.labelExternalId}</p>
          <input
            type="text"
            required
            value={externalId || ''}
            onChange={(e) => setExternalId(e.target.value)}
            className={`text-area-modal input-modal ${externalId && externalId !== '' ? '' : 'invalid-input'} `}
          />
          <p className="modal-text">{i18n.modalSF.labelType}</p>
          <div className="modal-select">
            <Select
              options={type}
              styles={customStyles}
              placeholder={i18n.modalSF.type}
              noOptionsMessage={() => i18n.supervisor.modalTransfer.selectNoOptionsMessage}
              components={{ Option: CustomOption }}
              onChange={(v) => setSelectedType(v)}
              value={getValue(selectedType)}
            />
          </div>
          <p className="modal-text">{i18n.modalSF.labelReason}</p>
          <div className="modal-select">
            <Select
              options={motive}
              styles={customStyles}
              placeholder={i18n.modalSF.reason}
              noOptionsMessage={() => i18n.supervisor.modalTransfer.selectNoOptionsMessage}
              components={{ Option: CustomOption }}
              onChange={(v) => setSelectedReason(v)}
              value={getValue(selectedReason)}
            />
          </div>
          <p className="modal-text">{i18n.modalSF.labelPriority}</p>
          <div className="modal-select">
            <Select
              options={priority}
              styles={customStyles}
              placeholder={i18n.modalSF.priority}
              noOptionsMessage={() => i18n.supervisor.modalTransfer.selectNoOptionsMessage}
              components={{ Option: CustomOption }}
              onChange={(v) => setSelectedPriority(v)}
              value={getValue(selectedPriority)}
            />
          </div>
          <p className="modal-text">{i18n.modalSF.labelDescription}</p>
          <div>
            <textarea onChange={(v) => setDescription(v.target.value)} className="text-area-modal" placeholder={i18n.modalSF.description} rows="4" cols="50" />
          </div>
          <hr />
          <div className="modal-buttons-row">
            <CancelButton onClick={onModalCancel} label={i18n.buttons.cancel} />
            <SubmitButton
              disabled={isFormDisabled()}
              onClick={() => action(handleModalSubmit(selectedType, selectedReason, selectedPriority, description, externalId))}
              label={i18n.modalSF.saveAndCloseConv}
            />
          </div>
        </div>
      </div>
    </ModalStyled>
  );
};

ModalSF.propTypes = {
  show: PropTypes.bool,
  i18n: PropTypes.object,
  onModalCancel: PropTypes.func,
  action: PropTypes.func,
};

const CancelButton = ({ onClick, label }) => (
  <button onClick={onClick} className="modal-button-cancel ts ts-normal" type="button">
    {label}
  </button>
);

CancelButton.propTypes = {
  onClick: PropTypes.func,
  label: PropTypes.string,
};

const SubmitButton = ({ onClick, label, disabled }) => (
  <button disabled={disabled} onClick={onClick} className="modal-button-finish ts ts-normal" type="button">
    {label}
  </button>
);

SubmitButton.propTypes = {
  onClick: PropTypes.func,
  label: PropTypes.string,
  disabled: PropTypes.bool,
};

export default withI18n(ModalSF);
