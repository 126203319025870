import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { DoubleFactorForm } from '../../organisms';
import images from '../../../assets/images';
import ComponentStyled from './styled';
import PopupInstallPWA from '../../organisms/popup-install-pwa/PopupInstallPWA';

const DoubleFactor = ({ history }) => {
  
  

  return (
    <ComponentStyled>
      <PopupInstallPWA />
      <div className="logo-holder">
        <img className="logo" src={images.logoWhite} alt="logo" />
      </div>
      <div className="login-container">
        <DoubleFactorForm history={history} />
      </div>
    </ComponentStyled>
  );
};

DoubleFactor.propTypes = {
  history: PropTypes.object,
};

export default withRouter(DoubleFactor);
