export default {
  TEST: '/test',
  LOGIN: '/login',
  AUTH: '/',
  APPLICATIONS: '/applications',
  CONVERSATION: '/conversation',
  STATS: '/stats',
  ERROR: '/error',
  SUPERVISOR: '/dashboard/supervisor',
  SUPERVISOR_AGENTS: '/dashboard/supervisor/agents',
  SUPERVISOR_STATISTICS: '/dashboard/supervisor/statistics',
  SUPERVISOR_AGENT_DETAIL: '/dashboard/supervisor/agents/:agentId',
  SUPERVISOR_CONVERSATION_DETAIL: '/dashboard/supervisor/agents/:agentId/conversation/:conversationId',
  AGENT: '/dashboard/agent',
  AUDITOR: '/dashboard/auditor',
  NOT_FOUND: '/not-found',
  DOUBLE_FACTOR_FORM: '/login2Fa'
};
