import { Howl, Howler } from 'howler';
import AuthService from './auth-service';
import { USER_ROL } from '../models';

class AudioService {
  audioOptions = {
    volume: 1,
    throttleMs: 100,
  };

  audioHandler = null;

  setAudioSource = (source) => {
    if (source) this.audioHandler = new Howl({
      src: [source],
    });
  };

  play = () => {
    const user = AuthService.getUserRol();
    if (this.audioHandler && user === USER_ROL.AGENT) {
      this.setVolume(0.8)
      this.audioHandler.play();
    } else if (user === USER_ROL.AGENT) {
      // eslint-disable-next-line no-console
      console.error('Not able to able to play. Have you set audio Source file?');
    }
  };

  setVolume = (volume) => {
    if (this.audioHandler) {
      Howler.volume(volume);
    } else {
      // eslint-disable-next-line no-console
      console.error('Not able to able to set Volume. Have you set audio Source file?');
    }
  };
}

const instance = new AudioService();

export default instance;
