/* eslint-disable react/prop-types */
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthService } from '../../../services';
import Paths from '../../../config/routes';

export default function PrivateRoute({ component: Component, ...rest }) {
  const adminToken = AuthService.getAdminToken();

  if (!adminToken) {
    return <Redirect to={{ pathname: Paths.NOT_FOUND }} />;
  }

  return <Route {...rest} render={(props) => <Component {...props} />} />;
}
