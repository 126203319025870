import React from 'react';
import PropTypes from 'prop-types';
import { ClipLoader } from 'react-spinners';
import { ErrorIcon, PlayIcon } from '../../../atoms';
import i18n from '../../../../assets/i18n';

export const MessageVideoHolder = ({ isFetching, isDownloadError, videoSource, handleClick }) => (
  <div className="multimedia-holder">
    {isFetching && <ClipLoader size={40} loading={isFetching} />}
    {isDownloadError ? (
      <>
        <ErrorIcon className="error-icon" />
        <p className="bubble-info-text">{i18n.errors.videoFileNotAvailable}</p>
      </>
    ) : (
      videoSource && (
        <div className="play-info-holder" onClick={handleClick}>
          <PlayIcon className="play-icon" />
          <p className="bubble-info-text">{i18n.multimedia.playVideo}</p>
        </div>
      )
    )}
  </div>
);

MessageVideoHolder.propTypes = {
  isFetching: PropTypes.bool,
  isDownloadError: PropTypes.bool,
  videoSource: PropTypes.string,
  handleClick: PropTypes.func,
};