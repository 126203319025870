/* eslint-disable class-methods-use-this */
import * as api from '../api';
import { STORAGE_ITEM } from '../models';

class TopicsService {
  async createTopic(name, code) {
    const applicationId = parseInt(localStorage.getItem(STORAGE_ITEM.APP_ID), 10);
    return api.postTopic(applicationId, name, code);
  }

  async obtainTopics(applicationId, find = '') {
    try {
      const tags = await api.getTopics(applicationId, find);
      if (tags?.data?.topics) {
        return tags.data.topics;
      }
      return tags;
    } catch (error) {
      return { ok: false };
    }
  }

  async deleteTopic(idTopic) {
    const applicationId = parseInt(localStorage.getItem(STORAGE_ITEM.APP_ID), 10);
    return api.deleteTopic(applicationId, idTopic);
  }

  async updateTopic(topic) {
    const applicationId = parseInt(localStorage.getItem(STORAGE_ITEM.APP_ID), 10);
    return api.putTopic(applicationId, topic);
  }

  async unsubscribeTopics(tagsToDelete, convId) {
    const applicationId = parseInt(localStorage.getItem(STORAGE_ITEM.APP_ID), 10);
    const unsubscribedTopics = await api.unsubscribeTopics(applicationId, tagsToDelete, convId);
    return unsubscribedTopics;
  }

  async subscribeTopics(tagsToDelete, convId) {
    const applicationId = parseInt(localStorage.getItem(STORAGE_ITEM.APP_ID), 10);
    const subscribedTopics = await api.subscribeTopics(applicationId, tagsToDelete, convId);
    return subscribedTopics;
  }
}

const instance = new TopicsService();

export default instance;
