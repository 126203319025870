import React from 'react';
import PropTypes from 'prop-types';
import { ModalTransferStyled } from './styled';
import { withI18n } from '../../../hocs';
import { AGENT_STATUS } from '../../../models';
import { UserIcon } from '../../atoms';

const CancelButton = ({ onClick, label }) => (
  <button onClick={onClick} className="modal-button-cancel ts ts-normal" type="button">
    {label}
  </button>
);

CancelButton.propTypes = {
  onClick: PropTypes.func,
  label: PropTypes.string,
};

const SubmitButton = ({ onClick, disabled }) => (
  <button disabled={disabled} onClick={onClick} className="modal-button-transfer ts ts-normal" type="button">
    <svg style={{ width: 24, height: 24 }} viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M19,21V19H15V17H19V15L22,18L19,21M10,4A4,4 0 0,1 14,8A4,4 0 0,1 10,12A4,4 0 0,1 6,8A4,4 0 0,1 10,4M10,14C11.15,14 12.25,14.12 13.24,14.34C12.46,15.35 12,16.62 12,18C12,18.7 12.12,19.37 12.34,20H2V18C2,15.79 5.58,14 10,14Z"
      />
    </svg>
  </button>
);
SubmitButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

const AgentList = ({ i18n, agents, transferFunction }) => {
  return (
    <div className="agent-list-container">
      {agents
        .filter((el) => el.status !== AGENT_STATUS.OFFLINE)
        .map((el) => {
          let classStatus;
          let copyStatus;
          switch (el.status) {
            case AGENT_STATUS.ONLINE:
            case AGENT_STATUS.HALTING:
              classStatus = 'color-unavailable';
              copyStatus = i18n.chats.unavailable;
              break;
            case AGENT_STATUS.AVAILABLE:
              classStatus = 'color-available';
              copyStatus = i18n.chats.available;
              break;
            default:
              classStatus = 'color-available';
              copyStatus = i18n.chats.unknown;
              break;
          }
          const anonymousPic = <UserIcon fill="#CECECE" />;
          return (
            <div className="agent-item" key={el.agentId}>
              <div className="little-padding user-info">
                <div className="div-avatar-agent">
                  {el.userImage && el.userImage !== '' ? <img className="agent-avatar" src={el.userImage} alt="profile pic" /> : anonymousPic}
                </div>
                <div className="agent-name-email">
                  <span>{el.name}</span>
                  <span>{el.email}</span>
                </div>
              </div>
              <div className="little-padding agent-conversations">
                <span>{`${el.numConversations} ${i18n.chats.conversations}`}</span>
              </div>
              <div className="little-padding agent-status">
                <span className={classStatus}>{copyStatus}</span>
              </div>
              <div className="agent-transfer">
                <SubmitButton disabled={el.status !== AGENT_STATUS.AVAILABLE} className="modal-button-transfer" onClick={() => transferFunction(el.agentId)} />
              </div>
            </div>
          );
        })}
    </div>
  );
};
AgentList.propTypes = {
  i18n: PropTypes.object,
  agents: PropTypes.array,
  transferFunction: PropTypes.func,
};

const ModalTransfer = ({ show, i18n, agents, onModalCancel, onModalFinish }) => {
  const { title, body, noAvailableAgents, cancel } = i18n.chats.transferChatModal;
  return (
    <ModalTransferStyled show={show}>
      <div className="modal-content">
        <h2 className="modal-title">{title}</h2>
        <div className="modal-body">
          <p className="modal-text">{agents && agents.length > 0 ? body : noAvailableAgents}</p>
          <AgentList i18n={i18n} agents={agents} transferFunction={onModalFinish} />
          <hr />
          <div className="modal-buttons-row">
            <CancelButton onClick={onModalCancel} label={cancel} />
          </div>
        </div>
      </div>
    </ModalTransferStyled>
  );
};

ModalTransfer.propTypes = {
  show: PropTypes.bool,
  i18n: PropTypes.object,
  onModalFinish: PropTypes.func,
  onModalCancel: PropTypes.func,
  agents: PropTypes.array,
};

export default withI18n(ModalTransfer);
