import { STORAGE_ITEM } from "../models";

import * as api from "../api";

class TemplateService {

  static mapToTemplate = (cannedMessage) => ({
    id: cannedMessage.id,
    title: cannedMessage.title,
    message: cannedMessage.content.body,
    onlyAuditor: cannedMessage.onlyAuditor,
    template: {
      content: cannedMessage.content,
      contentType: cannedMessage.contentType,
    }
  });

  static getList = async () => {
    const appId = localStorage.getItem(STORAGE_ITEM.APP_ID);
    const adminToken = localStorage.getItem(STORAGE_ITEM.ADM_TOKEN);
    api.tokenToAxiosHeader(adminToken);
    const fragments = await api.getTemplates(appId);
    return fragments.data.data.map(this.mapToTemplate);
  };
  static mapToCannedMessage = (template) => ({
    title: template.title,
    contentType: 'text',
    content: {
      body: template.message
    },
  });

  static create = async (template) => {
    const appId = localStorage.getItem(STORAGE_ITEM.APP_ID);
    const adminToken = localStorage.getItem(STORAGE_ITEM.ADM_TOKEN);
    api.tokenToAxiosHeader(adminToken);
    const response = await api.postTemplate(appId, this.mapToCannedMessage(template));
    return this.mapToTemplate(response.data.data);
  };

  static delete = async (templateId) => {
    const appId = localStorage.getItem(STORAGE_ITEM.APP_ID);
    const adminToken = localStorage.getItem(STORAGE_ITEM.ADM_TOKEN);
    api.tokenToAxiosHeader(adminToken);
    const response = await api.deleteTemplate(appId, templateId);
    return response;
  }

  static getAuditorTemplate = async () => {
    const chatPermission = 'agent_auditor';
    const appId = localStorage.getItem(STORAGE_ITEM.APP_ID);
    const adminToken = localStorage.getItem(STORAGE_ITEM.ADM_TOKEN);
    api.tokenToAxiosHeader(adminToken);
    const fragments = await api.getTemplate(appId, chatPermission);
    if (fragments) {
      return fragments.data.data;
    }
    return null;
  };

  static sendTemplate = async (template, toWho, channelConv) => {
    const { content, contentType } = template;
    const bodyRequest = {
      messages: [
        {
          to: toWho,
          channel: channelConv,
          sendType: 'proactive',
          contentType,
          content,

        }
      ],
    }
    const appId = localStorage.getItem(STORAGE_ITEM.APP_ID);
    const adminToken = localStorage.getItem(STORAGE_ITEM.ADM_TOKEN);
    const resp = await api.sendTemplate(appId, adminToken, bodyRequest);
    return resp;
  };

  static getTemplateByName = async (applicationId, tempName, tempLang) => {
    let templateToReturn = null;
    const respWaba = await api.getWaba(applicationId);
    let wabaId;
    if (respWaba?.data?.data?.length) {
      wabaId = respWaba.data.data[0]?.wabaId;
      const response = await api.getTemplatesWaba(applicationId, wabaId, { name: tempName })
      const templatesFound = response?.data?.data?.templates;
      if (templatesFound?.length) {
        templateToReturn = templatesFound?.find(el => el.language === tempLang);
        return templateToReturn;
      }
    }
  }

  };

export default TemplateService;
