import qs from 'qs';
import jwtDecode from 'jwt-decode';
// eslint-disable-next-line import/no-cycle
import * as api from '../api';
import { STORAGE_ITEM } from '../models';

class AuthService {
  init = (location) => {
    let params;

    if (location) {
      params = qs.parse(location, { ignoreQueryPrefix: true });
    } else {
      throw new Error('Browser location needed for this service');
    }

    const { applicationId, token } = params;

    if (token) {
      this.saveAdminToken(token);
    } else if (!this.getAdminToken()) {
      throw new Error('No admin token provided');
    }

    if (applicationId) {
      this.saveApplicationId(applicationId);
    } else {
      throw new Error('No applicationId provided');
    }
  };

  doAuth = async (location = undefined) => {
    if (location) {
      this.init(location);
    }
    const admToken = this.getAdminToken();
    const applicationId = this.getApplicationId();

    if (admToken && applicationId) {
      try {
        api.tokenToAxiosHeader(admToken);

        const response = await api.getWAToken({ params: { applicationId } });
        const { token } = response.data.data;

        this.saveWaToken(token);
        this.saveUserInfo(token);

        const accountIdResp = await api.getAccountId(applicationId);
        const { accountId, logoFile, name } = accountIdResp.data.data;

        this.saveAccountId(accountId);
        this.saveApplicationInfo(name, logoFile);
      } catch (error) {
        api.removeAxiosToken();
        throw new Error(error);
      }
    } else {
      throw new Error('Not token or applicationId found');
    }
  };

  logout = () => {
    this.clearStorage();
    api.removeAxiosToken();
  };

  saveUserInfo = (token) => {
    let userRol;
    let webUserId;
    let agentId;
    if (token) {
      userRol = jwtDecode(token).chatPermission;
      webUserId = jwtDecode(token).webUserId;
      agentId = jwtDecode(token).sub;
    }
    localStorage.setItem(STORAGE_ITEM.USER_ROL, userRol);
    localStorage.setItem(STORAGE_ITEM.USER_WEB_ID, webUserId);
    localStorage.setItem(STORAGE_ITEM.AGENT_ID, agentId);
  };

  saveApplicationInfo = (name, image) => {
    localStorage.setItem(STORAGE_ITEM.APPLICATION_NAME, name);
    localStorage.setItem(STORAGE_ITEM.APPLICATION_IMAGE, image);
  };

  getApplicationInfo = (name, image) => {
    const applicationName = localStorage.getItem(STORAGE_ITEM.APPLICATION_NAME, name);
    const applicationImage = localStorage.getItem(STORAGE_ITEM.APPLICATION_IMAGE, image);

    return { applicationName, applicationImage };
  };

  getLoggedUserInfo = () => {
    const userRol = this.getUserRol();
    const webUserId = parseInt(this.getLoggedUserWebId(), 10);
    const agentId = parseInt(this.getLoggedAgentId(), 10);
    return { userRol, webUserId, agentId };
  };

  getUserRol = () => localStorage.getItem(STORAGE_ITEM.USER_ROL);

  getLoggedUserWebId = () => localStorage.getItem(STORAGE_ITEM.USER_WEB_ID);

  getLoggedAgentId = () => localStorage.getItem(STORAGE_ITEM.AGENT_ID);

  toString = () => {
    // eslint-disable-next-line no-console
    console.log('AuthService:');
    // eslint-disable-next-line no-console
    console.table({ admToken: this.getAdminToken(), appId: this.getApplicationId(), waToken: this.getWaToken(), userRol: this.getUserRol() });
  };

  saveAdminToken = (admToken) => {
    localStorage.setItem(STORAGE_ITEM.ADM_TOKEN, admToken);
  };

  saveWaToken = (waToken) => {
    localStorage.setItem(STORAGE_ITEM.WA_TOKEN, waToken);
  };

  saveApplicationId = (appId) => {
    localStorage.setItem(STORAGE_ITEM.APP_ID, appId);
  };

  saveAccountId = (accountId) => {
    localStorage.setItem(STORAGE_ITEM.ACCOUNT_ID, accountId);
  };

  getAdminToken = () => localStorage.getItem(STORAGE_ITEM.ADM_TOKEN);

  getWaToken = () => localStorage.getItem(STORAGE_ITEM.WA_TOKEN);

  getApplicationId = () => localStorage.getItem(STORAGE_ITEM.APP_ID);

  getAccountId = () => localStorage.getItem(STORAGE_ITEM.ACCOUNT_ID);

  clearStorage = () => localStorage.clear();
}

const instance = new AuthService();

export default instance;
